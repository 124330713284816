<template>
  <div style="height: inherit">
    <div class="body-content-overlay" />
    <section class="chat-app-window">
      <div v-if="activeChat == null" class="start-chat-area">
        <div class="mb-1 start-chat-icon">
          <feather-icon icon="MessageSquareIcon" size="56" />
        </div>
        <h4 class="sidebar-toggle start-chat-text" @click="startConversation">
          {{ $t("chatPage.startConversation") }}
        </h4>
      </div>
      <div v-else class="active-chat">
        <div class="chat-navbar">
          <header class="chat-header">
            <div class="d-flex align-items-center">
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon icon="MenuIcon" class="cursor-pointer" size="21" @click="showLeftSidebar = true" />
              </div>

              <b-avatar size="36" :src="activeChat.customer.avatar" class="mr-1 cursor-pointer" />
              <h6 class="mb-0">
                {{ activeChat.customer.name }}
              </h6>
            </div>
          </header>
        </div>
        <VuePerfectScrollbar ref="refChatLogPS" :settings="perfectScrollbarSettings" class="user-chats scroll-area">
          <ChatLog :chat-data="activeChat" />
        </VuePerfectScrollbar>
        <b-form class="chat-app-form" @submit.prevent="sendMessage">
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input v-model="chatInputMessage" :placeholder="$t('chatPage.enterMessage')" />
          </b-input-group>
          <b-button variant="primary" type="submit">{{ $t("chatPage.send") }}</b-button>
        </b-form>
      </div>
    </section>
    <portal to="content-renderer-sidebar-left">
      <ChatLeftSidebar
        :chats="chats"
        :active-chat-contact-id="activeChat ? activeChat.bookingId : null"
        :mq-shall-show-left-sidebar.sync="showLeftSidebar"
        @open-chat="setBookingIdQueryParam"
      />
    </portal>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { $themeBreakpoints } from "@themeConfig";
import ChatLeftSidebar from "@/components/chat/ChatLeftSidebar.vue";
import ChatLog from "@/components/chat/ChatLog.vue";
import chatActions from "@/store/chat/actions.js";
import { mapGetters } from "vuex";

let pageLoader = null;

export default {
  name: "ChatIndex",

  components: {
    VuePerfectScrollbar,
    ChatLeftSidebar,
    ChatLog,
  },

  data() {
    return {
      chats: [],
      activeChat: null,
      chatInputMessage: "",
      showLeftSidebar: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      loading: false,
    };
  },

  async mounted() {
    pageLoader = this.$loading.show();
    this.chats = await this.$store.dispatch(chatActions.GET_CHATS);
    const { id } = this.$route.query;
    if (id) {
      await this.openChatOfContact(id);
    }

    pageLoader.hide();
  },

  methods: {
    setBookingIdQueryParam(bookingId) {
      if (this.activeChat && this.activeChat.bookingId == bookingId) {
        return;
      }

      this.$router.push({ name: "Chat", query: { id: bookingId } });
    },
    scrollToBottomInChatLog() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    },
    async openChatOfContact(bookingId) {
      this.chatInputMessage = "";
      const selectedChat = this.chats.find((x) => x.bookingId == bookingId);
      if (!selectedChat) return;

      const data = await this.$store.dispatch(chatActions.LOAD_MESSAGES, bookingId);
      this.activeChat = {
        bookingId,
        customer: {
          id: selectedChat.customerId,
          avatar: selectedChat.customerProfilePicture,
          name: selectedChat.customerName,
        },
        messages: data.messages,
      };

      selectedChat.unseenMessageCount = 0;

      this.showLeftSidebar = false;
      this.$nextTick(() => {
        this.scrollToBottomInChatLog();
      });
    },
    async sendMessage() {
      if (!this.chatInputMessage) return;

      const result = await this.$store.dispatch(chatActions.SEND_MESSAGE, {
        bookingId: this.activeChat.bookingId,
        text: this.chatInputMessage,
      });

      if (result.success) {
        this.activeChat.messages.push({
          text: this.chatInputMessage,
          senderId: this.user.id,
          senderName: this.user.firstName,
          isRead: false,
        });

        this.chatInputMessage = "";
      }

      this.$nextTick(() => {
        this.scrollToBottomInChatLog();
      });
    },
    startConversation() {
      if (this.$store.state.app.windowWidth < $themeBreakpoints.lg) {
        this.showLeftSidebar = true;
      }
    },
  },

  watch: {
    currentBreakPoint(val, oldVal) {
      if (oldVal === "md" && val === "lg") this.showLeftSidebar = false;
    },
    "$route.query.id": async function (val) {
      await this.openChatOfContact(val);
    },
  },

  computed: {
    ...mapGetters({
      currentBreakPoint: "app/currentBreakPoint",
      user: "user",
      unreadMessageCount: "unreadMessageCount",
    }),
  },

  metaInfo() {
    return {
      title: this.$t("chatPage.tabTitle"),
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat.scss";
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
