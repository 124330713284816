<template>
  <StatisticCardHorizontal
    icon="HeartIcon"
    :statistic="favoriteCount"
    :statisticTitle="$t('homePage.favoriteCount')"
    color="danger"
    :loading="loading"
  />
</template>

<script>
import StatisticCardHorizontal from "@/components/home/StatisticCardHorizontal.vue";
import boatActions from "@/store/boat/actions.js";

export default {
  name: "FavoriteCountCard",

  components: {
    StatisticCardHorizontal,
  },

  data() {
    return {
      favoriteCount: 0,
      loading: true,
    };
  },

  async mounted() {
    this.favoriteCount = await this.$store.dispatch(boatActions.GET_FAVORITE_BOAT_COUNT);
    this.loading = false;
  },
};
</script>
