<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content v-if="user">
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">{{ user.firstName }} {{ user.lastName }}</p>
        <span class="user-status">Partner</span>
      </div>
      <b-avatar size="40" :src="user.profilePicture" variant="light-primary" badge class="badge-minimal" badge-variant="success">
        <!-- <feather-icon v-if="!userData.fullName" icon="UserIcon" size="22" /> -->
      </b-avatar>
    </template>

    <b-dropdown-item :to="{ name: 'AccountSettings' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>{{ $t("common.profile") }}</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'Faq' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
      <span>{{ $t("common.faq") }}</span>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>{{ $t("common.logout") }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapGetters } from "vuex";
import accountActions from "@/store/account/actions.js";

export default {
  name: "UserDropdown",

  data() {
    return {
      userData: {},
    };
  },

  methods: {
    logout() {
      this.$store.dispatch(accountActions.LOGOUT);
    },
  },

  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
