import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "@/libs/axios.js";
import i18n from "@/libs/i18n/index.js";

const state = {
  announcements: {},
};

const mutations = {
  [mutationTypes.SET_ANNOUNCEMENTS](state, payload) {
    state.announcements[i18n.locale] = payload;
  },
};

const actions = {
  async [actionTypes.GET_ANNOUNCEMENTS]({ commit, getters }) {
    if (getters.announcements && getters.announcements[i18n.locale]) {
      return getters.announcements[i18n.locale];
    }

    const { data: result } = await httpClient.get("/announcement");
    if (result.success) {
      commit(mutationTypes.SET_ANNOUNCEMENTS, result.data);
      return result.data;
    }
  },
};

const getters = {
  announcements: (state) => state.announcements,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
