<template>
  <b-card :img-src="imageSrc" :img-alt="boat.name" img-top class="card-profile">
    <h3>{{ boat.name }}</h3>
    <h6 class="text-muted" v-if="boat.model">
      {{ boat.brand }} {{ boat.model }} / {{ $t(`addOrUpdateBoatPage.rentalPeriods.${this.boat.rentalPeriod}`) }}
    </h6>
    <h6 class="text-muted" v-else>-</h6>
    <b-badge class="profile-badge" :variant="statusBadgeVariant">
      <!-- İlk kez eklenmişse veya değişiklik onayı bekliyorsa "Onay Bekliyor" olarak gösteriyoruz -->
      {{ [2, 3].includes(this.boat.status) ? $t("common.waitingForApproval") : $t(`boatStatuses.${this.boat.status}`) }}
    </b-badge>
    <hr class="mb-2" />

    <div class="d-flex justify-content-center">
      <b-button variant="primary" class="mr-1" :to="{ name: 'EditBoat', params: { id: boat.id } }">
        <b-icon icon="pencil-square" />
      </b-button>
      <b-button variant="danger" @click="handleDelete">
        <b-icon icon="trash" />
      </b-button>
    </div>
  </b-card>
</template>

<script>
import boatActions from "@/store/boat/actions.js";
import { BoatStatus } from "@/utils/enum.js";

export default {
  name: "BoatListingCard",

  data() {
    return {
      BoatStatus,
    };
  },

  props: {
    boat: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleDelete() {
      this.$bvModal
        .msgBoxConfirm(this.$t("messages.boatDeleteConfirmation"), {
          title: this.$t("common.areYouSure"),
          size: "md",
          okTitle: this.$t("common.yes"),
          cancelTitle: this.$t("common.no"),
          okVariant: "primary",
          cancelVariant: "secondary",
          centered: true,
        })
        .then(async (result) => {
          if (!result) {
            return;
          }

          const isSuccess = await this.$store.dispatch(boatActions.DELETE_BOAT, this.boat.id);
          if (isSuccess) {
            this.$emit("delete", this.boat.id);
          }
        });
    },
  },

  computed: {
    imageSrc() {
      // TODO: Eren - Default image
      return this.boat.image || "https://via.placeholder.com/150";
    },
    statusBadgeVariant() {
      switch (this.boat.status) {
        case this.BoatStatus.ACTIVE:
          return "success";

        case this.BoatStatus.WAITING_FOR_APPROVAL:
        case this.BoatStatus.WAITING_FOR_UPDATE_APPROVAL:
          return "warning";

        case this.BoatStatus.REJECTED:
          return "danger";

        case this.BoatStatus.UPDATE_REQUIRED:
          return "info";

        default:
          return "primary";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card-profile {
  .card-body {
    padding-top: 1rem;
  }

  .card-img-top {
    height: 250px;
    object-fit: cover;
    object-position: center;
  }
}
</style>
