import actionTypes from "./actions";
import mutationTypes from "./mutations";
import i18n from "@/libs/i18n";
import httpClient from "@/libs/axios";

const state = {
  questions: {},
};

const mutations = {
  [mutationTypes.SET_QUESTIONS](state, payload) {
    state.questions[i18n.locale] = payload;
  },
};

const actions = {
  async [actionTypes.GET_QUESTIONS]({ commit, getters }) {
    if (getters.questions && getters.questions[i18n.locale]) {
      return getters.questions[i18n.locale];
    }

    const { data: result } = await httpClient.get("/faq");
    if (result.success) {
      commit(mutationTypes.SET_QUESTIONS, result.data);
      return result.data;
    }
  },
};

const getters = {
  questions: (state) => state.questions,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
