import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "@/libs/axios.js";
import i18n from "@/libs/i18n/index.js";

const state = {
  selectedBoatIds: [],
};

const mutations = {
  [mutationTypes.SET_SELECTED_BOAT_IDS](state, payload) {
    state.selectedBoatIds = payload;
  },
};

const actions = {
  async [actionTypes.GET_EVENTS]() {
    const { data: result } = await httpClient.get("/calendar");
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.ADD_OR_UPDATE_EVENT]({ commit }, payload) {
    const { data: result } = await httpClient.post("/calendar", payload);
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.DELETE_EVENT]({ commit }, eventId) {
    const { data: result } = await httpClient.delete(`/calendar/${eventId}`);
    return result.success;
  },
  [actionTypes.UPDATE_SELECTED_BOAT_IDS]({ commit }, selectedBoatIds) {
    commit(mutationTypes.SET_SELECTED_BOAT_IDS, selectedBoatIds);
  },
};

const getters = {
  selectedBoatIds: (state) => state.selectedBoatIds,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
