export default {
  SET_BOATS: "SET_BOATS",
  SET_BOAT_FEATURES: "SET_BOAT_FEATURES",
  SET_BOAT_TYPES: "SET_BOAT_TYPES",
  SET_BOAT_BRANDS: "SET_BOAT_BRANDS",
  SET_BOAT_MODELS: "SET_BOAT_MODELS",
  SET_BOAT_FLAGS: "SET_BOAT_FLAGS",
  SET_BOAT_SERVICES: "SET_BOAT_SERVICES",
  SET_BOAT_POSTING_RULES: "SET_BOAT_POSTING_RULES",
  SET_BOAT_COUNT: "SET_BOAT_COUNT",
  SET_FAVORITE_BOAT_COUNT: "SET_FAVORITE_BOAT_COUNT",
};
