<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content" :class="{ show: mqShallShowLeftSidebar }">
        <span class="sidebar-close-icon">
          <feather-icon icon="XIcon" size="16" @click="$emit('update:mq-shall-show-left-sidebar', false)" />
        </span>
        <div class="chat-fixed-search">
          <div class="d-flex align-items-center w-100">
            <div v-if="user" class="sidebar-profile-toggle">
              <b-avatar size="42" class="cursor-pointer avatar-border-2" :src="user.profilePicture" />
            </div>
            <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-prepend>
              <b-form-input v-model="searchQuery" :placeholder="$t('chatPage.search')" />
            </b-input-group>
          </div>
        </div>
        <VuePerfectScrollbar :settings="perfectScrollbarSettings" class="chat-user-list-wrapper list-group scroll-area">
          <h4 class="chat-list-title">{{ $t("chatPage.chats") }}</h4>
          <ul class="chat-users-list chat-list media-list">
            <ChatContact
              v-for="chat in filteredChats"
              :key="chat.bookingId"
              :chat="chat"
              tag="li"
              :class="{ active: activeChatContactId === chat.bookingId }"
              @click="$emit('open-chat', chat.bookingId)"
            />
          </ul>
        </VuePerfectScrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ChatContact from "./ChatContact.vue";
import { mapGetters } from "vuex";

export default {
  name: "ChatLeftSidebar",

  components: {
    VuePerfectScrollbar,
    ChatContact,
  },

  props: {
    chats: {
      type: Array,
      required: true,
    },
    activeChatContactId: {
      type: String,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      searchQuery: "",
    };
  },

  computed: {
    filteredChats() {
      return this.chats.filter((x) => x.customerName.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    ...mapGetters(["user"]),
  },
};
</script>
