import actionTypes from "./actions.js";
import httpClient from "@/libs/axios.js";

const state = {};

const mutations = {};

const actions = {
  async [actionTypes.GET_DISCOUNTS]({ commit }, query) {
    const params = new URLSearchParams(query).toString();
    const { data: result } = await httpClient.get(`/discount?${params}`);
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.ADD_OR_UPDATE_DISCOUNT]({ commit }, payload) {
    const { data: result } = await httpClient.post("/discount", payload);
    return result;
  },
  async [actionTypes.DELETE_DISCOUNT]({ commit }, id) {
    const { data: result } = await httpClient.delete(`/discount/${id}`);
    return result.success;
  },
  async [actionTypes.GET_DISCOUNT_BY_ID]({ commit }, id) {
    const { data: result } = await httpClient.get(`/discount/${id}`);
    if (result.success) {
      return result.data;
    }
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
