<template>
  <div>
    <Breadcrumb
      :title="isEdit ? $t('addOrUpdateDiscountPage.updateTabTitle') : $t('addOrUpdateDiscountPage.createTabTitle')"
      :items="breadcrumbItems"
    >
      <template #right>
        <b-button :to="{ name: 'Discounts' }" variant="primary">{{ $t("common.backToList") }}</b-button>
      </template>
    </Breadcrumb>
    <b-card ref="card">
      <b-container fluid>
        <b-row>
          <b-col md="2">
            <h4>{{ $t("addOrUpdateDiscountPage.discountType") }}</h4>
          </b-col>
          <b-col md="7">
            <b-row>
              <b-col md="6">
                <div
                  class="discount-type"
                  :class="{ active: form.discountType == DiscountType.STANDART }"
                  @click="handleDiscountTypeClick(DiscountType.STANDART)"
                >
                  <p>{{ $t("discountTypes.1") }}</p>
                  <span v-html="$t('addOrUpdateDiscountPage.standartDiscountDesc')"></span>
                </div>
              </b-col>
              <b-col md="6">
                <div
                  class="discount-type"
                  :class="{ active: form.discountType == DiscountType.EARLY_BOOKING }"
                  @click="handleDiscountTypeClick(DiscountType.EARLY_BOOKING)"
                >
                  <p>{{ $t("discountTypes.2") }}</p>
                  <span>{{ $t("addOrUpdateDiscountPage.earlyBookingDiscountDesc") }}</span>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="9">
            <hr class="discountpage-seperator" />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="2">
            <h4>{{ $t("addOrUpdateDiscountPage.discountInfo") }}</h4>
          </b-col>
          <b-col md="7">
            <b-row>
              <b-col md="8">
                <b-form-group :label="$t('addOrUpdateDiscountPage.name')">
                  <b-form-input v-model="form.name" :state="submitted && !$v.form.name.$error" size="lg" />
                  <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
                    <span v-if="!$v.form.name.required">{{ $t("messages.discountNameRequired") }}</span>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :label="$t('addOrUpdateDiscountPage.discountRate')">
                  <b-form-input
                    v-model.number="form.discountRate"
                    :state="submitted && !$v.form.discountRate.$error"
                    size="lg"
                    :placeholder="
                      maxDiscountRate
                        ? $t('addOrUpdateDiscountPage.discountRatePlaceholder') + '%' + maxDiscountRate
                        : $t('addOrUpdateDiscountPage.discountRatePlaceholder')
                    "
                  />
                  <div v-if="submitted && $v.form.discountRate.$error" class="invalid-feedback">
                    <span v-if="!$v.form.discountRate.required">{{ $t("messages.discountRateRequired") }}</span>
                    <span v-else-if="!$v.form.discountRate.maxValue">
                      {{ $t("messages.discountRateMaxValue").replace("{maxValue}", this.maxDiscountRate) }}
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-checkbox v-model="form.isActive" switch>{{ $t("common.active") }}</b-form-checkbox>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="9">
            <hr class="discountpage-seperator" />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="2">
            <h4>{{ $t("addOrUpdateDiscountPage.bookingDates") }}</h4>
            <span>{{ $t("addOrUpdateDiscountPage.bookingDatesDesc") }}</span>
          </b-col>
          <b-col md="7">
            <b-row v-if="form.discountType == DiscountType.STANDART">
              <b-col cols="12">
                <div class="d-flex justify-content-between align-items-center">
                  <span>{{ $t("addOrUpdateDiscountPage.unlimited") }}</span>
                  <b-form-checkbox v-model="form.isLimitlessTerm" switch size="xl"></b-form-checkbox>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="form.discountType == DiscountType.STANDART">
              <b-col cols="12">
                <div class="or">
                  <hr />
                  <span>{{ $t("common.or") }}</span>
                  <hr />
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('common.startDateLabel')">
                  <b-form-datepicker
                    v-model="form.startDate"
                    :locale="$i18n.locale"
                    size="lg"
                    start-weekday="1"
                    :disabled="form.isLimitlessTerm"
                    :placeholder="$t('common.startDatePlaceholder')"
                    :state="submitted && !$v.form.startDate.$error"
                  />
                  <div v-if="submitted && $v.form.startDate.$error" class="invalid-feedback">
                    <span v-if="!$v.form.startDate.required">{{ $t("messages.startDateRequired") }}</span>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('common.endDateLabel')">
                  <b-form-datepicker
                    v-model="form.endDate"
                    :locale="$i18n.locale"
                    size="lg"
                    start-weekday="1"
                    :disabled="form.isLimitlessTerm"
                    :placeholder="$t('common.endDatePlaceholder')"
                    :state="submitted && !$v.form.endDate.$error"
                  />
                  <div v-if="submitted && $v.form.endDate.$error" class="invalid-feedback">
                    <span v-if="!$v.form.endDate.required">{{ $t("messages.endDateRequired") }}</span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="9">
            <hr class="discountpage-seperator" />
          </b-col>
        </b-row>

        <b-row v-if="form.discountType == DiscountType.EARLY_BOOKING">
          <b-col md="2">
            <h4>{{ $t("addOrUpdateDiscountPage.checkinDates") }}</h4>
            <span>{{ $t("addOrUpdateDiscountPage.checkinDatesDesc") }}</span>
          </b-col>
          <b-col md="7">
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('common.startDateLabel')">
                  <b-form-datepicker
                    v-model="form.checkinStartDate"
                    :locale="$i18n.locale"
                    size="lg"
                    start-weekday="1"
                    :placeholder="$t('common.startDatePlaceholder')"
                    :state="submitted && !$v.form.checkinStartDate.$error"
                  />
                  <div v-if="submitted && $v.form.checkinStartDate.$error" class="invalid-feedback">
                    <span v-if="!$v.form.checkinStartDate.required">{{ $t("messages.endDateRequired") }}</span>
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('common.endDateLabel')">
                  <b-form-datepicker
                    v-model="form.checkinEndDate"
                    :locale="$i18n.locale"
                    size="lg"
                    start-weekday="1"
                    :placeholder="$t('common.startDatePlaceholder')"
                    :state="submitted && !$v.form.checkinEndDate.$error"
                  />
                  <div v-if="submitted && $v.form.checkinEndDate.$error" class="invalid-feedback">
                    <span v-if="!$v.form.checkinEndDate.required">{{ $t("messages.endDateRequired") }}</span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row v-if="form.discountType == DiscountType.EARLY_BOOKING">
          <b-col md="9">
            <hr class="discountpage-seperator" />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="2">
            <h4>{{ $t("addOrUpdateDiscountPage.discountBoats") }}</h4>
            <span>{{ $t("addOrUpdateDiscountPage.discountBoatsDesc") }}</span>
          </b-col>
          <b-col md="7">
            <b-row :class="{ 'mb-1': !submitted && !$v.form.boatIds.$error }">
              <b-col cols="12">
                <div class="d-md-flex d-block justify-content-between align-items-center">
                  <span>{{ $t("addOrUpdateDiscountPage.selectBoats") }}</span>
                  <div class="d-flex align-items-center">
                    <label for="allboats" class="cursor-pointer mr-1">
                      {{ $t("addOrUpdateDiscountPage.validForAll") }}
                    </label>
                    <b-form-checkbox
                      id="allboats"
                      switch
                      @change="handleToggleAllBoats"
                      :checked="doAllBoatsBeenSelected && this.boats.length > 0"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>

            <div class="mb-1" v-if="submitted && $v.form.boatIds.$error">
              <span v-if="!$v.form.boatIds.minLength" class="text-danger">{{
                $t("messages.discountBoatsMinLength")
              }}</span>
            </div>

            <div
              class="boat-row mb-1"
              v-for="boat in boats"
              :key="boat.id"
              :class="{ 'has-error': duplicateBoatIds.includes(boat.id) }"
            >
              <div>
                <b-form-checkbox @change="handleBoatSelectChange(boat)" :checked="form.boatIds.includes(boat.id)" />
              </div>
              <div @click="handleBoatSelectChange(boat)" class="info">{{ getBoatDisplayName(boat) }}</div>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col md="9" class="text-right">
            <b-button @click="handleSubmit" variant="primary" :disabled="submitting">
              <b-spinner small class="mr-1" v-if="submitting" />
              {{ $t("common.save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import { minValue, maxValue, requiredIf, required } from "vuelidate/lib/validators";
import { DiscountType, AppConfigType } from "@/utils/enum.js";
import discountActions from "@/store/discount/actions.js";
import boatActions from "@/store/boat/actions.js";
import commonActions from "@/store/common/actions.js";

export default {
  name: "AddOrUpdateDiscount",

  data() {
    return {
      form: {
        discountType: DiscountType.STANDART,
        name: "",
        discountRate: null,
        boatIds: [],
        isActive: false,
        isLimitlessTerm: false,
        startDate: null,
        endDate: null,
        checkinStartDate: null,
        checkinEndDate: null,
      },
      submitting: false,
      submitted: null,
      boats: [],
      DiscountType,
      AppConfigType,
      maxDiscountRate: null,
      duplicateBoatIds: [],
    };
  },

  async mounted() {
    var maxDiscountRateConfig;

    [this.boats, maxDiscountRateConfig] = await Promise.all([
      this.$store.dispatch(boatActions.GET_BOATS),
      this.$store.dispatch(commonActions.GET_APP_CONFIG_BY_KEY, AppConfigType.MAX_PARTNER_DISCOUNT_RATE),
    ]);

    this.maxDiscountRate = parseFloat(maxDiscountRateConfig.value);

    if (this.isEdit) {
      let loader = this.$loading.show({
        container: this.$refs.card,
      });

      const { id } = this.$route.params;
      this.form = await this.$store.dispatch(discountActions.GET_DISCOUNT_BY_ID, id);
      loader.hide();
    }
  },

  methods: {
    async handleSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.submitting = true;
      const result = await this.$store.dispatch(discountActions.ADD_OR_UPDATE_DISCOUNT, this.form);
      if (!result.success) {
        this.submitting = false;
        if (result.validationErrors && result.validationErrors.some((x) => x.key == "boatIds")) {
          const duplicateBoatIds = result.validationErrors.find((x) => x.key == "boatIds").message;
          this.duplicateBoatIds = JSON.parse(duplicateBoatIds);
        }
        return;
      }

      this.$router.push({ name: "Discounts" });
      this.submitting = false;
    },

    handleDiscountTypeClick(type) {
      this.form.discountType = type;
    },

    getBoatDisplayName(boat) {
      var result = `${boat.numberOfCabins} Kabinli / ${boat.numberOfBeds} Yataklı - ${boat.type} - ${boat.brand} - ${boat.model}`;

      if (boat.name) {
        result = boat.name + " - " + result;
      }

      return result;
    },

    handleBoatSelectChange(boat) {
      const index = this.form.boatIds.findIndex((x) => x == boat.id);
      if (index == -1) {
        this.form.boatIds.push(boat.id);
      } else {
        this.form.boatIds.splice(index, 1);
      }
    },

    handleToggleAllBoats() {
      if (this.doAllBoatsBeenSelected) {
        this.form.boatIds = [];
      } else {
        this.form.boatIds = this.boats.map((x) => x.id);
      }
    },
  },

  computed: {
    doAllBoatsBeenSelected() {
      const boatIds = this.boats.map((x) => x.id);
      return boatIds.length == this.form.boatIds.length;
    },

    isEdit() {
      return this.$router.currentRoute.name === "EditDiscount";
    },

    breadcrumbItems() {
      return [
        { text: this.$t("discountsPage.tabTitle"), to: "Discounts" },
        {
          text: this.isEdit
            ? this.$t("addOrUpdateDiscountPage.updateTabTitle")
            : this.$t("addOrUpdateDiscountPage.createTabTitle"),
          to: this.isEdit ? "EditDiscount" : "CreateDiscount",
        },
      ];
    },
  },

  validations() {
    return {
      form: {
        discountType: { required },
        name: { required },
        discountRate: { required, minValue: minValue(1), maxValue: maxValue(this.maxDiscountRate) },
        boatIds: { minLength: () => (this.form.isActive ? this.form.boatIds.length > 0 : true) },
        isActive: { required },
        isLimitlessTerm: { required: requiredIf(() => this.form.discountType == DiscountType.STANDART) },
        startDate: { required: requiredIf(() => !this.form.isLimitlessTerm) },
        endDate: { required: requiredIf(() => !this.form.isLimitlessTerm) },
        checkinStartDate: { required: requiredIf(() => this.form.discountType == DiscountType.EARLY_BOOKING) },
        checkinEndDate: { required: requiredIf(() => this.form.discountType == DiscountType.EARLY_BOOKING) },
      },
    };
  },

  metaInfo() {
    return {
      title: this.isEdit
        ? this.$t("addOrUpdateDiscountPage.updateTabTitle")
        : this.$t("addOrUpdateDiscountPage.createTabTitle"),
    };
  },
};
</script>

<style lang="scss">
hr.discountpage-seperator {
  border-color: #d8d8d8 !important;
  margin-top: 32px;
  margin-bottom: 32px;
}

.discount-type {
  padding: 16px;
  border: 1.5px solid #dfdfdf;
  border-radius: 8px;
  cursor: pointer;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #88919a;
    margin-bottom: 4px;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #88919a;
  }

  &.active {
    background: rgba(19, 68, 99, 0.6);
    border: none;

    p,
    span {
      color: #fcfcfd;
    }
  }
}

.boat-row {
  display: flex;
  align-items: center;
  gap: 16px;

  .info {
    padding: 16px 24px;
    border: 1.5px solid #dfdfdf;
    border-radius: 8px;
    width: 100%;
  }

  &.has-error {
    .info {
      border: 1.5px solid red;
    }
  }
}

.or {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  hr {
    border-color: #c4c4c4;
    width: 100%;
  }
}
</style>
