import Vue from "vue";
import Vuex from "vuex";

// modules
import account from "./account";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import notification from "./notification";
import payment from "./payment";
import boat from "./boat";
import common from "./common";
import location from "./location";
import policy from "./policy";
import announcement from "./announcement";
import faq from "./faq";
import booking from "./booking";
import chat from "./chat";
import discount from "./discount";
import calendar from "./calendar";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    app,
    appConfig,
    verticalMenu,
    notification,
    payment,
    boat,
    common,
    location,
    policy,
    announcement,
    faq,
    booking,
    chat,
    discount,
    calendar,
  },
});
