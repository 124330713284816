<template>
  <div>
    <Breadcrumb :title="$t('discountsPage.tabTitle')" :items="breadcrumbItems">
      <template #right>
        <b-button :to="{ name: 'CreateDiscount' }" variant="primary">{{ $t("common.new") }}</b-button>
      </template>
    </Breadcrumb>
    <b-row v-if="isLoaded && discounts.length > 0" class="d-none d-md-block discount-header">
      <b-col md="10">
        <b-row>
          <b-col md="7">{{ $t("discountsPage.discountName") }}</b-col>
          <b-col md="1" class="text-center">{{ $t("discountsPage.discountRate") }}</b-col>
          <b-col md="2" class="text-center">{{ $t("discountsPage.bookingDate") }}</b-col>
          <b-col md="2" class="text-center">{{ $t("discountsPage.discountType") }}</b-col>
        </b-row>
      </b-col>
      <b-col md="2"></b-col>
    </b-row>
    <b-card class="discount-card" v-for="discount in discounts" :key="discount.id">
      <b-row align-v="center">
        <b-col md="10">
          <b-row>
            <b-col md="7" class="text-center text-md-left">
              <p class="mb-md-0">{{ discount.name }}</p>
            </b-col>
            <b-col md="1" class="text-center">
              <p class="mb-md-0">%{{ discount.discountRate }}</p>
            </b-col>
            <b-col md="2" class="text-center">
              <p v-if="discount.isLimitlessTerm" class="mb-md-0">{{ $t("discountsPage.limitless") }}</p>
              <p v-else class="mb-md-0">
                {{ $moment(discount.startDate).lang($i18n.locale).format("DD.MM.YYYY") }} -
                {{ $moment(discount.endDate).lang($i18n.locale).format("DD.MM.YYYY") }}
              </p>
            </b-col>
            <b-col md="2" class="text-center">
              <p class="mb-md-0">{{ $t(`discountTypes.${discount.discountType}`) }}</p>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="2" class="text-center text-md-right">
          <b-button @click="handleEdit(discount.id)" type="button" variant="primary" class="mr-1">Düzenle</b-button>
          <b-button @click="handleDelete(discount.id)" type="button" variant="outline-danger">Sil</b-button>
        </b-col>
      </b-row>
    </b-card>
    <div v-if="discounts.length > 0" class="pagination-container">
      <b-pagination
        v-model="query.page"
        :total-rows="totalCount"
        :per-page="10"
        first-number
        last-number
        @change="handlePageChanged"
      />
    </div>
    <EmptyListGraphic
      v-if="isLoaded && discounts.length === 0"
      :image="require('@/assets/images/discounts-empty.svg')"
      buttonLink="CreateDiscount"
      :buttonText="$t('common.addDiscount')"
    />
  </div>
</template>

<script>
import discountActions from "@/store/discount/actions.js";
import EmptyListGraphic from "@/components/common/EmptyListGraphic.vue";

export default {
  name: "DiscountIndex",
  components: { EmptyListGraphic },

  data() {
    return {
      discounts: [],
      totalCount: 0,
      isLoaded: false,
      query: {
        page: 1,
      },
    };
  },

  async mounted() {
    const { page } = this.$route.query;
    this.query.page = page ?? 1;

    const data = await this.$store.dispatch(discountActions.GET_DISCOUNTS, this.query);
    this.discounts = data.items;
    this.totalCount = data.totalCount;
    this.isLoaded = true;
  },

  methods: {
    handleEdit(id) {
      this.$router.push({ name: "EditDiscount", params: { id } });
    },

    handleDelete(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("messages.discountDeleteConfirmation"), {
          title: this.$t("common.areYouSure"),
          size: "md",
          okTitle: this.$t("common.yes"),
          cancelTitle: this.$t("common.no"),
          okVariant: "primary",
          cancelVariant: "secondary",
          centered: true,
        })
        .then(async (result) => {
          if (result) {
            const isSuccess = await this.$store.dispatch(discountActions.DELETE_DISCOUNT, id);
            if (isSuccess) {
              const index = this.discounts.findIndex((x) => x.id == id);
              this.discounts.splice(index, 1);
            }
          }
        });
    },

    async handlePageChanged(e) {
      this.query.page = e;
      this.$router.replace({ path: this.$route.path, query: { ...this.query } });

      this.loaded = false;
      const data = await this.$store.dispatch(discountActions.GET_DISCOUNTS, this.query);
      this.discounts = data.items;
      this.totalCount = data.totalCount;
      this.isLoaded = true;
    },
  },

  computed: {
    breadcrumbItems() {
      return [{ text: this.$t("discountsPage.tabTitle"), to: "Discounts" }];
    },
  },

  metaInfo() {
    return {
      title: this.$t("discountsPage.tabTitle"),
    };
  },
};
</script>

<style lang="scss" scoped>
.discount-header {
  padding: 0 1.5rem;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #636363;
}
</style>
