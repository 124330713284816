import axios from "axios";
import Vue from "vue";
import actions from "@/store/account/actions.js";
import store from "@/store/index.js";

const httpClient = axios.create({
  baseURL: process.env.NODE_ENV === "development" ? process.env.VUE_APP_BASE_LOCAL_API_URL : process.env.VUE_APP_BASE_PROD_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

httpClient.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("access_token");
  const lang = localStorage.getItem("lang");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  if (lang != null && lang != undefined) {
    config.headers.lang = lang;
  } else {
    config.headers.lang = "tr";
  }

  return config;
}, undefined);

httpClient.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      Vue.$toast.success(response.data.message);
    }

    return response;
  },
  (error) => {
    switch (error.response.status) {
      case 401:
      case 403:
        store.dispatch(actions.LOGOUT);
        break;
      default:
        if (error.response.data.message) {
          Vue.$toast.error(error.response.data.message);
        }
        break;
    }

    return error.response;
  }
);

export default httpClient;
