<template>
  <LayoutVertical>
    <router-view />
  </LayoutVertical>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";

export default {
  name: "DashboardLayout",

  components: {
    LayoutVertical,
  },

  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
