import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "@/libs/axios.js";
import router from "@/router/index.js";
import boatMutations from "../boat/mutations.js";

const state = {
  isAuthenticated: false,
  user: null,
};

const mutations = {
  [mutationTypes.SET_IS_AUTHENTICATED](state, payload) {
    state.isAuthenticated = payload;
  },
  [mutationTypes.SET_USER](state, payload) {
    state.user = payload;
  },
};

const actions = {
  [actionTypes.IS_AUTHENTICATED]({ commit }, payload) {
    commit(mutationTypes.SET_IS_AUTHENTICATED, payload);
  },
  async [actionTypes.LOGIN]({ commit }, payload) {
    const { data: result } = await httpClient.post("/auth/login", payload);
    if (result.success) {
      const { user, token } = result.data;
      localStorage.setItem("access_token", token);
      commit(mutationTypes.SET_USER, user);
      commit(mutationTypes.SET_IS_AUTHENTICATED, true);
    }

    return result.success;
  },
  async [actionTypes.REGISTER]({ commit }, payload) {
    const { data: result } = await httpClient.post("/auth/register", payload);
    if (result.success) {
      const { user, token } = result.data;
      localStorage.setItem("access_token", token);
      commit(mutationTypes.SET_USER, user);
      commit(mutationTypes.SET_IS_AUTHENTICATED, true);
    }

    return result.success;
  },
  async [actionTypes.FORGOT_PASSWORD]({ commit }, payload) {
    const { data: result } = await httpClient.post("/auth/forgot-password", payload);
    return result.success;
  },
  async [actionTypes.RESET_PASSWORD]({ commit }, payload) {
    const { data: result } = await httpClient.post("/auth/reset-password", payload);
    return result.success;
  },
  async [actionTypes.LOGOUT]({ commit }) {
    localStorage.removeItem("access_token");
    commit(mutationTypes.SET_USER, null);
    commit(mutationTypes.SET_IS_AUTHENTICATED, false);
    commit(boatMutations.SET_BOATS, []);
    if (router.currentRoute.name !== "Login") {
      router.push({ name: "Login" });
    }
  },
  async [actionTypes.GET_ACCOUNT]({ commit }) {
    const { data: result } = await httpClient.get("/account");
    if (result.success) {
      commit(mutationTypes.SET_USER, result.data);
    }
  },
  async [actionTypes.UPDATE_PASSWORD]({ commit }, payload) {
    const { data: result } = await httpClient.patch("/account/update-password", payload);
    return result.success;
  },
  async [actionTypes.GET_ACCOUNT_DETAILS]({ commit }) {
    const { data: result } = await httpClient.get("/account/details");
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.UPDATE_GENERAL_SETTINGS]({ commit, state }, payload) {
    const { data: result } = await httpClient.patch("/account/update-general-settings", payload);
    if (result.success) {
      commit(mutationTypes.SET_USER, {
        ...state.user,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phoneNumber: payload.phoneNumber,
        profilePicture: payload.profilePicture,
      });
    }

    return result.success;
  },
  async [actionTypes.UPDATE_NOTIFICATION_SETTINGS]({ commit }, payload) {
    const { data: result } = await httpClient.patch("/account/update-notification-settings", payload);
    if (result.success) {
      commit(mutationTypes.SET_ACCOUNT_DETAILS, {
        ...state.accountDetails,
        ...payload,
      });
    }
  },
  async [actionTypes.SET_LANGUAGE]({ commit }, payload) {
    await httpClient.patch("/account/language/update", payload);
  },
  async [actionTypes.CHECK_BILLING_INFO_COMPLETED]() {
    const { data: result } = await httpClient.get("/account/check-billing-info");
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.UPDATE_CONTACT_SETTINGS]({ commit, state }, payload) {
    await httpClient.patch("/account/update-contact-settings", payload);
  },
  async [actionTypes.SEND_PHONE_NUMBER_CONFIRMATION_SMS]({ commit }, payload) {
    const { data: result } = await httpClient.post("/auth/send-confirmation-sms", payload);
    return result;
  },
  async [actionTypes.UPDATE_PHONE_NUMBER]({ commit }, payload) {
    const { data: result } = await httpClient.patch("/account/update-phone-number", payload);
    return result;
  },
};

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  user: (state) => state.user,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
