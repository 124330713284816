<template>
  <div>
    <Breadcrumb :title="$t('billingInformationPage.tabTitle')" :items="breadcrumbItems" />
    <b-card ref="form">
      <b-form @submit.prevent="handleSubmit">
        <b-row>
          <b-col cols="12" sm="6">
            <b-form-group label="Unvan">
              <b-form-input v-model.trim="form.title" :state="submitted && !$v.form.title.$error" />
              <div v-if="submitted && $v.form.title.$error" class="invalid-feedback">
                <span v-if="!$v.form.title.required">{{ $t("messages.invoiceTitleRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form-group label="Ülke">
              <b-form-select v-model="form.country" :state="submitted && !$v.form.country.$error">
                <b-form-select-option v-for="country in invoiceCountries" :key="country.key" :value="country.value">
                  {{ $t(`invoiceCountries.${country.value}`) }}
                </b-form-select-option>
              </b-form-select>
              <div v-if="submitted && $v.form.country.$error" class="invalid-feedback">
                <span v-if="!$v.form.country.required">{{ $t("messages.countryRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="4">
            <b-form-group label="İl">
              <b-form-input v-model.trim="form.city" :state="submitted && !$v.form.city.$error" />
              <div v-if="submitted && $v.form.city.$error" class="invalid-feedback">
                <span v-if="!$v.form.city.required">{{ $t("messages.cityRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="4">
            <b-form-group label="İlçe">
              <b-form-input v-model.trim="form.district" :state="submitted && !$v.form.district.$error" />
              <div v-if="submitted && $v.form.district.$error" class="invalid-feedback">
                <span v-if="!$v.form.district.required">{{ $t("messages.districtRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="4">
            <b-form-group label="Posta Kodu">
              <b-form-input v-model.trim="form.zipCode" :state="submitted && !$v.form.zipCode.$error" />
              <div v-if="submitted && $v.form.zipCode.$error" class="invalid-feedback">
                <span v-if="!$v.form.zipCode.required">{{ $t("messages.zipCodeRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Adres">
              <b-form-textarea v-model.trim="form.address" rows="3" :state="submitted && !$v.form.address.$error" />
              <div v-if="submitted && $v.form.address.$error" class="invalid-feedback">
                <span v-if="!$v.form.address.required">{{ $t("messages.addressRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="6">
            <b-form-group label="E-Posta">
              <b-form-input v-model.trim="form.email" :state="submitted && !$v.form.email.$error" />
              <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                <span v-if="!$v.form.email.required">{{ $t("messages.emailRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form-group label="Telefon">
              <b-form-input v-model.trim="form.phoneNumber" :state="submitted && !$v.form.phoneNumber.$error" />
              <div v-if="submitted && $v.form.phoneNumber.$error" class="invalid-feedback">
                <span v-if="!$v.form.phoneNumber.required">{{ $t("messages.phoneRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="form.country === invoiceCountry.TURKEY">
          <b-col cols="12" sm="6">
            <b-form-group label="Vergi Dairesi">
              <b-form-input v-model.trim="form.taxOffice" :state="submitted && !$v.form.taxOffice.$error" />
              <div v-if="submitted && $v.form.taxOffice.$error" class="invalid-feedback">
                <span v-if="!$v.form.taxOffice.required">{{ $t("messages.taxOfficeRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form-group label="Mersis No">
              <b-form-input v-model.trim="form.mersisNumber" :state="submitted && !$v.form.mersisNumber.$error" />
              <div v-if="submitted && $v.form.mersisNumber.$error" class="invalid-feedback">
                <span v-if="!$v.form.mersisNumber.required">{{ $t("messages.mersisNumberRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="form.country === invoiceCountry.OTHER">
          <b-col cols="12" sm="6">
            <b-form-group label="Ticaret Kayıt Numarası">
              <b-form-input
                v-model.trim="form.tradeRegistrationNumber"
                :state="submitted && !$v.form.tradeRegistrationNumber.$error"
              />
              <div v-if="submitted && $v.form.tradeRegistrationNumber.$error" class="invalid-feedback">
                <span v-if="!$v.form.tradeRegistrationNumber.required">{{
                  $t("messages.tradeRegistrationNumberRequired")
                }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form-group label="Ticaret Kayıt Yeri">
              <b-form-input
                v-model.trim="form.tradeRegistrationPlace"
                :state="submitted && !$v.form.tradeRegistrationPlace.$error"
              />
              <div v-if="submitted && $v.form.tradeRegistrationPlace.$error" class="invalid-feedback">
                <span v-if="!$v.form.tradeRegistrationPlace.required">{{
                  $t("messages.tradeRegistrationPlaceRequired")
                }}</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" sm="6">
            <b-form-group label="Pasaport No / TC Kimlik No / Vergi Numarası">
              <b-form-input
                v-model.trim="form.taxOrIdentityNumber"
                :state="submitted && !$v.form.taxOrIdentityNumber.$error"
              />
              <div v-if="submitted && $v.form.taxOrIdentityNumber.$error" class="invalid-feedback">
                <span v-if="!$v.form.taxOrIdentityNumber.required">{{
                  $t("messages.taxOrIdentityNumberRequired")
                }}</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right" cols="12">
            <b-button
              type="submit"
              ref="submitBtn"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
            >
              {{ $t("common.save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import paymentActions from "@/store/payment/actions.js";
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { InvoiceCountry } from "@/utils/enum.js";

let formLoader = null;

export default {
  name: "BillingInformation",

  data() {
    return {
      submitted: null,
      form: {
        title: "",
        taxOrIdentityNumber: "",
        taxOffice: "",
        email: "",
        phoneNumber: "",
        country: 1,
        city: "",
        district: "",
        address: "",
        zipCode: "",
        mersisNumber: "",
        tradeRegistrationNumber: "",
        tradeRegistrationPlace: "",
      },
      invoiceCountry: InvoiceCountry,
    };
  },

  async mounted() {
    formLoader = this.$loading.show({ container: this.$refs.form.el });
    this.form = await this.$store.dispatch(paymentActions.GET_BILLING_INFORMATION);
    formLoader.hide();
  },

  methods: {
    async handleSubmit() {
      this.$refs.submitBtn.disabled = true;
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$refs.submitBtn.disabled = false;
        return;
      }

      try {
        await this.$store.dispatch(paymentActions.UPDATE_BILLING_INFORMATION, this.form);
      } finally {
        this.submitted = null;
        this.$v.$reset();
        this.$refs.submitBtn.disabled = false;
      }
    },
  },

  computed: {
    breadcrumbItems() {
      return [
        {
          text: this.$t("billingInformationPage.tabTitle"),
          to: "BillingInformation",
        },
      ];
    },
    ...mapGetters(["invoiceCountries"]),
  },

  validations() {
    return {
      form: {
        title: { required },
        taxOrIdentityNumber: { required },
        taxOffice: {
          required: requiredIf(function () {
            return this.form.country == this.invoiceCountry.TURKEY;
          }),
        },
        email: { required },
        phoneNumber: { required },
        country: 1,
        city: { required },
        district: { required },
        address: { required },
        zipCode: { required },
        mersisNumber: {
          required: requiredIf(function () {
            return this.form.country == this.invoiceCountry.TURKEY;
          }),
        },
        tradeRegistrationNumber: {
          required: requiredIf(function () {
            return this.form.country == InvoiceCountry.OTHER;
          }),
        },
        tradeRegistrationPlace: {
          required: requiredIf(function () {
            return this.form.country == InvoiceCountry.OTHER;
          }),
        },
      },
    };
  },

  metaInfo() {
    return {
      title: this.$t("billingInformationPage.tabTitle"),
    };
  },
};
</script>
