<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId + String(index)"
      class="chat"
      :class="{ 'chat-left': msgGrp.senderId === formattedChatData.customer.id }"
    >
      <div class="chat-avatar">
        <b-avatar
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="
            msgGrp.senderId === formattedChatData.customer.id
              ? formattedChatData.customer.avatar
              : formattedChatData.profileUserAvatar
          "
        />
      </div>
      <div class="chat-body">
        <div v-for="msgData in msgGrp.messages" :key="msgData.time" class="chat-content">
          <p>{{ msgData.msg }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ChatLog",

  props: {
    chatData: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formattedChatData() {
      const customer = {
        id: this.chatData.customer.id,
        avatar: this.chatData.customer.avatar,
      };

      let chatLog = this.chatData.messages;
      const formattedChatLog = [];

      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined;
      let msgGroup = {
        senderId: chatMessageSenderId,
        messages: [],
      };

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.text,
            time: msg.createdDateTime,
          });
        } else {
          chatMessageSenderId = msg.senderId;
          formattedChatLog.push(msgGroup);
          msgGroup = {
            senderId: msg.senderId,
            messages: [
              {
                msg: msg.text,
                time: msg.createdDateTime,
              },
            ],
          };
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup);
      });

      return {
        formattedChatLog,
        customer,
        profileUserAvatar: this.user.profilePicture,
      };
    },
    ...mapGetters(["user"]),
  },
};
</script>
