<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { useWindowSize, useCssVar } from "@vueuse/core";
import store from "@/store";
import accountActions from "@/store/account/actions.js";
import chatActions from "@/store/chat/actions.js";
import { mapGetters } from "vuex";

export default {
  name: "App",

  async beforeCreate() {
    // Set colors in theme
    const colors = ["primary", "secondary", "success", "info", "warning", "danger", "light", "dark"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },

  async created() {
    if (this.isAuthenticated) {
      await store.dispatch(accountActions.GET_ACCOUNT);
      store.dispatch(chatActions.GET_UNREAD_MESSAGE_COUNT);
    }
  },

  setup() {
    const { skin, skinClasses } = useAppConfig();
    const { enableScrollToTop } = $themeConfig.layout;

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
      enableScrollToTop,
    };
  },

  computed: {
    ...mapGetters(["isAuthenticated"]),
  },

  metaInfo() {
    return {
      titleTemplate: "%s | Rotaneta Partner",
    };
  },
};
</script>
