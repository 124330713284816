import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "@/libs/axios.js";

const state = {
  bankAccounts: [],
};

const mutations = {
  [mutationTypes.SET_BANK_ACCOUNTS](state, payload) {
    state.bankAccounts = payload;
  },
};

const actions = {
  async [actionTypes.GET_BILLING_INFORMATION]() {
    const { data: result } = await httpClient.get("/payment/billing-information");
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.GET_BANK_ACCOUNTS]({ commit, getters }, forceUpdate = false) {
    if (getters.bankAccounts.length > 0 && !forceUpdate) {
      return getters.bankAccounts;
    }

    const { data: result } = await httpClient.get("/payment/bank-accounts");
    if (result.success) {
      commit(mutationTypes.SET_BANK_ACCOUNTS, result.data);
      return result.data;
    }
  },
  async [actionTypes.GET_BANK_ACCOUNT_BY_ID]({ commit }, id) {
    const { data: result } = await httpClient.get(`/payment/bank-accounts/${id}`);
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.ADD_OR_UPDATE_BANK_ACCOUNT]({ dispatch }, bankAccount) {
    const { data: result } = await httpClient.post("/payment/bank-accounts", bankAccount);
    if (result.success) {
      await dispatch(actionTypes.GET_BANK_ACCOUNTS, true);
    }

    return result.success;
  },
  async [actionTypes.DELETE_BANK_ACCOUNT]({ commit }, id) {
    await httpClient.delete(`/payment/bank-accounts/${id}`);
  },
  async [actionTypes.UPDATE_BILLING_INFORMATION]({ commit }, billingInformation) {
    await httpClient.put("/payment/billing-information", billingInformation);
  },
  async [actionTypes.SET_DEFAULT_BANK_ACCOUNT]({ commit, getters }, id) {
    const { data: result } = await httpClient.get(`/payment/bank-accounts/${id}/set-primary`);
    if (result.success) {
      const index = getters.bankAccounts.findIndex((x) => x.id === id);
      if (index > -1) {
        getters.bankAccounts[index].isPrimary = true;
      }

      const primaryIndex = getters.bankAccounts.findIndex((x) => x.isPrimary);
      if (primaryIndex > -1) {
        getters.bankAccounts[primaryIndex].isPrimary = false;
      }

      commit(mutationTypes.SET_BANK_ACCOUNTS, getters.bankAccounts);
    }

    return getters.bankAccounts.sort((a, b) => (a.isPrimary ? -1 : 1));
  },
};

const getters = {
  merchantTypes: (state) => state.merchantTypes,
  bankAccounts: (state) => state.bankAccounts,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
