<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <span class="brand-logo">
        <b-navbar-nav class="nav align-items-center ml-auto">
          <Locale />
        </b-navbar-nav>
      </span>
      <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-img src="@/assets/images/logo/rotaneta-horizontal.svg" alt="Rotaneta Logo" class="auth-logo" fluid />
          <b-card-title title-tag="h2" class="font-weight-bold mb-1"> {{ $t("registerPage.title") }}</b-card-title>
          <b-card-text class="mb-2"> {{ $t("registerPage.subtitle") }} </b-card-text>
          <b-form
            v-if="step == 1"
            class="auth-register-form mt-2"
            @submit.prevent="sendPhoneNumberConfirmationSms(true)"
          >
            <b-form-group :label="$t('common.firstName')">
              <b-form-input v-model="form.firstName" :state="submitted && !$v.form.firstName.$error" />
              <div v-if="submitted && $v.form.firstName.$error" class="invalid-feedback">
                <span v-if="!$v.form.firstName.required">{{ $t("messages.firstNameRequired") }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('common.lastName')">
              <b-form-input v-model="form.lastName" :state="submitted && !$v.form.lastName.$error" />
              <div v-if="submitted && $v.form.lastName.$error" class="invalid-feedback">
                <span v-if="!$v.form.lastName.required">{{ $t("messages.lastNameRequired") }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('common.email')">
              <b-form-input v-model="form.email" :state="submitted && !$v.form.email.$error" />
              <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                <span v-if="!$v.form.email.required">{{ $t("messages.emailRequired") }}</span>
                <span v-if="!$v.form.email.email">{{ $t("messages.emailInvalid") }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('common.phone')">
              <PhoneNumberInput
                v-model="phoneNumber"
                :class="{ 'is-invalid': submitted && $v.form.phoneNumber.$error }"
              />
              <div v-if="submitted && $v.form.phoneNumber.$error" class="invalid-feedback">
                <span v-if="!$v.form.phoneNumber.required">{{ $t("messages.phoneRequired") }}</span>
                <span v-else-if="!$v.form.phoneNumber.isValid">{{ $t("messages.phoneInvalid") }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('common.password')">
              <b-input-group class="input-group-merge" :class="{ 'is-invalid': submitted && $v.form.password.$error }">
                <b-form-input
                  v-model="form.password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="submitted && !$v.form.password.$error"
                />
                <b-input-group-append is-text>
                  <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                </b-input-group-append>
              </b-input-group>
              <div v-if="submitted && $v.form.password.$error" class="invalid-feedback">
                <span v-if="!$v.form.password.required">{{ $t("messages.passwordRequired") }}</span>
                <span v-if="!$v.form.password.minLength">{{ $t("messages.passwordFormatInvalid") }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('common.passwordConfirmation')">
              <b-input-group
                class="input-group-merge"
                :class="{ 'is-invalid': submitted && $v.form.passwordConfirm.$error }"
              >
                <b-form-input
                  v-model="form.passwordConfirm"
                  class="form-control-merge"
                  :type="passwordConfirmFieldType"
                  :state="submitted && !$v.form.passwordConfirm.$error"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordConfirmToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordConfirmVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <div v-if="submitted && $v.form.passwordConfirm.$error" class="invalid-feedback">
                <span v-if="!$v.form.passwordConfirm.required">{{ $t("messages.passwordConfirmationRequired") }}</span>
                <span v-else-if="!$v.form.passwordConfirm.sameAs">{{
                  $t("messages.passwordConfirmationInvalid")
                }}</span>
              </div>
            </b-form-group>

            <b-form-group>
              <b-input-group :class="{ 'is-invalid': submitted && $v.policyAccepted.$error }">
                <b-form-checkbox v-model="policyAccepted" :state="submitted && !$v.policyAccepted.$error">
                  <b-link v-if="$i18n.locale === 'tr'" class="ml-1" v-b-modal.privacy-policy
                    >Gizlilik politikası
                  </b-link>
                  <span v-if="$i18n.locale === 'tr'">ve </span>
                  <b-link v-if="$i18n.locale === 'tr'" v-b-modal.terms-of-use>kullanım şartlarını </b-link>
                  <span v-if="$i18n.locale === 'tr'">kabul ediyorum.</span>

                  <span v-if="$i18n.locale === 'en'" class="ml-1">I agree to </span>
                  <b-link v-if="$i18n.locale === 'en'" v-b-modal.privacy-policy>privacy policy </b-link>
                  <span v-if="$i18n.locale === 'en'">and </span>
                  <b-link v-if="$i18n.locale === 'en'" v-b-modal.terms-of-use>terms of use</b-link>
                </b-form-checkbox>
              </b-input-group>
              <div v-if="submitted && $v.policyAccepted.$error" class="invalid-feedback">
                <span v-if="!$v.policyAccepted.checked">{{ $t("messages.policyAcceptanceRequired") }}</span>
              </div>
            </b-form-group>

            <b-button variant="primary" ref="submitBtn" block type="submit">
              {{ $t("registerPage.createAccount") }}
            </b-button>
          </b-form>

          <b-form v-else-if="step == 2" class="auth-register-form mt-2" @submit.prevent="handleSubmit">
            <b-form-group :label="$t('registerPage.confirmationCode')">
              <b-form-input
                v-model="form.phoneNumberConfirmationCode"
                :state="submitted && !$v.form.phoneNumberConfirmationCode.$error"
              />
              <div v-if="submitted && $v.form.phoneNumberConfirmationCode.$error" class="invalid-feedback">
                <span v-if="!$v.form.phoneNumberConfirmationCode.required">
                  {{ $t("messages.phoneNumberConfirmationCodeRequired") }}
                </span>
              </div>
            </b-form-group>

            <b-form-group>
              <VueCountdown
                ref="countdown"
                :key="countdownKey"
                :auto-start="true"
                :time="60 * 2 * 1000"
                v-slot="{ minutes, seconds }"
                @end="onCountdownEnd"
              >
                <a v-if="canSendNewCode" @click="sendPhoneNumberConfirmationSms(false)" href="javascript:void(0)">
                  {{ $t("registerPage.sendNewCode") }}
                </a>
                <span v-else>
                  {{
                    $t("registerPage.newCodeCountdownText").replace("{minutes}", minutes).replace("{seconds}", seconds)
                  }}
                </span>
              </VueCountdown>
            </b-form-group>

            <b-button variant="primary" ref="submitBtn" block type="submit">
              {{ $t("registerPage.confirm") }}
            </b-button>
          </b-form>

          <p class="text-center mt-2">
            <span>{{ $t("registerPage.alreadyHaveAccount") }}</span>
            <b-link :to="{ name: 'Login' }">
              <span>&nbsp;{{ $t("common.login") }}</span>
            </b-link>
          </p>
        </b-col>
      </b-col>

      <b-col lg="7" class="d-none d-lg-flex align-items-center p-5 auth-bg">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="require('@/assets/images/register.png')" alt="Register V2" />
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="privacy-policy"
      :title="$t('common.kvkk')"
      size="lg"
      ok-only
      :ok-title="$t('common.readAndUnderstood')"
    >
      <div v-if="privacyPolicy" v-html="privacyPolicy.content"></div>
    </b-modal>
    <b-modal
      id="terms-of-use"
      :title="$t('common.termsOfUse')"
      size="lg"
      ok-only
      :ok-title="$t('common.readAndUnderstood')"
    >
      <div v-if="termsOfUse" v-html="termsOfUse.content"></div>
    </b-modal>
  </div>
</template>

<script>
import { togglePasswordVisibility } from "@/mixins/forms.js";
import { required, email, minLength, requiredIf } from "vuelidate/lib/validators";
import accountActions from "@/store/account/actions.js";
import policyActions from "@/store/policy/actions.js";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import { capitalizeFirstLetter } from "@/utils/helpers.js";
import { mask } from "vue-the-mask";
import PhoneNumberInput from "@/components/common/PhoneNumberInput.vue";
import { isValidPhoneNumber } from "libphonenumber-js";
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  name: "Register",

  components: {
    Locale,
    PhoneNumberInput,
    VueCountdown,
  },

  directives: { mask },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        countryIsoCode: "",
        countryDialCode: "",
        phoneNumber: "",
        password: "",
        passwordConfirm: "",
        phoneNumberConfirmationCode: "",
      },

      submitted: null,
      policyAccepted: false,
      passwordConfirmFieldType: "password",
      privacyPolicy: null,
      termsOfUse: null,
      phoneNumber: {},
      step: 1,
      canSendNewCode: false,
      countdownKey: 1,
    };
  },

  mounted() {
    this.$store.dispatch(policyActions.GET_KVKK).then((policy) => (this.privacyPolicy = policy));
    this.$store.dispatch(policyActions.GET_TERMS_OF_USE).then((policy) => (this.termsOfUse = policy));

    this.$root.$on("langChanged", () => {
      this.$store.dispatch(policyActions.GET_KVKK).then((policy) => (this.privacyPolicy = policy));
      this.$store.dispatch(policyActions.GET_TERMS_OF_USE).then((policy) => (this.termsOfUse = policy));
    });
  },

  methods: {
    async handleSubmit() {
      this.$refs.submitBtn.disabled = true;
      this.$v.$touch();
      this.submitted = true;
      if (this.$v.$invalid) {
        this.$refs.submitBtn.disabled = false;
        return;
      }

      const isSuccess = await this.$store.dispatch(accountActions.REGISTER, this.form);
      if (isSuccess) {
        this.$router.push({ name: "Home" });
      } else {
        this.submitted = null;
        this.$v.$reset();
        this.$refs.submitBtn.disabled = false;
      }
    },
    togglePasswordConfirmVisibility() {
      this.passwordConfirmFieldType = this.passwordConfirmFieldType === "password" ? "text" : "password";
    },
    async sendPhoneNumberConfirmationSms(isResend) {
      this.$refs.submitBtn.disabled = true;
      if (isResend) {
        this.$v.$touch();
        this.submitted = true;
        if (this.$v.$invalid) {
          this.$refs.submitBtn.disabled = false;
          return;
        }
      }

      const request = {
        countryCode: this.form.countryDialCode,
        phoneNumber: this.form.phoneNumber,
        email: this.form.email,
        isRegister: true,
      };

      const result = await this.$store.dispatch(accountActions.SEND_PHONE_NUMBER_CONFIRMATION_SMS, request);
      this.submitted = null;
      this.$v.$reset();
      this.$refs.submitBtn.disabled = false;

      if (result.success) {
        this.step = 2;
        this.countdownKey++;
        this.$nextTick(() => {
          this.$refs.countdown.start();
          this.canSendNewCode = false;
        });
      }
    },
    onCountdownEnd() {
      this.canSendNewCode = true;
    },
  },

  watch: {
    "form.firstName": function (val) {
      if (val) {
        this.form.firstName = capitalizeFirstLetter(val);
      }
    },
    "form.lastName": function (val) {
      if (val) {
        this.form.lastName = capitalizeFirstLetter(val);
      }
    },
    phoneNumber: {
      handler: function (newVal) {
        this.form.countryDialCode = newVal.dialCode;
        this.form.countryIsoCode = newVal.iso;
        this.form.phoneNumber = newVal.phoneNumber;
      },
      deep: true,
    },
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },

  validations() {
    return {
      form: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        phoneNumber: {
          required,
          isValid: () => isValidPhoneNumber(this.form.phoneNumber, this.form.countryIsoCode),
        },
        password: { required, minLength: minLength(6) },
        passwordConfirm: {
          required,
          sameAs: () => {
            return this.form.password === this.form.passwordConfirm;
          },
        },
        phoneNumberConfirmationCode: {
          required: requiredIf(() => this.step == 2),
        },
      },

      policyAccepted: { checked: () => this.policyAccepted },
    };
  },

  metaInfo() {
    return {
      title: this.$t("registerPage.tabTitle"),
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
