export default {
  LIST_BOAT_FEATURES: "LIST_BOAT_FEATURES",
  LIST_BOAT_TYPES: "LIST_BOAT_TYPES",
  LIST_BOAT_BRANDS: "LIST_BOAT_BRANDS",
  LIST_BOAT_MODELS_BY_BRAND_ID: "LIST_BOAT_MODELS_BY_BRAND_ID",
  LIST_BOAT_FLAGS: "LIST_BOAT_FLAGS",
  LIST_BOAT_SERVICES: "LIST_BOAT_SERVICES",
  CREATE_BOAT: "CREATE_BOAT",
  UPDATE_BOAT: "UPDATE_BOAT",
  GET_BOAT_BY_ID: "GET_BOAT_BY_ID",
  GET_BOATS: "GET_BOATS",
  DELETE_BOAT: "DELETE_BOAT",
  LIST_SPOKEN_LANGUAGES: "LIST_SPOKEN_LANGUAGES",
  LIST_BOAT_POSTING_RULES: "LIST_BOAT_POSTING_RULES",
  GET_BOAT_COUNT: "GET_BOAT_COUNT",
  GET_FAVORITE_BOAT_COUNT: "GET_FAVORITE_BOAT_COUNT",
};
