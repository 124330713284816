<template>
  <div>
    <Breadcrumb :title="$t('faqPage.tabTitle')" :items="breadcrumbItems">
      <template #right>
        <b-button :to="{ name: 'BankAccounts' }" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
          {{ $t("common.backToList") }}
        </b-button>
      </template>
    </Breadcrumb>
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <b-tab v-for="(category, index) in faqData" :key="index" :active="!index">
          <template #title>
            <!-- <feather-icon :icon="categoryObj.icon" size="18" class="mr-1" /> -->
            <span class="font-weight-bold">{{ category.name }}</span>
          </template>
          <div>
            <app-collapse accordion type="margin">
              <app-collapse-item v-for="item in category.faqs" :key="item.id" :title="item.question">
                <div v-html="item.answer"></div>
              </app-collapse-item>
            </app-collapse>
          </div>
        </b-tab>
        <template #tabs-end>
          <b-img fluid :src="require('@/assets/images/illustration/faq-illustrations.svg')" class="d-none d-md-block mt-auto" />
        </template>
      </b-tabs>
    </section>
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>{{ $t("faqPage.stillHaveQuestion") }}</h2>
          <b-card-text class="mb-3">
            {{ $t("faqPage.stillHaveQuestionDesc") }}
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="PhoneCallIcon" size="18" />
            </b-avatar>
            <h4>0(850) 308 78 68</h4>
            <span class="text-body">{{ $t("faqPage.stillHaveQuestionPhoneDesc") }}</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="MailIcon" size="18" />
            </b-avatar>
            <h4>partner@rotaneta.com</h4>
            <span class="text-body">{{ $t("faqPage.stillHaveQuestionEmailDesc") }}</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import faqActions from "@/store/faq/actions.js";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  name: "Faq",

  components: {
    AppCollapse,
    AppCollapseItem,
  },

  data() {
    return {
      faqSearchQuery: "",
      faqData: [],
    };
  },

  async mounted() {
    await this.init();

    this.$root.$on("langChanged", async () => {
      await this.init();
    });
  },

  methods: {
    async init() {
      this.faqData = await this.$store.dispatch(faqActions.GET_QUESTIONS);
    },
  },

  watch: {
    faqSearchQuery: function (val) {},
  },

  computed: {
    breadcrumbItems() {
      return [{ text: this.$t("faqPage.tabTitle"), to: "Faq" }];
    },
  },

  metaInfo() {
    return {
      title: this.$t("faqPage.tabTitle"),
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
</style>
