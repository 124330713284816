<template>
  <li class="nav-item" :class="{ active: isActive }">
    <b-link class="d-flex align-items-center" :to="{ name: to }">
      <feather-icon :icon="icon" />
      <span class="menu-title text-truncate">{{ text }}</span>
      <b-badge v-if="tag" pill :variant="tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
export default {
  name: "NavMenuItem",

  props: {
    to: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: "CircleIcon",
    },
    tag: {
      required: false,
    },
    tagVariant: {
      type: String,
      required: false,
      default: "primary",
    },
  },

  computed: {
    isActive() {
      return this.$route.name === this.to;
    },
  },
};
</script>
