import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "@/libs/axios.js";
import i18n from "@/libs/i18n/index.js";

const state = {
  countries: {},
  locations: {},
  marinas: {},
  departurePorts: {},
};

const mutations = {
  [mutationTypes.SET_COUNTRIES](state, payload) {
    state.countries[i18n.locale] = payload;
  },
  [mutationTypes.SET_LOCATIONS](state, payload) {
    state.locations[payload.id] = {
      ...state.locations[payload.id],
      [i18n.locale]: payload.data,
    };
  },
  [mutationTypes.SET_MARINAS](state, payload) {
    state.marinas[payload.id] = {
      ...state.marinas[payload.id],
      [i18n.locale]: payload.data,
    };
  },
  [mutationTypes.SET_DEPARTURE_PORTS](state, payload) {
    state.departurePorts[payload.id] = {
      ...state.marinas[payload.id],
      [i18n.locale]: payload.data,
    };
  },
};

const actions = {
  async [actionTypes.GET_COUNTRIES]({ commit, getters }) {
    if (getters.countries && getters.countries[i18n.locale]) {
      return getters.countries[i18n.locale];
    }

    const { data: result } = await httpClient.get("/location/countries");
    if (result.success) {
      commit(mutationTypes.SET_COUNTRIES, result.data);
      return result.data;
    }
  },
  async [actionTypes.GET_LOCATIONS]({ commit, getters }, countryId) {
    if (getters.locations[countryId] && getters.locations[countryId][i18n.locale]) {
      return getters.locations[countryId][i18n.locale];
    }

    const { data: result } = await httpClient.get(`/location/countries/${countryId}/locations`);
    if (result.success) {
      commit(mutationTypes.SET_LOCATIONS, { id: countryId, data: result.data });
      return result.data;
    }
  },
  async [actionTypes.GET_MARINAS]({ commit, getters }, locationId) {
    if (getters.marinas[locationId] && getters.marinas[locationId][i18n.locale]) {
      return getters.marinas[locationId][i18n.locale];
    }

    const { data: result } = await httpClient.get(`/location/${locationId}/marinas`);
    if (result.success) {
      commit(mutationTypes.SET_MARINAS, { id: locationId, data: result.data });
      return result.data;
    }
  },
  async [actionTypes.GET_DEPARTURE_PORTS]({ commit, getters }, locationId) {
    if (getters.departurePorts[locationId] && getters.departurePorts[locationId][i18n.locale]) {
      return getters.departurePorts[locationId][i18n.locale];
    }

    const { data: result } = await httpClient.get(`/location/${locationId}/departure-ports`);
    if (result.success) {
      commit(mutationTypes.SET_DEPARTURE_PORTS, { id: locationId, data: result.data });
      return result.data;
    }
  },
};

const getters = {
  countries: (state) => state.countries,
  locations: (state) => state.locations,
  marinas: (state) => state.marinas,
  departurePorts: (state) => state.departurePorts,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
