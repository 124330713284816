<template>
  <div>
    <Breadcrumb :title="$t('bookingsPage.tabTitle')" :items="breadcrumbItems" />
    <BookingListingCard v-for="item in bookings" :key="item.id" :booking="item" />

    <div v-if="bookings.length > 0" class="pagination-container">
      <b-pagination
        v-model="query.page"
        :total-rows="totalCount"
        :per-page="10"
        first-number
        last-number
        @change="handlePageChanged"
      />
    </div>
  </div>
</template>

<script>
import bookingActions from "@/store/booking/actions.js";
import BookingListingCard from "@/components/booking/BookingListingCard.vue";

export default {
  name: "Bookings",
  components: { BookingListingCard },

  data() {
    return {
      loaded: false,
      bookings: [],
      totalCount: 1,
      query: {
        page: 1,
      },
    };
  },

  async mounted() {
    const { page } = this.$route.query;
    this.query.page = page ?? 1;

    const data = await this.$store.dispatch(bookingActions.GET_BOOKINGS, this.query);
    this.bookings = data.items;
    this.totalCount = data.totalCount;
    this.loaded = true;
  },

  methods: {
    async handlePageChanged(e) {
      this.query.page = e;
      this.$router.replace({ path: this.$route.path, query: { ...this.query } });

      this.loaded = false;
      const data = await this.$store.dispatch(bookingActions.GET_BOOKINGS, this.query);
      this.bookings = data.items;
      this.totalCount = data.totalCount;
      this.loaded = true;
    },
  },

  computed: {
    breadcrumbItems() {
      return [{ text: this.$t("bookingsPage.tabTitle"), to: "Bookings" }];
    },
  },

  metaInfo() {
    return {
      title: this.$t("bookingsPage.tabTitle"),
    };
  },
};
</script>
