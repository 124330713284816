import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "../../libs/axios.js";
import i18n from "../../libs/i18n/index.js";

const state = {
  kvkk: {},
  termsOfUse: {},
  distanceSellingAgreement: {},
  commercialMailConfirmation: {},
};

const mutations = {
  [mutationTypes.SET_KVKK](state, payload) {
    state.kvkk[i18n.locale] = payload;
  },
  [mutationTypes.SET_TERMS_OF_USE](state, payload) {
    state.termsOfUse[i18n.locale] = payload;
  },
  [mutationTypes.SET_DISTANCE_SELLING_AGREEMENT](state, payload) {
    state.distanceSellingAgreement[i18n.locale] = payload;
  },
  [mutationTypes.SET_COMMERCIAL_MAIL_CONFIRMATION](state, payload) {
    state.commercialMailConfirmation[i18n.locale] = payload;
  },
};

const actions = {
  async [actionTypes.GET_TERMS_OF_USE]({ commit, getters }) {
    if (getters.termsOfUse && getters.termsOfUse[i18n.locale]) {
      return getters.termsOfUse[i18n.locale];
    }

    const { data: result } = await httpClient.get("/policy/terms-of-use");
    if (result.success) {
      commit(mutationTypes.SET_TERMS_OF_USE, result.data);
      return result.data;
    }
  },
  async [actionTypes.GET_KVKK]({ commit, getters }) {
    if (getters.kvkk && getters.kvkk[i18n.locale]) {
      return getters.kvkk[i18n.locale];
    }

    const { data: result } = await httpClient.get("/policy/kvkk");
    if (result.success) {
      commit(mutationTypes.SET_KVKK, result.data);
      return result.data;
    }
  },
  async [actionTypes.DISTANCE_SELLING_AGREEMENT]({ commit, getters }) {
    if (getters.distanceSellingAgreement && getters.distanceSellingAgreement[i18n.locale]) {
      return getters.distanceSellingAgreement[i18n.locale];
    }

    const { data: result } = await httpClient.get("/policy/mss");
    if (result.success) {
      commit(mutationTypes.SET_DISTANCE_SELLING_AGREEMENT, result.data);
      return result.data;
    }
  },
  async [actionTypes.COMMERCIAL_MAIL_CONFIRMATION]({ commit, getters }) {
    if (getters.commercialMailConfirmation && getters.commercialMailConfirmation[i18n.locale]) {
      return getters.commercialMailConfirmation[i18n.locale];
    }

    const { data: result } = await httpClient.get("/policy/commercial-mail");
    if (result.success) {
      commit(mutationTypes.SET_COMMERCIAL_MAIL_CONFIRMATION, result.data);
      return result.data;
    }
  },
  async [actionTypes.GET_POLICY_BY_TYPE]({ commit }, type) {
    const { data: result } = await httpClient.get(`/policy/type/${type}`);
    if (result.success) {
      return result.data;
    }
  },
};

const getters = {
  kvkk: (state) => state.kvkk,
  termsOfUse: (state) => state.termsOfUse,
  distanceSellingAgreement: (state) => state.distanceSellingAgreement,
  commercialMailConfirmation: (state) => state.commercialMailConfirmation,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
