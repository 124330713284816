import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "@/libs/axios.js";

const state = {
  bookingCount: 0,
};

const mutations = {
  [mutationTypes.SET_BOOKING_COUNT](state, payload) {
    state.bookingCount = payload;
  },
};

const actions = {
  async [actionTypes.GET_BOOKINGS]({ commit }, query) {
    const params = new URLSearchParams(query).toString();
    const { data: result } = await httpClient.get(`/booking?${params}`);
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.ANSWER_AVAILABILITY_CHECK]({ commit }, payload) {
    const { data: result } = await httpClient.post("/booking/answer", payload);
    return result;
  },
  async [actionTypes.CANCEL_BOOKING]({ commit }, payload) {
    const { data: result } = await httpClient.post(`/booking/cancel`, payload);
    return result;
  },
  async [actionTypes.UPLOAD_DELIVERY_RECORD]({ commit }, payload) {
    const formData = new FormData();
    formData.append("file", payload.file);
    const { data: uploadResult } = await httpClient.post("/general/upload-file?uploadType=booking", formData);
    if (!uploadResult.success) {
      return false;
    }

    const { data: result } = await httpClient.post("/booking/update-delivery-record", {
      bookingId: payload.bookingId,
      documentUrl: uploadResult.data,
    });

    if (!result.success) {
      return false;
    }

    return result;
  },
  async [actionTypes.UPLOAD_INVOICE]({ commit }, payload) {
    const formData = new FormData();
    formData.append("file", payload.file);
    const { data: uploadResult } = await httpClient.post("/general/upload-file?uploadType=booking", formData);
    if (!uploadResult.success) {
      return false;
    }

    const { data: result } = await httpClient.post(`/booking/update-invoice`, {
      bookingId: payload.bookingId,
      invoiceUrl: uploadResult.data,
    });

    if (!result.success) {
      return false;
    }

    return result;
  },
  async [actionTypes.REMOVE_DOCUMENT]({ commit }, payload) {
    const { data: result } = await httpClient.delete(
      `/booking/${payload.bookingId}/remove-document?type=${payload.documentType}`
    );
    return result;
  },
  async [actionTypes.GET_BOOKING_COUNT]({ commit, getters }) {
    if (getters.bookingCount > 0) {
      return getters.bookingCount;
    }

    const { data: result } = await httpClient.get("/booking/count");
    if (result.success) {
      commit(mutationTypes.SET_BOOKING_COUNT, result.data);
      return result.data;
    }
  },
};

const getters = {
  bookingCount: (state) => state.bookingCount,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
