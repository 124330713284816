<template>
  <StatisticCardHorizontal
    icon="CpuIcon"
    :statistic="boatCount"
    :statisticTitle="$t('homePage.boatCount')"
    color="primary"
    :loading="loading"
  />
</template>

<script>
import StatisticCardHorizontal from "@/components/home/StatisticCardHorizontal.vue";
import boatActions from "@/store/boat/actions.js";

export default {
  name: "BoatCountCard",

  components: {
    StatisticCardHorizontal,
  },

  data() {
    return {
      boatCount: 0,
      loading: true,
    };
  },

  async mounted() {
    this.boatCount = await this.$store.dispatch(boatActions.GET_BOAT_COUNT);
    this.loading = false;
  },
};
</script>
