import actionTypes from "./actions";
import mutationTypes from "./mutations";
import httpClient from "@/libs/axios";

const state = {
  notifications: [],
};

const mutations = {
  [mutationTypes.SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications;
  },
};

const actions = {
  async [actionTypes.FETCH_NOTIFICATIONS]({ commit }) {
    const { data: result } = await httpClient.get("/notification");
    if (result.success) {
      commit(mutationTypes.SET_NOTIFICATIONS, result.data);
    }
  },
  async [actionTypes.READ_ALL_NOTIFICATIONS]({ commit }) {
    const { data: result } = await httpClient.post("/notification/read-all");
    if (result.success) {
      commit(mutationTypes.SET_NOTIFICATIONS, result.data);
    }
  },
};

const getters = {
  notifications: (state) => state.notifications,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
