import Vue from "vue";
import VueRouter from "vue-router";
import AuthLayout from "@/components/layouts/AuthLayout.vue";
import DashboardLayout from "@/components/layouts/DashboardLayout.vue";
import Home from "@/views/Home.vue";
import Login from "@/views/authentication/Login.vue";
import Register from "@/views/authentication/Register.vue";
import ForgotPassword from "@/views/authentication/ForgotPassword.vue";
import ResetPassword from "@/views/authentication/ResetPassword.vue";
import AccountSettings from "@/views/account/AccountSettings.vue";
import CalendarIndex from "@/views/calendar/Index.vue";
import Boats from "@/views/boat/Index.vue";
import BillingInformation from "@/views/payment/BillingInformation.vue";
import BankAccounts from "@/views/payment/BankAccounts.vue";
import AddOrUpdateBankAccount from "@/views/payment/AddOrUpdateBankAccount.vue";
import AddOrUpdateBoat from "@/views/boat/AddOrUpdate.vue";
import Faq from "@/views/Faq.vue";
import store from "@/store/index.js";
import Bookings from "@/views/booking/Index.vue";
import Chat from "@/views/chat/Index.vue";
import Discounts from "@/views/discount/Index.vue";
import AddOrUpdateDiscount from "@/views/discount/AddOrUpdate.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/account",
        name: "AccountSettings",
        component: AccountSettings,
      },
      {
        path: "/calendar",
        name: "CalendarIndex",
        component: CalendarIndex,
      },
      {
        path: "/boats",
        name: "Boats",
        component: Boats,
      },
      {
        path: "/billing",
        name: "BillingInformation",
        component: BillingInformation,
      },
      {
        path: "/bank-accounts",
        name: "BankAccounts",
        component: BankAccounts,
      },
      {
        path: "/bank-accounts/new",
        name: "CreateBankAccount",
        component: AddOrUpdateBankAccount,
      },
      {
        path: "/bank-accounts/edit/:id",
        name: "EditBankAccount",
        component: AddOrUpdateBankAccount,
      },
      {
        path: "/boat/new",
        name: "CreateBoat",
        component: AddOrUpdateBoat,
      },
      {
        path: "/boat/edit/:id",
        name: "EditBoat",
        component: AddOrUpdateBoat,
      },
      {
        path: "/faq",
        name: "Faq",
        component: Faq,
      },
      {
        path: "/booking",
        name: "Bookings",
        component: Bookings,
      },
      {
        path: "/chat",
        name: "Chat",
        component: Chat,
        meta: {
          contentRenderer: "sidebar-left",
          contentClass: "chat-application",
        },
      },
      {
        path: "/discount",
        name: "Discounts",
        component: Discounts,
      },
      {
        path: "/discount/new",
        name: "CreateDiscount",
        component: AddOrUpdateDiscount,
      },
      {
        path: "/discount/edit/:id",
        name: "EditDiscount",
        component: AddOrUpdateDiscount,
      },
    ],
  },
  {
    path: "",
    name: "",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        component: Login,
      },
      {
        path: "/register",
        name: "Register",
        component: Register,
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPassword,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.isAuthenticated) {
    next({
      name: "Login",
      query: { returnUrl: to.fullPath },
    });
  } else if (!to.matched.some((record) => record.meta.requiresAuth) && store.getters.isAuthenticated) {
    next({ name: "Home" });
  }

  next();
});

export default router;
