import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "@/libs/axios.js";

const state = {
  unreadMessageCount: 0,
};

const mutations = {
  [mutationTypes.SET_UNREAD_MESSAGE_COUNT](state, payload) {
    state.unreadMessageCount = payload;
  },
};

const actions = {
  async [actionTypes.GET_CHATS]() {
    const { data: result } = await httpClient.get("/chat");
    return result.data;
  },
  async [actionTypes.LOAD_MESSAGES]({ dispatch }, bookingId) {
    const { data: result } = await httpClient.get(`/chat/${bookingId}`);
    if (result && result.success) {
      dispatch(actionTypes.MARK_ALL_MESSAGES_AS_READ, bookingId).then(() => {
        dispatch(actionTypes.GET_UNREAD_MESSAGE_COUNT);
      });
    }

    return result.data;
  },
  async [actionTypes.SEND_MESSAGE]({ commit }, payload) {
    const { data: result } = await httpClient.post("/chat", payload);
    return result;
  },
  async [actionTypes.MARK_ALL_MESSAGES_AS_READ]({ commit }, bookingId) {
    const { data: result } = await httpClient.get(`/chat/${bookingId}/read-all`);
    return result;
  },
  async [actionTypes.GET_UNREAD_MESSAGE_COUNT]({ commit }) {
    const { data: result } = await httpClient.get("/chat/unread-messages-count");
    if (result.success) {
      commit(mutationTypes.SET_UNREAD_MESSAGE_COUNT, result.data);
    }

    return result.data;
  },
};

const getters = {
  unreadMessageCount: (state) => state.unreadMessageCount,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
