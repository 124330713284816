<template>
  <div class="addorupdate-boat">
    <Breadcrumb
      :title="isEdit ? $t('addOrUpdateBoatPage.updateTabTitle') : $t('addOrUpdateBoatPage.createTabTitle')"
      :items="breadcrumbItems"
    >
      <template #right>
        <b-button v-b-modal.boat-rules variant="outline-primary" class="mr-1">
          {{ $t("common.boatPostingRules") }}
        </b-button>
        <b-button :to="{ name: 'Boats' }" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
          {{ $t("common.backToList") }}
        </b-button>
      </template>
    </Breadcrumb>
    <b-row>
      <b-col cols="12">
        <FormWizard
          color="#0A4273"
          error-color="#ea5455"
          :title="null"
          :subtitle="null"
          shape="square"
          :back-button-text="$t('common.previous')"
          :next-button-text="$t('common.next')"
          class="mb-3"
          ref="wizard"
          :validateOnBack="false"
          @on-change="handleTabChange"
        >
          <TabContent :title="$t('addOrUpdateBoatPage.generalInformation')">
            <b-container fluid>
              <b-row>
                <b-col md="2">
                  <h4>{{ $t("addOrUpdateBoatPage.boatInformations") }}</h4>
                </b-col>
                <b-col md="7">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group :label="$t('addOrUpdateBoatPage.boatName')">
                        <b-form-input
                          v-model="form.name"
                          :state="generalInformationGroupValidated && !$v.form.name.$error"
                          size="lg"
                          :placeholder="$t('addOrUpdateBoatPage.boatNamePlaceholder')"
                        />
                        <div
                          v-if="generalInformationGroupValidated && $v.form.name.$error"
                          class="invalid-feedback"
                        ></div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.boatType')">
                        <b-form-select
                          v-model="form.boatTypeId"
                          :state="generalInformationGroupValidated && !$v.form.boatTypeId.$error"
                          size="lg"
                        >
                          <b-form-select-option v-for="item in boatTypes" :key="item.id" :value="item.id">
                            {{ item.name }}
                          </b-form-select-option>
                        </b-form-select>
                        <div
                          v-if="generalInformationGroupValidated && $v.form.boatTypeId.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.boatTypeId.required">{{ $t("messages.boatTypeRequired") }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.brand')">
                        <vue-multiselect
                          v-model="boatBrandTemp"
                          :options="boatBrands"
                          label="name"
                          track-by="id"
                          :placeholder="$t('common.selectPlaceholder')"
                          :class="{
                            'is-invalid': generalInformationGroupValidated && $v.form.boatBrandId.$error,
                            'is-valid': generalInformationGroupValidated && !$v.form.boatBrandId.$error,
                          }"
                          @input="handleBoatBrandChange"
                        />
                        <div
                          v-if="generalInformationGroupValidated && $v.form.boatBrandId.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.boatBrandId.required">{{ $t("messages.boatBrandRequired") }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.model')">
                        <vue-multiselect
                          v-model="boatModelTemp"
                          :options="boatModels"
                          label="name"
                          track-by="id"
                          :placeholder="$t('common.selectPlaceholder')"
                          :class="{
                            'is-invalid': generalInformationGroupValidated && $v.form.boatModelId.$error,
                            'is-valid': generalInformationGroupValidated && !$v.form.boatModelId.$error,
                          }"
                          @input="handleBoatModelChange"
                          :disabled="form.boatBrandId == null || boatModels.length == 0"
                        />
                        <div
                          v-if="generalInformationGroupValidated && $v.form.boatModelId.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.boatModelId.required">{{ $t("messages.boatBrandRequired") }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.boatLength')">
                        <b-input-group>
                          <b-form-input
                            v-model="form.boatLength"
                            :state="generalInformationGroupValidated && !$v.form.boatLength.$error"
                            size="lg"
                            :placeholder="$t('addOrUpdateBoatPage.boatLengthPlaceholder')"
                            v-mask="'###'"
                          />
                          <div
                            v-if="generalInformationGroupValidated && $v.form.boatLength.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.form.boatLength.required">{{ $t("messages.boatLengthRequired") }}</span>
                          </div>
                          <template #append>
                            <b-form-select v-model="form.unitOfLength" class="border-left-none" size="lg">
                              <b-form-select-option v-for="unit in unitOfLengths" :key="unit.key" :value="unit.value">
                                {{ $t(`addOrUpdateBoatPage.unitOfLengths.${unit.value}`) }}
                              </b-form-select-option>
                            </b-form-select>
                          </template>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.yearOfConstruction')">
                        <b-form-input
                          v-model="form.yearOfConstruction"
                          type="number"
                          :state="generalInformationGroupValidated && !$v.form.yearOfConstruction.$error"
                          size="lg"
                          :placeholder="$t('addOrUpdateBoatPage.yearOfConstructionPlaceholder')"
                        />
                        <div
                          v-if="generalInformationGroupValidated && $v.form.yearOfConstruction.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.yearOfConstruction.required">{{
                            $t("messages.yearOfConstructionRequired")
                          }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col></b-col>
              </b-row>

              <b-row>
                <b-col cols="9">
                  <hr class="boatpage-separator" />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="2">
                  <h4>{{ $t("addOrUpdateBoatPage.contents") }}</h4>
                </b-col>
                <b-col md="7">
                  <b-row>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.numberOfCabins')">
                        <b-form-input
                          v-model="form.numberOfCabins"
                          type="number"
                          :state="generalInformationGroupValidated && !$v.form.numberOfCabins.$error"
                          size="lg"
                          :placeholder="$t('addOrUpdateBoatPage.numberOfCabinsPlaceholder')"
                        />
                        <div
                          v-if="generalInformationGroupValidated && $v.form.numberOfCabins.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.numberOfCabins.required">{{
                            $t("messages.numberOfCabinsRequired")
                          }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.numberOfBeds')">
                        <b-form-input
                          v-model="form.numberOfBeds"
                          type="number"
                          :state="generalInformationGroupValidated && !$v.form.numberOfBeds.$error"
                          size="lg"
                          :placeholder="$t('addOrUpdateBoatPage.numberOfBedsPlaceholder')"
                        />
                        <div
                          v-if="generalInformationGroupValidated && $v.form.numberOfBeds.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.numberOfBeds.required">{{ $t("messages.numberOfBedsRequired") }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.numberOfBathroom')">
                        <b-form-input
                          v-model="form.numberOfBathroom"
                          type="number"
                          :state="generalInformationGroupValidated && !$v.form.numberOfBathroom.$error"
                          size="lg"
                          :placeholder="$t('addOrUpdateBoatPage.numberOfBathroomPlaceholder')"
                        />
                        <div
                          v-if="generalInformationGroupValidated && $v.form.numberOfBathroom.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.numberOfBathroom.required">{{
                            $t("messages.numberOfBathroomRequired")
                          }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.capacity')">
                        <b-form-input
                          v-model="form.cruiseCapacity"
                          type="number"
                          :state="generalInformationGroupValidated && !$v.form.cruiseCapacity.$error"
                          size="lg"
                          :placeholder="$t('addOrUpdateBoatPage.capacityPlaceholder')"
                        />
                        <div
                          v-if="generalInformationGroupValidated && $v.form.cruiseCapacity.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.cruiseCapacity.required">{{ $t("messages.capacityRequired") }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.staffCount')">
                        <b-form-input
                          v-model="form.staff"
                          type="number"
                          :state="generalInformationGroupValidated && !$v.form.staffCount.$error"
                          size="lg"
                          :placeholder="$t('addOrUpdateBoatPage.crewPlaceholder')"
                        />
                        <div v-if="generalInformationGroupValidated && $v.form.staffCount.$error"></div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.fuelCost')">
                        <b-form-select
                          v-model="form.fuelCost"
                          :state="generalInformationGroupValidated && !$v.form.fuelCost.$error"
                          size="lg"
                        >
                          <b-form-select-option v-for="item in fuelCosts" :key="item.key" :value="item.value">
                            {{ $t(`addOrUpdateBoatPage.fuelCosts.${item.value}`) }}
                          </b-form-select-option>
                        </b-form-select>
                        <div
                          v-if="generalInformationGroupValidated && $v.form.fuelCost.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.fuelCost.required">{{ $t("messages.fuelCostRequired") }}</span>
                          <span v-else-if="!$v.form.fuelCost.notEqualToZero">{{
                            $t("messages.fuelCostRequired")
                          }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="9">
                  <hr class="boatpage-separator" />
                </b-col>
              </b-row>

              <b-row v-if="form.rentalPeriod != RentalPeriod.Hourly">
                <b-col md="2">
                  <h4>{{ $t("addOrUpdateBoatPage.checkInOutInfo") }}</h4>
                </b-col>
                <b-col md="7">
                  <b-row>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.checkInTime')">
                        <b-form-input
                          v-model="form.checkinTime"
                          type="time"
                          :state="generalInformationGroupValidated && !$v.form.checkinTime.$error"
                          size="lg"
                        />
                        <div
                          v-if="generalInformationGroupValidated && $v.form.checkinTime.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.checkinTime.required">{{ $t("messages.checkInTimeRequired") }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.checkOutTime')">
                        <b-form-input
                          v-model="form.checkoutTime"
                          type="time"
                          :state="generalInformationGroupValidated && !$v.form.checkoutTime.$error"
                          size="lg"
                        />
                        <div
                          v-if="generalInformationGroupValidated && $v.form.checkoutTime.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.checkoutTime.required">{{ $t("messages.checkOutTimeRequired") }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group :label="$t('addOrUpdateBoatPage.checkInDays')">
                        <vue-multiselect
                          v-model="checkinDaysTemp"
                          :options="weekDays"
                          multiple
                          track-by="value"
                          :customLabel="(val) => $t(`weekDays.${val.value}`)"
                          :placeholder="$t('common.selectPlaceholder')"
                          :closeOnSelect="false"
                          :class="{
                            'is-invalid': generalInformationGroupValidated && $v.form.availableCheckinDays.$error,
                            'is-valid': generalInformationGroupValidated && !$v.form.availableCheckinDays.$error,
                          }"
                        >
                          <template #option="day"> {{ $t(`weekDays.${day.option.value}`) }} </template>
                        </vue-multiselect>
                        <div
                          v-if="generalInformationGroupValidated && $v.form.availableCheckinDays.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.availableCheckinDays.required">
                            {{ $t("messages.availableCheckinDayRequired") }}
                          </span>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row v-if="form.rentalPeriod != RentalPeriod.Hourly">
                <b-col cols="9">
                  <hr class="boatpage-separator" />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="2">
                  <h4>{{ $t("addOrUpdateBoatPage.location") }}</h4>
                </b-col>
                <b-col md="7">
                  <b-row>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.country')">
                        <vue-multiselect
                          v-model="countryTemp"
                          :options="countries"
                          label="name"
                          track-by="id"
                          :placeholder="$t('common.selectPlaceholder')"
                          :class="{
                            'is-invalid': generalInformationGroupValidated && $v.form.countryId.$error,
                            'is-valid': generalInformationGroupValidated && !$v.form.countryId.$error,
                          }"
                          @input="handleCountryChange"
                        />
                        <div
                          v-if="generalInformationGroupValidated && $v.form.countryId.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.countryId.required">{{ $t("messages.countryRequired") }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.location')">
                        <vue-multiselect
                          v-model="locationTemp"
                          :options="locations"
                          label="name"
                          track-by="id"
                          :placeholder="$t('common.selectPlaceholder')"
                          :class="{
                            'is-invalid': generalInformationGroupValidated && $v.form.locationId.$error,
                            'is-valid': generalInformationGroupValidated && !$v.form.locationId.$error,
                          }"
                          :disabled="form.countryId == null || locations.length == 0"
                          @input="handleLocationChange"
                        />
                        <div
                          v-if="generalInformationGroupValidated && $v.form.locationId.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.locationId.required">{{ $t("messages.locationRequired") }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.marina')">
                        <vue-multiselect
                          v-model="marinaTemp"
                          :options="marinas"
                          label="name"
                          track-by="id"
                          :placeholder="$t('common.selectPlaceholder')"
                          :class="{
                            'is-invalid': generalInformationGroupValidated && $v.form.marinaId.$error,
                            'is-valid': generalInformationGroupValidated && !$v.form.marinaId.$error,
                          }"
                          :disabled="form.locationId == null || marinas.length == 0"
                          @input="handleMarinaChange"
                        />
                        <div
                          v-if="generalInformationGroupValidated && $v.form.marinaId.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.marinaId.required">{{ $t("messages.marinaRequired") }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="form.rentalPeriod == RentalPeriod.Hourly && departurePorts.length > 0">
                    <b-col cols="12" class="departure-boat-selection">
                      <b-form-group label="Hizmet Verilen Limanlar (Müşterilerin ücretsiz giriş yapabileceği yerler)">
                        <b-form-checkbox-group v-model="form.departurePortIds" class="checkboxes">
                          <b-form-checkbox v-for="port in departurePorts" :key="port.id" :value="port.id">
                            {{ port.name }}
                          </b-form-checkbox>
                        </b-form-checkbox-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="9">
                  <hr class="boatpage-separator" />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="2">
                  <h4>{{ $t("addOrUpdateBoatPage.listingInformations") }}</h4>
                </b-col>
                <b-col md="7">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group :label="$t('addOrUpdateBoatPage.descriptionTr')">
                        <ckeditor v-model="form.descriptionTR" :editor="editorSettings.editor" />
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <b-form-group :label="$t('addOrUpdateBoatPage.descriptionEn')">
                        <ckeditor v-model="form.descriptionEN" :editor="editorSettings.editor" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </TabContent>
          <TabContent :title="$t('addOrUpdateBoatPage.features')">
            <b-container fluid>
              <b-form-checkbox-group v-model="form.featureIds">
                <b-row v-for="category in boatFeatures" :key="category.id" class="mt-3">
                  <b-col cols="12">
                    <h2>{{ category.name }}</h2>
                  </b-col>
                  <b-col cols="3" v-for="feature in category.features" :key="feature.id" class="mt-1">
                    <b-form-checkbox :value="feature.id">
                      <b-img :src="feature.image" height="24" />
                      {{ feature.name }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-form-checkbox-group>
            </b-container>
          </TabContent>
          <TabContent :title="$t('addOrUpdateBoatPage.services')">
            <b-container fluid>
              <b-row>
                <b-col cols="12">
                  <h2>{{ $t("addOrUpdateBoatPage.services") }}</h2>
                </b-col>
                <b-col cols="12">
                  <b-alert variant="danger" show>
                    <h4 class="alert-heading">{{ $t("common.warning") }}</h4>
                    <div class="alert-body">{{ $t("addOrUpdateBoatPage.servicesDescription") }}</div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row v-if="form.rentalPeriod == RentalPeriod.Hourly" class="mt-3">
                <b-col cols="12">
                  <h2>{{ $t("addOrUpdateBoatPage.additionalPackages") }}</h2>
                </b-col>
                <b-col cols="12">
                  <div v-for="(additionalPackage, index) in form.additionalPackages" :key="index">
                    <h5>{{ index + 1 + ". " + $t("addOrUpdateBoatPage.package") }}</h5>
                    <b-row>
                      <b-col md="6">
                        <b-form-group :label="$t('addOrUpdateBoatPage.packageNameTr')">
                          <b-form-input
                            v-model="additionalPackage.nameTR"
                            size="lg"
                            :placeholder="$t('addOrUpdateBoatPage.enterPackageName')"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group :label="$t('addOrUpdateBoatPage.packageNameEn')">
                          <b-form-input
                            v-model="additionalPackage.nameEN"
                            size="lg"
                            :placeholder="$t('addOrUpdateBoatPage.enterPackageName')"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group :label="$t('addOrUpdateBoatPage.packageContentTr')">
                          <ckeditor v-model="additionalPackage.contentsTR" :editor="editorSettings.editor" />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group :label="$t('addOrUpdateBoatPage.packageContentEn')">
                          <ckeditor v-model="additionalPackage.contentsEN" :editor="editorSettings.editor" />
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group
                          :label="
                            $t('addOrUpdateBoatPage.packagePricePerPerson') +
                            ` (${CurrencySymbol[form.defaultCurrency]})`
                          "
                        >
                          <b-form-input
                            v-model.number="additionalPackage.pricePerPerson"
                            size="lg"
                            :placeholder="$t('addOrUpdateBoatPage.enterPackagePricePerPerson')"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="a" label-class="invisible">
                          <b-button
                            @click="deleteAdditionalPackage(index)"
                            variant="outline-danger"
                            class="delete-package-btn"
                            size="lg"
                            block
                          >
                            <feather-icon icon="Trash2Icon" size="1x" />
                            <span>{{ $t("addOrUpdateBoatPage.deletePackage") }}</span>
                          </b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12">
                        <hr class="boatpage-seperator" />
                      </b-col>
                    </b-row>
                  </div>

                  <b-row>
                    <b-col md="4">
                      <b-form-group>
                        <b-button @click="addNewAdditionalPackage" class="new-package-btn" size="lg" block>
                          <span>{{ $t("addOrUpdateBoatPage.addNewPackage") }}</span>
                          <b-icon icon="plus" />
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row
                v-for="category in boatServices.filter((x) => x.services.length > 0)"
                :key="category.id"
                class="mt-3"
              >
                <b-col cols="12">
                  <h2>{{ category.name }}</h2>
                </b-col>
                <b-col md="3" v-for="service in category.services" :key="service.id" class="mt-1">
                  <b-row>
                    <b-col cols="6">
                      <b-form-group>
                        <b-form-checkbox
                          :checked="form.services.some((x) => x.serviceId === service.id)"
                          @change="handleServiceCheckboxChange(service.id, $event)"
                          :data-service-id="service.id"
                          class="service-checkbox"
                        >
                          <b-img :src="service.image" height="24" />
                          {{ service.name }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col v-if="form.services.some((x) => x.serviceId == service.id)" class="text-right" cols="6">
                      <b-form-group :label="$t('common.required')" label-size="sm">
                        <b-form-checkbox
                          :checked="
                            form.services.some((x) => x.serviceId == service.id) &&
                            form.services.find((x) => x.serviceId == service.id).isRequired
                          "
                          :disabled="!form.services.some((x) => x.serviceId == service.id)"
                          @change="handleServiceRequiredChange(service.id, $event)"
                          switch
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="form.services.some((x) => x.serviceId == service.id)">
                    <b-col cols="6">
                      <b-form-group
                        :label="`${$t('addOrUpdateBoatPage.price')} (${CurrencySymbol[form.defaultCurrency]})`"
                      >
                        <b-form-input
                          type="number"
                          :value="
                            form.services.some((x) => x.serviceId === service.id)
                              ? form.services.find((x) => x.serviceId === service.id).price
                              : null
                          "
                          @input="handleServicePriceChange(service.id, $event)"
                          :state="servicesGroupValidated && isValidService(service.id)"
                          :disabled="!form.services.some((x) => x.serviceId === service.id)"
                          :placeholder="
                            form.services.some((x) => x.serviceId === service.id)
                              ? $t('addOrUpdateBoatPage.pricePlaceholder')
                              : ''
                          "
                        />
                        <div v-if="servicesGroupValidated && !isValidService(service.id)" class="invalid-feedback">
                          <span>{{ $t("messages.invalidServicePrice") }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Periyot">
                        <b-form-select
                          :value="
                            form.services.some((x) => x.serviceId == service.id)
                              ? form.services.find((x) => x.serviceId == service.id).boatServicePeriod
                              : null
                          "
                          :disabled="!form.services.some((x) => x.serviceId == service.id)"
                          @change="handleBoatServicePeriodChange(service.id, $event)"
                          :state="servicesGroupValidated && isValidServicePeriod(service.id)"
                        >
                          <b-form-select-option
                            v-for="item in boatServicePeriodSelectList"
                            :key="item.key"
                            :value="item.value"
                          >
                            {{ $t(`boatServicePeriods.${item.value}`) }}
                          </b-form-select-option>
                        </b-form-select>
                        <div
                          v-if="servicesGroupValidated && !isValidServicePeriod(service.id)"
                          class="invalid-feedback"
                        >
                          <span>{{ $t("messages.invalidWeeklyBoatServicePeriodForHourlyRental") }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
          </TabContent>
          <TabContent :title="$t('addOrUpdateBoatPage.images')">
            <b-container fluid ref="imagesTab">
              <b-row>
                <b-col cols="12">
                  <p class="text-muted">{{ $t("addOrUpdateBoatPage.allowedImageFileTypes") }}</p>
                </b-col>
                <b-col cols="12">
                  <p v-if="imagesGroupValidated && !$v.form.images.minLength" class="text-danger">
                    {{ $t("messages.imagesMinLength") }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button variant="primary" @click="$refs.imageInput.click()">{{ $t("common.uploadFile") }}</b-button>
                  <input
                    type="file"
                    ref="imageInput"
                    class="d-none"
                    @input="uploadImages"
                    multiple
                    accept=".jpg,.jpeg,.png"
                  />
                </b-col>
              </b-row>
              <div ref="imagesPreview">
                <VueDraggable
                  :force-fallback="true"
                  class="row"
                  @end="reorderImages"
                  :animation="150"
                  :list="form.images"
                >
                  <b-col
                    lg="2"
                    md="3"
                    sm="4"
                    class="preview-image mt-2"
                    v-for="(image, index) in form.images"
                    :key="index"
                    :data-index="index"
                    :data-order="image.order"
                  >
                    <div class="img-wrapper">
                      <b-img v-if="image.imageUrl" :src="image.imageUrl" fluid />
                    </div>
                    <b-button size="sm" variant="danger" @click="removeImage(index)">X</b-button>
                  </b-col>
                </VueDraggable>
              </div>
            </b-container>
          </TabContent>
          <TabContent :title="$t('addOrUpdateBoatPage.pricing')">
            <b-container fluid>
              <b-row>
                <b-col md="2">
                  <h4>{{ $t("addOrUpdateBoatPage.rentalType") }}</h4>
                </b-col>
                <b-col md="7">
                  <b-row>
                    <!-- <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.rentalPeriod')">
                        <b-form-select
                          v-model="form.rentalPeriod"
                          :state="pricingGroupValidated && !$v.form.rentalPeriod.$error"
                          size="lg"
                        >
                          <b-form-select-option
                            v-for="period in rentalPeriods"
                            :key="period.value"
                            :value="period.value"
                          >
                            {{ $t(`addOrUpdateBoatPage.rentalPeriods.${period.value}`) }}
                          </b-form-select-option>
                        </b-form-select>
                        <div v-if="pricingGroupValidated && $v.form.rentalPeriod.$error" class="invalid-feedback">
                          <span v-if="!$v.form.rentalPeriod.required">{{ $t("messages.rentalPeriodRequired") }}</span>
                          <span v-else-if="!$v.form.rentalPeriod.notEqualToZero">{{
                            $t("messages.rentalPeriodRequired")
                          }}</span>
                        </div>
                      </b-form-group>
                    </b-col> -->
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.provisionCost')">
                        <b-form-input
                          v-model="form.apa"
                          type="number"
                          :state="pricingGroupValidated && !$v.form.apa.$error"
                          size="lg"
                        />
                        <div v-if="pricingGroupValidated && $v.form.apa.$error" class="invalid-feedback">
                          <span v-if="!$v.form.apa.required">{{ $t("messages.apaRequired") }}</span>
                          <span v-if="!$v.form.apa.minValue">
                            {{ $t("messages.apaMinValue").replace("{minValue}", this.minPrepaymentRate.value) }}
                          </span>
                          <span v-if="!$v.form.apa.maxValue">{{ $t("messages.apaMaxValue") }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group :label="$t('addOrUpdateBoatPage.remainingPaymentType')">
                        <b-form-select
                          v-model="form.remainingPaymentType"
                          :state="pricingGroupValidated && !$v.form.remainingPaymentType.$error"
                          size="lg"
                        >
                          <b-form-select-option
                            v-for="item in remainingPaymentTypes"
                            :key="item.value"
                            :value="item.value"
                          >
                            {{ $t(`remainingPaymentTypes.${item.value}`) }}
                          </b-form-select-option>
                        </b-form-select>
                        <div
                          v-if="pricingGroupValidated && $v.form.remainingPaymentType.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.form.remainingPaymentType.required">{{
                            $t("messages.remainingPaymentTypeRequired")
                          }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="9">
                  <hr class="boatpage-seperator" />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="2">
                  <h4>{{ $t("addOrUpdateBoatPage.rentalPrice") }}</h4>
                </b-col>
                <b-col md="7">
                  <b-row>
                    <b-col md="6">
                      <b-form-group :label="boatPriceLabel">
                        <b-input-group>
                          <b-form-input
                            v-model="form.price"
                            type="number"
                            :state="pricingGroupValidated && !$v.form.price.$error"
                            size="lg"
                            :placeholder="$t('addOrUpdateBoatPage.pricePlaceholder')"
                          />
                          <div v-if="pricingGroupValidated && $v.form.price.$error" class="invalid-feedback">
                            <span v-if="!$v.form.price.required">{{ $t("messages.priceRequired") }}</span>
                            <span v-else-if="!$v.form.price.minValue">{{ $t("messages.priceMinValue") }}</span>
                          </div>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.currencyType')">
                        <b-form-select
                          v-model="form.defaultCurrency"
                          style="border-top-left-radius: none; border-bottom-left-radius: none"
                          size="lg"
                        >
                          <b-form-select-option
                            v-for="currency in currencies"
                            :key="currency.key"
                            :value="currency.value"
                          >
                            {{ $t(`addOrUpdateBoatPage.currencies.${currency.value}`) }}
                          </b-form-select-option>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.listingPrice')" class="mb-0">
                        <div v-if="form.price" class="listing-price">
                          <span>{{ listingPrice }} {{ CurrencySymbol[form.defaultCurrency] }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="form.rentalPeriod == RentalPeriod.Hourly || form.rentalPeriod == RentalPeriod.Daily">
                    <b-col cols="9">
                      <hr class="boatpage-seperator" />
                    </b-col>
                  </b-row>

                  <b-row v-if="form.rentalPeriod == RentalPeriod.Daily">
                    <b-col cols="12">
                      <b-form-group>
                        <b-form-checkbox v-model="canRentForOneDay">
                          {{ $t("addOrUpdateBoatPage.canRentForOneDayText") }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <div v-if="form.rentalPeriod == RentalPeriod.Daily && canRentForOneDay">
                    <b-row>
                      <b-col md="3">
                        <b-form-group :label="$t('addOrUpdateBoatPage.checkInTime')">
                          <b-form-input
                            v-model="form.checkinTimeForOneDay"
                            type="time"
                            :state="pricingGroupValidated && !$v.form.checkinTimeForOneDay.$error"
                            size="lg"
                          />
                          <div
                            v-if="pricingGroupValidated && $v.form.checkinTimeForOneDay.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.form.checkinTimeForOneDay.required">
                              {{ $t("messages.checkInTimeRequired") }}
                            </span>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group :label="$t('addOrUpdateBoatPage.checkOutTime')">
                          <b-form-input
                            v-model="form.checkoutTimeForOneDay"
                            type="time"
                            :state="pricingGroupValidated && !$v.form.checkoutTimeForOneDay.$error"
                            size="lg"
                          />
                          <div
                            v-if="pricingGroupValidated && $v.form.checkoutTimeForOneDay.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.form.checkoutTimeForOneDay.required">
                              {{ $t("messages.checkOutTimeRequired") }}
                            </span>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="3">
                        <b-form-group :label="$t('addOrUpdateBoatPage.oneDayPrice')">
                          <b-input-group>
                            <b-form-input
                              v-model="form.oneDayPrice"
                              type="number"
                              :state="pricingGroupValidated && !$v.form.oneDayPrice.$error"
                              size="lg"
                            />
                            <div v-if="pricingGroupValidated && $v.form.oneDayPrice.$error" class="invalid-feedback">
                              <span v-if="!$v.form.oneDayPrice.required">
                                {{ $t("messages.priceRequired") }}
                              </span>
                            </div>
                            <b-input-group-append>
                              <b-input-group-text>
                                {{ $t(`currencies.${form.defaultCurrency}`) }}
                              </b-input-group-text>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>

                  <b-row v-if="form.rentalPeriod == RentalPeriod.Hourly">
                    <b-col cols="12">
                      <b-form-group>
                        <b-form-checkbox v-model="havePriceDiffsByPeopleQuantity">
                          {{ $t("addOrUpdateBoatPage.boatPriceDiffText") }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="form.rentalPeriod == RentalPeriod.Hourly && havePriceDiffsByPeopleQuantity">
                    <b-col cols="12">
                      <div class="diff-item" v-for="(diff, i) in form.priceDifferences" :key="i">
                        <b-form-input
                          v-model.number="diff.numberOfPeople"
                          :state="pricingGroupValidated && isValidPriceDiff(i, 'numberOfPeople')"
                        />
                        <span>{{ $t("addOrUpdateBoatPage.afterPeople") }}</span>
                        <b-form-input
                          v-model.number="diff.difference"
                          :state="pricingGroupValidated && isValidPriceDiff(i, 'difference')"
                        />
                        <span>
                          {{ CurrencySymbol[form.defaultCurrency] }}
                          {{ $t("addOrUpdateBoatPage.priceDifference") }}
                        </span>
                        <span class="text-danger cursor-pointer" @click="removePriceDiff(i)">
                          {{ $t("common.remove") }}
                        </span>
                      </div>
                      <b-row>
                        <b-col cols="12">
                          <b-button @click="addDifferenceItem" variant="primary" size="sm">
                            {{ $t("addOrUpdateBoatPage.addDifference") }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>

                  <b-row v-if="form.rentalPeriod == RentalPeriod.Hourly">
                    <b-col cols="9">
                      <hr class="boatpage-seperator" />
                    </b-col>
                  </b-row>

                  <b-row v-if="form.rentalPeriod == RentalPeriod.Hourly">
                    <b-col cols="12">
                      <b-form-group>
                        <b-form-checkbox v-model="haveWeekendPricediff">
                          {{ $t("addOrUpdateBoatPage.weekendPriceDiffText") }}
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="form.rentalPeriod == RentalPeriod.Hourly && haveWeekendPricediff">
                    <b-col md="5" lg="4">
                      <b-form-group :label="$t('addOrUpdateBoatPage.weekendPriceDiff')">
                        <b-input-group>
                          <b-form-input
                            v-model="form.weekendPriceDiff"
                            type="number"
                            :state="pricingGroupValidated && !$v.form.weekendPriceDiff.$error"
                            size="lg"
                            :placeholder="$t('addOrUpdateBoatPage.pricePlaceholder')"
                          />
                          <div v-if="pricingGroupValidated && $v.form.weekendPriceDiff.$error" class="invalid-feedback">
                            <span v-if="!$v.form.weekendPriceDiff.required">{{ $t("messages.priceRequired") }}</span>
                            <span v-else-if="!$v.form.weekendPriceDiff.minValue">
                              {{ $t("messages.priceMinValue") }}
                            </span>
                          </div>
                          <b-input-group-append>
                            <b-input-group-text>
                              {{ $t(`currencies.${form.defaultCurrency}`) }}
                            </b-input-group-text>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="9">
                  <hr class="boatpage-seperator" />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="2">
                  <h4>{{ $t("addOrUpdateBoatPage.seasonalPrices") }}</h4>
                </b-col>
                <b-col md="7">
                  <div v-for="(group, index) in form.priceGroups" :key="index">
                    <b-row>
                      <b-col cols="12">
                        <b-row>
                          <b-col md="6">
                            <b-form-group :label="index + 1 + '. ' + $t('addOrUpdateBoatPage.season')">
                              <b-form-datepicker
                                v-model="group.dateFrom"
                                size="lg"
                                :locale="$i18n.locale"
                                :placeholder="$t('addOrUpdateBoatPage.pickSeasonStartDate')"
                                start-weekday="1"
                                @input="handleSeasonStartDateInput(index, $event)"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group :label="'a'" label-class="invisible">
                              <b-form-datepicker
                                v-model="group.dateTo"
                                size="lg"
                                :locale="$i18n.locale"
                                start-weekday="1"
                                :placeholder="$t('addOrUpdateBoatPage.pickSeasonEndDate')"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group :label="$t('addOrUpdateBoatPage.boatPrice')">
                              <b-form-input
                                v-model.number="group.price"
                                type="number"
                                size="lg"
                                :placeholder="$t('addOrUpdateBoatPage.pricePlaceholder')"
                                @input="handleSeasonPriceChanged(index, $event)"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group :label="$t('addOrUpdateBoatPage.listingPrice')">
                              <div class="season-listing-price" :class="{ 'has-price': group.listingPrice > 0 }">
                                <span>
                                  {{ group.listingPrice }}
                                  {{ CurrencySymbol[form.defaultCurrency] }}
                                </span>
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="form.rentalPeriod == RentalPeriod.Daily && canRentForOneDay" md="6">
                            <b-form-group :label="$t('addOrUpdateBoatPage.oneDayPrice')">
                              <b-form-input
                                v-model.number="group.oneDayPrice"
                                type="number"
                                size="lg"
                                :placeholder="$t('addOrUpdateBoatPage.pricePlaceholder')"
                                @input="handleSeasonOneDayPriceChanged(index, $event)"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col v-if="form.rentalPeriod == RentalPeriod.Daily && canRentForOneDay" md="6">
                            <b-form-group :label="$t('addOrUpdateBoatPage.listingPrice')">
                              <div class="season-listing-price" :class="{ 'has-price': group.oneDayListingPrice > 0 }">
                                <span>
                                  {{ group.oneDayListingPrice }}
                                  {{ CurrencySymbol[form.defaultCurrency] }}
                                </span>
                              </div>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="6">
                            <b-button
                              @click="deleteSeason(index)"
                              variant="outline-danger"
                              class="delete-season-btn"
                              size="lg"
                              block
                            >
                              <feather-icon icon="Trash2Icon" size="1x" />
                              <span>{{ $t("addOrUpdateBoatPage.deleteSeason") }}</span>
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <hr class="boatpage-seperator" />
                      </b-col>
                    </b-row>
                  </div>

                  <b-row>
                    <b-col md="6">
                      <b-form-group :label="form.priceGroups.length + 1 + '. ' + $t('addOrUpdateBoatPage.season')">
                        <b-button @click="addNewSeason" class="new-season-btn" size="lg" block>
                          <span>{{ $t("addOrUpdateBoatPage.addSeason") }}</span>
                          <b-icon icon="plus" />
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="9">
                  <hr class="boatpage-seperator" />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="2">
                  <h4>{{ $t("addOrUpdateBoatPage.extraFees") }}</h4>
                </b-col>
                <b-col md="7">
                  <b-row>
                    <b-col md="6">
                      <b-row>
                        <b-col cols="12">
                          <b-form-group :label="$t('addOrUpdateBoatPage.charterType')">
                            <b-form-select
                              v-model="form.charterType"
                              :state="servicesGroupValidated && !$v.form.charterType.$error"
                              size="lg"
                            >
                              <b-form-select-option v-for="item in charterTypes" :key="item.key" :value="item.value">
                                {{ $t(`addOrUpdateBoatPage.charterTypes.${item.value}`) }}
                              </b-form-select-option>
                            </b-form-select>
                            <div v-if="servicesGroupValidated && $v.form.charterType.$error" class="invalid-feedback">
                              <span v-if="!$v.form.charterType.required">{{ $t("messages.charterTypeRequired") }}</span>
                              <span v-else-if="!$v.form.charterType.notEqualToZero">{{
                                $t("messages.charterTypeRequired")
                              }}</span>
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" v-if="form.charterType == 3">
                          <b-form-group :label="$t('addOrUpdateBoatPage.skipperPrice')">
                            <b-input-group>
                              <b-form-input
                                v-model="form.skipperPrice"
                                type="number"
                                :state="servicesGroupValidated && !$v.form.skipperPrice.$error"
                                size="lg"
                              />
                              <div
                                v-if="servicesGroupValidated && $v.form.skipperPrice.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.form.skipperPrice.required">{{
                                  $t("messages.skipperPriceRequired")
                                }}</span>
                              </div>
                              <b-input-group-append>
                                <b-input-group-text>
                                  {{ $t(`currencies.${form.defaultCurrency}`) }}
                                </b-input-group-text>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" v-if="form.charterType == 3">
                          <b-form-group :label="$t('addOrUpdateBoatPage.skipperPayment')">
                            <b-form-select
                              v-model="form.skipperPaymentType"
                              :state="pricingGroupValidated && !$v.form.skipperPaymentType.$error"
                              size="lg"
                            >
                              <b-form-select-option
                                v-for="item in skipperPaymentTypes"
                                :key="item.key"
                                :value="item.value"
                              >
                                {{ $t(`skipperPaymentTypes.${item.value}`) }}
                              </b-form-select-option>
                            </b-form-select>
                            <div
                              v-if="pricingGroupValidated && $v.form.skipperPaymentType.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.form.skipperPaymentType.required">{{
                                $t("messages.skipperPaymentTypeRequired")
                              }}</span>
                              <span v-else-if="!$v.form.skipperPaymentType.notEqualToZero">
                                {{ $t("messages.skipperPaymentTypeRequired") }}
                              </span>
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" v-if="form.rentalPeriod == RentalPeriod.Hourly">
                          <b-form-group :label="$t('addOrUpdateBoatPage.servicePricingType')">
                            <b-form-select
                              v-model="form.servicePricingType"
                              :state="pricingGroupValidated && !$v.form.servicePricingType.$error"
                              size="lg"
                            >
                              <b-form-select-option
                                v-for="item in servicePricingTypes"
                                :key="item.key"
                                :value="item.value"
                              >
                                {{ $t(`servicePricingTypes.${item.value}`) }}
                              </b-form-select-option>
                            </b-form-select>
                            <div
                              v-if="pricingGroupValidated && $v.form.servicePricingType.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.form.servicePricingType.required">
                                {{ $t("messages.servicePricingTypeRequired") }}
                              </span>
                              <span v-else-if="!$v.form.servicePricingType.notEqualToZero">
                                {{ $t("messages.servicePricingTypeRequired") }}
                              </span>
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          v-if="
                            form.rentalPeriod == RentalPeriod.Hourly &&
                            form.servicePricingType !== null &&
                            form.servicePricingType != 0 &&
                            form.servicePricingType != ServicePricingType.NO_SERVICE_PRICE
                          "
                        >
                          <b-form-group :label="$t('addOrUpdateBoatPage.servicePrice')">
                            <b-input-group>
                              <b-form-input
                                v-model="form.servicePrice"
                                type="number"
                                :state="pricingGroupValidated && !$v.form.servicePrice.$error"
                                size="lg"
                                :placeholder="$t('addOrUpdateBoatPage.pricePlaceholder')"
                              />
                              <div v-if="pricingGroupValidated && $v.form.servicePrice.$error" class="invalid-feedback">
                                <span v-if="!$v.form.servicePrice.required">
                                  {{ $t("messages.servicePriceRequired") }}
                                </span>
                              </div>
                              <b-input-group-append>
                                <b-input-group-text>
                                  {{ $t(`currencies.${form.defaultCurrency}`) }}
                                </b-input-group-text>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <b-col v-if="form.rentalPeriod != RentalPeriod.Hourly" cols="12">
                          <b-form-group :label="$t('addOrUpdateBoatPage.depositPaymentType')">
                            <b-form-select
                              v-model="form.depositPaymentType"
                              :state="pricingGroupValidated && !$v.form.depositPaymentType.$error"
                              size="lg"
                            >
                              <b-form-select-option
                                v-for="item in depositPaymentTypes"
                                :key="item.key"
                                :value="item.value"
                              >
                                {{ $t(`depositPaymentTypes.${item.value}`) }}
                              </b-form-select-option>
                            </b-form-select>
                            <div
                              v-if="pricingGroupValidated && $v.form.depositPaymentType.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.form.depositPaymentType.required">
                                {{ $t("messages.depositPaymentTypeRequired") }}
                              </span>
                              <span v-else-if="!$v.form.depositPaymentType.notEqualToZero">
                                {{ $t("messages.depositPaymentTypeRequired") }}
                              </span>
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          v-if="
                            form.rentalPeriod != RentalPeriod.Hourly &&
                            form.depositPaymentType !== null &&
                            form.depositPaymentType != 0 &&
                            form.depositPaymentType != DepositPaymentType.NO_DEPOSIT
                          "
                        >
                          <b-form-group :label="$t('addOrUpdateBoatPage.depositPrice')">
                            <b-input-group>
                              <b-form-input
                                v-model="form.depositPrice"
                                type="number"
                                :state="pricingGroupValidated && !$v.form.depositPrice.$error"
                                @input="handleDepositPriceChange"
                                size="lg"
                              />
                              <div v-if="pricingGroupValidated && $v.form.depositPrice.$error" class="invalid-feedback">
                                <span v-if="!$v.form.depositPrice.required">
                                  {{ $t("messages.depositPriceRequired") }}
                                </span>
                              </div>

                              <b-input-group-append>
                                <b-input-group-text>
                                  {{ $t(`currencies.${form.defaultCurrency}`) }}
                                </b-input-group-text>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col md="6">
                      <b-row>
                        <b-col v-if="form.rentalPeriod != RentalPeriod.Hourly" cols="12">
                          <b-form-group :label="$t('addOrUpdateBoatPage.transitLog')">
                            <b-form-select
                              v-model="form.transitLogPaymentType"
                              :state="pricingGroupValidated && !$v.form.transitLogPaymentType.$error"
                              size="lg"
                            >
                              <b-form-select-option
                                v-for="item in transitLogPaymentTypes"
                                :key="item.value"
                                :value="item.value"
                              >
                                {{ $t(`transitLogPaymentTypes.${item.value}`) }}
                              </b-form-select-option>
                            </b-form-select>
                            <div
                              v-if="pricingGroupValidated && $v.form.transitLogPaymentType.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.form.transitLogPaymentType.required">
                                {{ $t("messages.transitLogPaymentTypeRequired") }}
                              </span>
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="12"
                          v-if="
                            form.rentalPeriod != RentalPeriod.Hourly &&
                            form.transitLogPaymentType !== null &&
                            form.transitLogPaymentType != 0 &&
                            form.transitLogPaymentType != TransitLogPaymentType.INCLUDED &&
                            form.transitLogPaymentType != TransitLogPaymentType.NO_TRANSIT_LOG
                          "
                        >
                          <b-form-group :label="$t('addOrUpdateBoatPage.transitLogPaymentType')">
                            <b-input-group>
                              <b-form-input
                                v-model="form.transitLogPrice"
                                type="number"
                                :state="pricingGroupValidated && !$v.form.transitLogPrice.$error"
                                @input="handleTransitLogPriceChange"
                                size="lg"
                              />
                              <div
                                v-if="pricingGroupValidated && $v.form.transitLogPrice.$error"
                                class="invalid-feedback"
                              >
                                <span v-if="!$v.form.transitLogPrice.required">{{
                                  $t("messages.transitLogPriceRequired")
                                }}</span>
                              </div>

                              <b-input-group-append>
                                <b-input-group-text>
                                  {{ $t(`currencies.${form.defaultCurrency}`) }}
                                </b-input-group-text>
                              </b-input-group-append>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group :label="$t('addOrUpdateBoatPage.additionalServicesPaymentType')">
                            <b-form-select
                              v-model="form.additionalServicesPaymentType"
                              :state="pricingGroupValidated && !$v.form.additionalServicesPaymentType.$error"
                              size="lg"
                            >
                              <b-form-select-option
                                v-for="item in additionalServicesPaymentTypes"
                                :key="item.value"
                                :value="item.value"
                              >
                                {{ $t(`additionalServicesPaymentTypes.${item.value}`) }}
                              </b-form-select-option>
                            </b-form-select>
                            <div
                              v-if="pricingGroupValidated && $v.form.additionalServicesPaymentType.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.form.additionalServicesPaymentType.required">
                                {{ $t("messages.additionalServicesPaymentTypeRequired") }}
                              </span>
                              <span v-else-if="!$v.form.additionalServicesPaymentType.notEqualToZero">
                                {{ $t("messages.additionalServicesPaymentTypeRequired") }}
                              </span>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="9">
                  <hr class="boatpage-seperator" />
                </b-col>
              </b-row>

              <b-row v-if="form.rentalPeriod != RentalPeriod.Hourly">
                <b-col md="2">
                  <h4>{{ $t("addOrUpdateBoatPage.cancellationConditions") }}</h4>
                </b-col>
                <b-col md="7">
                  <b-row>
                    <b-col md="6">
                      <b-form-group :label="$t('addOrUpdateBoatPage.bookingCancellationType')">
                        <b-form-select
                          v-model="form.cancellationPolicy"
                          :state="pricingGroupValidated && !$v.form.cancellationPolicy.$error"
                          size="lg"
                        >
                          <b-form-select-option
                            v-for="policy in cancellationPolicies"
                            :key="policy.value"
                            :value="policy.value"
                          >
                            {{ $t(`addOrUpdateBoatPage.cancellationPolicies.${policy.value}`) }}
                          </b-form-select-option>
                        </b-form-select>
                        <div v-if="pricingGroupValidated && $v.form.cancellationPolicy.$error" class="invalid-feedback">
                          <span v-if="!$v.form.cancellationPolicy.required">{{
                            $t("messages.cancellationPolicyRequired")
                          }}</span>
                          <span v-else-if="!$v.form.cancellationPolicy.notEqualToZero">{{
                            $t("messages.cancellationPolicyRequired")
                          }}</span>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row v-if="form.rentalPeriod != RentalPeriod.Hourly">
                <b-col cols="12">
                  <div class="cancellation-policies">
                    <li v-for="policy in cancellationPolicies" :key="policy.value">
                      <span v-html="$t(`cancellationPolicyDescriptions.${policy.value}`)"></span>
                    </li>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </TabContent>
          <TabContent :title="$t('addOrUpdateBoatPage.documents')">
            <b-container fluid>
              <b-row class="mb-2">
                <b-col cols="12">
                  <p class="text-muted">{{ $t("addOrUpdateBoatPage.boatDocumentsDesc") }}</p>
                  <p class="text-muted">{{ $t("addOrUpdateBoatPage.allowedDocumentFileTypes") }}</p>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="3">{{ $t("addOrUpdateBoatPage.documentName") }}</b-col>
                <b-col cols="7">{{ $t("addOrUpdateBoatPage.documentFile") }}</b-col>
                <b-col cols="2">{{ $t("addOrUpdateBoatPage.documentActions") }}</b-col>
              </b-row>
              <b-row class="boat-documents mb-2">
                <b-col cols="3">
                  <span class="btn btn-primary btn-block document-name" variant="primary" type="button" block>
                    {{ $t(`addOrUpdateBoatPage.boatDocumentTypes.${BoatDocumentType.TURIZM_ISLETMESI_BELGESI}`) }}
                  </span>
                </b-col>
                <b-col cols="7">
                  <b-form-file
                    :placeholder="$t('common.fileInputPlaceholder')"
                    :drop-placeholder="$t('common.fileInputDropPlaceHolder')"
                    :browse-text="$t('common.fileInputBrowseText')"
                    @input="handleDocumentUpload($event, BoatDocumentType.TURIZM_ISLETMESI_BELGESI)"
                    accept=".jpg,.jpeg,.png,.pdf"
                  />
                </b-col>
                <b-col cols="2">
                  <b-button
                    variant="primary"
                    type="button"
                    class="mr-2"
                    @click.prevent="
                      viewDocument(
                        form.documents.find((x) => x.documentType === BoatDocumentType.TURIZM_ISLETMESI_BELGESI)
                          .documentUrl
                      )
                    "
                    :disabled="
                      !form.documents.some((x) => x.documentType === BoatDocumentType.TURIZM_ISLETMESI_BELGESI)
                    "
                  >
                    <b-icon icon="eye" />
                  </b-button>

                  <b-button
                    variant="danger"
                    type="button"
                    :disabled="
                      !form.documents.some((x) => x.documentType === BoatDocumentType.TURIZM_ISLETMESI_BELGESI)
                    "
                    @click.prevent="removeDocument(BoatDocumentType.TURIZM_ISLETMESI_BELGESI)"
                  >
                    <b-icon icon="trash" />
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="boat-documents mb-2">
                <b-col cols="3">
                  <span class="btn btn-primary btn-block document-name" variant="primary" type="button" block>
                    {{ $t(`addOrUpdateBoatPage.boatDocumentTypes.${BoatDocumentType.SIGORTA_POLICESI}`) }}
                  </span>
                </b-col>
                <b-col cols="7">
                  <b-form-file
                    :placeholder="$t('common.fileInputPlaceholder')"
                    :drop-placeholder="$t('common.fileInputDropPlaceHolder')"
                    :browse-text="$t('common.fileInputBrowseText')"
                    @input="handleDocumentUpload($event, BoatDocumentType.SIGORTA_POLICESI)"
                  />
                </b-col>
                <b-col cols="2">
                  <b-button
                    variant="primary"
                    type="button"
                    class="mr-2"
                    @click.prevent="
                      viewDocument(
                        form.documents.find((x) => x.documentType === BoatDocumentType.SIGORTA_POLICESI).documentUrl
                      )
                    "
                    :disabled="!form.documents.some((x) => x.documentType === BoatDocumentType.SIGORTA_POLICESI)"
                  >
                    <b-icon icon="eye" />
                  </b-button>

                  <b-button
                    variant="danger"
                    type="button"
                    :disabled="!form.documents.some((x) => x.documentType === BoatDocumentType.SIGORTA_POLICESI)"
                    @click.prevent="removeDocument(BoatDocumentType.SIGORTA_POLICESI)"
                  >
                    <b-icon icon="trash" />
                  </b-button>
                </b-col>
                <!-- <b-col cols="3">
                  <b-form-datepicker
                    @input="handleDocumentExpirationDateChange($event, BoatDocumentType.IMZA_SIRKULERI)"
                    :disabled="!form.documents.some((x) => x.documentType === BoatDocumentType.IMZA_SIRKULERI)"
                    :locale="$i18n.locale"
                    :value="
                      form.documents.some((x) => x.documentType === BoatDocumentType.IMZA_SIRKULERI)
                        ? form.documents.find((x) => x.documentType === BoatDocumentType.IMZA_SIRKULERI).expirationDate
                        : null
                    "
                  />
                </b-col> -->
              </b-row>
            </b-container>
          </TabContent>
          <template #finish>
            <b-button
              variant="outline-warning"
              class="wizard-btn mr-2"
              :disabled="formSubmitting"
              @click.prevent="handleSubmit(true)"
              v-if="form.boatStatus == BoatStatus.DRAFT || !isEdit"
            >
              <b-spinner small class="mr-1" v-if="formSubmitting" />
              {{ $t("addOrUpdateBoatPage.saveAsDraft") }}
            </b-button>

            <b-button
              variant="primary"
              class="wizard-btn"
              :disabled="formSubmitting"
              @click.prevent="handleSubmit(false)"
              v-if="!isEdit || form.boatStatus == BoatStatus.DRAFT"
            >
              <b-spinner small class="mr-1" v-if="formSubmitting" />
              {{ $t("addOrUpdateBoatPage.sendForApproval") }}
            </b-button>

            <b-button
              variant="primary"
              class="wizard-btn"
              :disabled="formSubmitting"
              @click.prevent="handleSubmit(false)"
              v-else
            >
              <b-spinner small class="mr-1" v-if="formSubmitting" />
              {{ $t("common.save") }}
            </b-button>
          </template>
        </FormWizard>
      </b-col>
    </b-row>
    <BoatRulesModal />
    <BoatBusinessModelSelectionModal @selected="handleBusinessModelSelected" hideCloseButton />
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import boatActions from "@/store/boat/actions.js";
import { mapGetters } from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueDraggable from "vuedraggable";
import httpClient from "@/libs/axios.js";
import locationActions from "@/store/location/actions.js";
import { minValue, maxValue, requiredIf } from "vuelidate/lib/validators";
import {
  Currency,
  AppConfigType,
  CurrencySymbol,
  TransitLogPaymentType,
  CharterType,
  BoatDocumentType,
  BoatStatus,
  RentalPeriod,
  SkipperPaymentType,
  RemainingPaymentType,
  BoatServicePeriod,
  AdditionalServicesPaymentType,
  DepositPaymentType,
  ServicePricingType,
} from "@/utils/enum.js";
import { notEqualToZero } from "@/utils/validators.js";
import commonActions from "@/store/common/actions.js";
import BoatRulesModal from "@/components/common/BoatRulesModal.vue";
import VSelect from "vue-select";
import VueMultiselect from "vue-multiselect";
import FeatherIcon from "../../@core/components/feather-icon/FeatherIcon.vue";
import { VueMaskDirective } from "v-mask";
import BoatBusinessModelSelectionModal from "@/components/boats/BoatBusinessModelSelectionModal.vue";

let formLoader = null;

export default {
  name: "AddOrUpdateBoat",

  directives: {
    mask: VueMaskDirective,
  },

  components: {
    FormWizard,
    TabContent,
    VueDraggable,
    BoatRulesModal,
    VSelect,
    VueMultiselect,
    FeatherIcon,
    BoatBusinessModelSelectionModal,
  },

  data() {
    return {
      form: {
        name: "",
        boatTypeId: null,
        boatBrandId: null,
        boatModelId: null,
        numberOfCabins: null,
        numberOfBathroom: null,
        numberOfBeds: null,
        cruiseCapacity: null,
        boatLength: null,
        unitOfLength: 1,
        yearOfConstruction: null,
        fuelCost: null,
        charterType: null,
        apa: 15,
        cancellationPolicy: null,
        staff: null,
        featureIds: [],
        services: [],
        images: [],
        documents: [],
        countryId: null,
        locationId: null,
        marinaId: null,
        departurePortIds: [],
        rentalPeriod: null,
        price: null,
        priceGroups: [],
        defaultCurrency: Currency.TRY,
        descriptionTR: "",
        descriptionEN: "",
        checkinTime: null,
        checkoutTime: null,
        transitLogPaymentType: TransitLogPaymentType.NO_TRANSIT_LOG,
        transitLogPrice: null,
        skipperPrice: null,
        remainingPaymentType: RemainingPaymentType.ONLINE,
        boatStatus: 0,
        skipperPaymentType: SkipperPaymentType.PREPAID,
        additionalServicesPaymentType: AdditionalServicesPaymentType.CHECKIN,
        depositPaymentType: DepositPaymentType.NO_DEPOSIT,
        servicePricingType: ServicePricingType.NO_SERVICE_PRICE,
        depoitPrice: null,
        availableCheckinDays: [1, 2, 3, 4, 5, 6, 7],
        additionalPackages: [],
        priceDifferences: [],
        weekendPriceDiff: null,
        oneDayPrice: null,
        checkinTimeForOneDay: null,
        checkoutTimeForOneDay: null,
      },

      listingPrice: 0,
      generalInformationGroupValidated: null,
      servicesGroupValidated: null,
      pricingGroupValidated: null,
      documentsGroupValidated: null,
      imagesGroupValidated: null,
      boatBrandTemp: null,
      boatModelTemp: null,
      countryTemp: null,
      locationTemp: null,
      marinaTemp: null,
      formSubmitting: false,
      boatFeatures: [],
      boatTypes: [],
      boatBrands: [],
      boatModels: [],
      boatServices: [],
      countries: [],
      locations: [],
      marinas: [],
      departurePorts: [],
      checkinDaysTemp: [],
      minPrepaymentRate: null,
      comissionRate: null,
      isDraft: false,
      CurrencySymbol,
      BoatDocumentType,
      BoatStatus,
      RentalPeriod,
      TransitLogPaymentType,
      SkipperPaymentType,
      BoatServicePeriod,
      AdditionalServicesPaymentType,
      DepositPaymentType,
      ServicePricingType,
      havePriceDiffsByPeopleQuantity: false,
      haveWeekendPricediff: false,
      canRentForOneDay: false,
    };
  },

  async mounted() {
    await this.init();

    if (this.isEdit) {
      formLoader = this.$loading.show({ container: this.$refs.form });
      this.form = await this.$store.dispatch(boatActions.GET_BOAT_BY_ID, this.$route.params.id);

      for (let i = 0; i < this.form.priceGroups.length; i++) {
        const group = this.form.priceGroups[i];

        const priceFloat = parseFloat(group.price);
        const commission = priceFloat * (this.form.comissionRate / 100);
        group.listingPrice = priceFloat - commission;

        if (this.form.rentalPeriod == RentalPeriod.Daily && this.form.oneDayPrice > 0) {
          const oneDayPriceFloat = parseFloat(group.oneDayPrice);
          const oneDayCommission = oneDayPriceFloat * (this.form.comissionRate / 100);
          group.oneDayListingPrice = oneDayPriceFloat - oneDayCommission;
        }
      }

      if (this.form.boatBrandId) {
        this.boatModels = await this.$store.dispatch(boatActions.LIST_BOAT_MODELS_BY_BRAND_ID, this.form.boatBrandId);
      }

      if (this.form.countryId) {
        this.locations = await this.$store.dispatch(locationActions.GET_LOCATIONS, this.form.countryId);
      }

      if (this.form.locationId) {
        this.marinas = await this.$store.dispatch(locationActions.GET_MARINAS, this.form.locationId);
        if (this.form.rentalPeriod == RentalPeriod.Hourly) {
          this.departurePorts = await this.$store.dispatch(locationActions.GET_DEPARTURE_PORTS, this.form.locationId);
        }
      }

      this.boatBrandTemp = this.boatBrands.find((x) => x.id == this.form.boatBrandId);
      this.boatModelTemp = this.boatModels.find((x) => x.id == this.form.boatModelId);
      this.countryTemp = this.countries.find((x) => x.id == this.form.countryId);
      this.locationTemp = this.locations.find((x) => x.id == this.form.locationId);
      this.marinaTemp = this.marinas.find((x) => x.id == this.form.marinaId);

      if (this.form.priceDifferences.length > 0) {
        this.havePriceDiffsByPeopleQuantity = true;
      }

      if (this.form.weekendPriceDiff > 0) {
        this.haveWeekendPricediff = true;
      }

      if (this.form.oneDayPrice > 0) {
        this.canRentForOneDay = true;
      }

      formLoader.hide();
    } else {
      const { mode } = this.$route.query;
      this.setRentalPeriod(mode);
    }

    if (this.form.rentalPeriod) {
      this.boatServices = await this.$store.dispatch(boatActions.LIST_BOAT_SERVICES, this.form.rentalPeriod);
    }

    this.checkinDaysTemp = this.weekDays.filter((x) => this.form.availableCheckinDays.includes(x.value));

    this.$nextTick(() => {
      this.$refs.wizard.activateAll();
    });

    this.$root.$on("langChanged", async () => {
      await this.init();

      if (this.form.boatBrandId) {
        this.boatModels = await this.$store.dispatch(boatActions.LIST_BOAT_MODELS_BY_BRAND_ID, this.form.boatBrandId);
        this.boatBrandTemp = this.boatBrands.find((x) => x.id == this.form.boatBrandId);
        this.boatModelTemp = this.boatModels.find((x) => x.id == this.form.boatModelId);
      }

      if (this.form.countryId) {
        this.locations = await this.$store.dispatch(locationActions.GET_LOCATIONS, this.form.countryId);
        this.countryTemp = this.countries.find((x) => x.id == this.form.countryId);
        this.locationTemp = this.locations.find((x) => x.id == this.form.locationId);
      }

      if (this.form.locationId) {
        this.marinas = await this.$store.dispatch(locationActions.GET_MARINAS, this.form.locationId);
        if (this.form.rentalPeriod == RentalPeriod.Hourly) {
          this.departurePorts = await this.$store.dispatch(locationActions.GET_DEPARTURE_PORTS, this.form.locationId);
        }
        this.marinaTemp = this.marinas.find((x) => x.id == this.form.marinaId);
      }
    });
  },

  methods: {
    async init() {
      [this.boatTypes, this.boatBrands, this.boatFeatures, this.countries, this.minPrepaymentRate, this.comissionRate] =
        await Promise.all([
          this.$store.dispatch(boatActions.LIST_BOAT_TYPES),
          this.$store.dispatch(boatActions.LIST_BOAT_BRANDS),
          this.$store.dispatch(boatActions.LIST_BOAT_FEATURES),
          this.$store.dispatch(locationActions.GET_COUNTRIES),
          this.$store.dispatch(commonActions.GET_APP_CONFIG_BY_KEY, AppConfigType.MIN_PREPAYMENT_RATE),
          this.$store.dispatch(commonActions.GET_APP_CONFIG_BY_KEY, AppConfigType.COMISSION_RATE),
        ]);
    },
    async handleSubmit(isDraft) {
      this.form.availableCheckinDays = this.checkinDaysTemp.map((x) => x.value);
      this.isDraft = isDraft;
      if (!isDraft) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          console.log(Object.keys(this.$v.form).filter((key) => this.$v.form[key].$error));
          this.$toast.error(this.$t("messages.invalidForm"));
          this.generalInformationGroupValidated = true;
          this.servicesGroupValidated = true;
          this.imagesGroupValidated = true;
          this.pricingGroupValidated = true;
          this.documentsGroupValidated = true;

          if (this.$v.generalInformationGroup.$error) {
            this.$refs.wizard.changeTab(5, -1);
            return;
          }

          if (this.$v.servicesGroup.$error) {
            this.$refs.wizard.changeTab(5, 1);
            return;
          }

          if (this.$v.imagesGroup.$error) {
            this.$refs.wizard.changeTab(5, 2);
            return;
          }

          if (this.$v.pricingGroup.$error) {
            this.$refs.wizard.changeTab(5, 3);
            return;
          }

          if (this.$v.documentsGroup.$error) {
            this.$refs.wizard.changeTab(5, 4);
            return;
          }

          return;
        }
      }

      if (this.form.images.some((x) => !x.imageUrl)) {
        this.$toast.error(this.$t("messages.incompleteImageUpload"));
        this.$refs.wizard.changeTab(5, 2);

        return;
      }

      if (!this.haveWeekendPricediff) {
        this.form.weekendPriceDiff = null;
      }

      if (!this.canRentForOneDay) {
        this.form.checkinTimeForOneDay = null;
        this.form.checkoutTimeForOneDay = null;
        this.form.oneDayPrice = null;

        this.form.priceGroups.forEach((item) => {
          item.oneDayPrice = null;
        });
      }

      this.formSubmitting = true;
      var isSuccess;
      if (this.isEdit) {
        isSuccess = await this.$store.dispatch(boatActions.UPDATE_BOAT, { ...this.form, isDraft });
      } else {
        isSuccess = await this.$store.dispatch(boatActions.CREATE_BOAT, { ...this.form, isDraft });
      }

      if (isSuccess) {
        this.$router.push({ name: "Boats" });
      }

      this.formSubmitting = false;
    },
    async uploadImages(e) {
      const files = e.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const image = {
          imageUrl: "",
          order: this.form.images.length,
        };

        this.form.images.push(image);

        this.$nextTick(() => {
          this.startUpload(file, image.order);
        });
      }

      e.target.value = "";
    },
    async startUpload(file, i) {
      const el = document.querySelector(`.preview-image[data-order="${i}"]`);
      const loader = this.$loading.show({ container: el });
      const formData = new FormData();
      formData.append("file", file);

      try {
        const { data: result } = await httpClient.post("/general/upload-file?uploadType=boat", formData);
        if (result.success) {
          this.form.images[i].imageUrl = result.data;
        }
      } catch (error) {
        this.$toast.error(this.$t("messages.imageUploadError"));
      }

      loader.hide();
    },
    removeImage(index) {
      this.form.images.splice(index, 1);
    },
    reorderImages() {
      const imageTags = document.querySelectorAll(".preview-image");
      imageTags.forEach((el, order) => {
        const index = el.getAttribute("data-index");
        this.form.images[index].order = order;
      });
    },
    handleServicePriceChange(serviceId, price) {
      const service = this.form.services.find((x) => x.serviceId === serviceId);
      if (!service) {
        return;
      }

      service.price = parseFloat(price);
    },
    async handleBoatBrandChange(brand) {
      if (!brand) {
        this.form.boatBrandId = null;
        return;
      }

      this.form.boatBrandId = brand.id;
      this.boatModels = await this.$store.dispatch(boatActions.LIST_BOAT_MODELS_BY_BRAND_ID, this.form.boatBrandId);
    },
    handleBoatModelChange(model) {
      if (!model) {
        this.form.boatModelId = null;
        return;
      }

      this.form.boatModelId = model.id;
    },
    async handleCountryChange(country) {
      this.form.locationId = null;
      this.form.marinaId = null;
      this.marinas = [];

      if (!country) {
        this.form.countryId = null;
        return;
      }

      this.form.countryId = country.id;
      this.locations = await this.$store.dispatch(locationActions.GET_LOCATIONS, this.form.countryId);
    },
    async handleLocationChange(location) {
      this.form.marinaId = null;
      this.form.departurePortIds = [];
      this.marinas = [];
      this.departurePorts = [];

      if (!location) {
        this.form.locationId = null;
        return;
      }

      this.form.locationId = location.id;
      this.marinas = await this.$store.dispatch(locationActions.GET_MARINAS, this.form.locationId);
      if (this.form.rentalPeriod == RentalPeriod.Hourly) {
        this.departurePorts = await this.$store.dispatch(locationActions.GET_DEPARTURE_PORTS, this.form.locationId);
      }
    },
    handleMarinaChange(marina) {
      if (!marina) {
        this.form.marinaId = null;
        return;
      }

      this.form.marinaId = marina.id;
    },
    handleServiceCheckboxChange(serviceId) {
      if (this.form.services.some((x) => x.serviceId === serviceId)) {
        this.form.services = this.form.services.filter((x) => x.serviceId !== serviceId);
      } else {
        this.form.services.push({
          serviceId,
          price: 0,
          isRequired: false,
          boatServicePeriod: BoatServicePeriod.PER_BOOKING,
        });
      }
    },
    validateTabContent(group) {
      this.$v[group].$touch();
      this[group + "Validated"] = true;

      if (this.$v[group].$invalid) {
        this.$toast.error(this.$t("messages.invalidForm"));
        window.scrollTo({ top: 0, behavior: "smooth" });
        return false;
      }

      return true;
    },
    isValidService(serviceId) {
      const service = this.$v.form.services.$model.find((x) => x.serviceId === serviceId);
      if (service) {
        return service.price !== NaN && service.price !== null && service.price !== undefined && service.price >= 0;
      }

      return null;
    },
    isValidServicePeriod(serviceId) {
      const service = this.$v.form.services.$model.find((x) => x.serviceId === serviceId);
      if (service) {
        if (
          this.form.rentalPeriod == this.RentalPeriod.Hourly &&
          service.boatServicePeriod == this.BoatServicePeriod.PER_WEEK
        ) {
          return false;
        }

        return true;
      }

      return null;
    },
    handleDocumentExpirationDateChange(date, documentType) {
      const document = this.form.documents.find((x) => x.documentType === documentType);
      if (document) {
        document.expirationDate = new Date(date);
      }
    },
    removeDocument(documentType) {
      const document = this.form.documents.find((x) => x.documentType === documentType);
      if (document) {
        this.form.documents.splice(this.form.documents.indexOf(document), 1);
      }
    },
    async handleDocumentUpload(file, documentType) {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const { data: result } = await httpClient.post("/general/upload-file?uploadType=boatDocument", formData);
        if (!result.success) {
          return;
        }

        const document = this.form.documents.find((x) => x.documentType === documentType);
        if (document) {
          document.documentUrl = result.data;
          return;
        }

        this.form.documents.push({
          documentUrl: result.data,
          documentType,
          expirationDate: new Date(),
        });
      } catch {}
    },
    viewDocument(documentUrl) {
      window.open(documentUrl, "_blank");
    },
    handleTransitLogPriceChange() {
      this.listingPrice = this.calculateListingPrice();
    },
    handleDepositPriceChange() {
      this.listingPrice = this.calculateListingPrice();
    },
    calculateListingPrice() {
      var listingPrice;
      var comissionRate;

      if (this.isEdit) {
        comissionRate = this.form.comissionRate;
      } else if (!this.isEdit && this.comissionRate) {
        comissionRate = this.comissionRate.value;
      }

      if (comissionRate) {
        const price = parseFloat(this.form.price);
        const commission = price * (comissionRate / 100);
        listingPrice = price - commission;
      } else {
        listingPrice = this.form.price;
      }

      return listingPrice;
    },
    handleTabChange() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    addNewSeason() {
      this.form.priceGroups.push({
        dateFrom: null,
        dateTo: null,
        price: 0,
        listingPrice: 0,
      });
    },
    deleteSeason(index) {
      this.form.priceGroups.splice(index, 1);
    },
    handleSeasonPriceChanged(index, price) {
      if (!price || price < 0) {
        return null;
      }

      const group = this.form.priceGroups[index];
      var listingPrice;

      var comissionRate;

      if (this.isEdit) {
        comissionRate = this.form.comissionRate;
      } else if (!this.isEdit && this.comissionRate) {
        comissionRate = this.comissionRate.value;
      }

      if (comissionRate) {
        const priceFloat = parseFloat(price);
        const commission = priceFloat * (comissionRate / 100);
        listingPrice = priceFloat - commission;
      } else {
        listingPrice = price;
      }

      group.listingPrice = listingPrice;
    },
    handleSeasonOneDayPriceChanged(index, price) {
      if (!price || price < 0) {
        return null;
      }

      const group = this.form.priceGroups[index];
      var listingPrice;

      var comissionRate;

      if (this.isEdit) {
        comissionRate = this.form.comissionRate;
      } else if (!this.isEdit && this.comissionRate) {
        comissionRate = this.comissionRate.value;
      }

      if (comissionRate) {
        const priceFloat = parseFloat(price);
        const commission = priceFloat * (comissionRate / 100);
        listingPrice = priceFloat - commission;
      } else {
        listingPrice = price;
      }

      group.oneDayListingPrice = listingPrice;
    },
    handleServiceRequiredChange(serviceId, value) {
      const service = this.form.services.find((x) => x.serviceId == serviceId);
      service.isRequired = value;
    },
    handleBoatServicePeriodChange(serviceId, value) {
      const service = this.form.services.find((x) => x.serviceId == serviceId);
      service.boatServicePeriod = value;
    },
    handleSeasonStartDateInput(index, e) {
      if (!this.form.priceGroups[index].dateTo) {
        const endDate = this.$moment(e).add(6, "days").format("YYYY-MM-DD");
        this.form.priceGroups[index].dateTo = endDate;
      }
    },
    setRentalPeriod(mode) {
      switch (mode) {
        case "hourly":
          this.form.rentalPeriod = RentalPeriod.Hourly;
          break;
        case "daily":
          this.form.rentalPeriod = RentalPeriod.Daily;
          break;
        case "weekly":
          this.form.rentalPeriod = RentalPeriod.Weekly;
          break;
        default:
          this.$bvModal.show("business-model-selection-modal");
          return false;
      }

      return true;
    },
    handleBusinessModelSelected(model) {
      const result = this.setRentalPeriod(model);
      if (result) {
        this.$bvModal.hide("business-model-selection-modal");
      }
    },
    addNewAdditionalPackage() {
      this.form.additionalPackages.push({
        nameTR: "",
        descriptionTR: "",
        nameEN: "",
        descriptionEN: "",
        pricePerPerson: null,
      });
    },
    deleteAdditionalPackage(index) {
      this.form.additionalPackages.splice(index, 1);
    },
    addDifferenceItem() {
      this.form.priceDifferences.push({
        numberOfPeople: null,
        difference: null,
      });
    },
    removePriceDiff(index) {
      this.form.priceDifferences.splice(index, 1);
    },
    isValidPriceDiff(i, prop) {
      return (
        this.$v.form.priceDifferences.$model[i][prop] !== NaN &&
        this.$v.form.priceDifferences.$model[i][prop] !== null &&
        this.$v.form.priceDifferences.$model[i][prop] !== undefined &&
        this.$v.form.priceDifferences.$model[i][prop] > 0
      );
    },
  },

  watch: {
    "form.price": function () {
      if (!this.form.price || this.form.price <= 0) {
        this.listingPrice = null;
        return;
      }

      this.listingPrice = this.calculateListingPrice();
    },
    "form.rentalPeriod": async function (val) {
      if (!val) {
        return;
      }

      this.boatServices = await this.$store.dispatch(boatActions.LIST_BOAT_SERVICES, val);
    },
    havePriceDiffsByPeopleQuantity: function (val) {
      if (val) {
        if (this.form.priceDifferences.length == 0) {
          this.addDifferenceItem();
        }
      } else {
        this.form.priceDifferences = [];
      }
    },
  },

  computed: {
    isEdit() {
      return this.$router.currentRoute.name === "EditBoat";
    },
    breadcrumbItems() {
      return [
        { text: this.$t("boatsPage.tabTitle"), to: "Boats" },
        {
          text: this.isEdit ? this.$t("addOrUpdateBoatPage.updateBoat") : this.$t("addOrUpdateBoatPage.createBoat"),
          to: this.isEdit ? "EditBoat" : "CreateBoat",
        },
      ];
    },
    editorSettings() {
      return {
        editor: ClassicEditor,
      };
    },
    boatPriceLabel() {
      if (this.form.rentalPeriod) {
        return (
          this.$t(`addOrUpdateBoatPage.rentalPeriods.${this.form.rentalPeriod}`) +
          " " +
          this.$t("addOrUpdateBoatPage.boatPrice")
        );
      }

      return this.$t("addOrUpdateBoatPage.boatPrice");
    },
    boatServicePeriodSelectList() {
      if (this.form.rentalPeriod == RentalPeriod.Hourly) {
        return this.boatServicePeriods.filter((x) => x.value == BoatServicePeriod.PER_BOOKING);
      } else if (this.form.rentalPeriod == RentalPeriod.Daily) {
        return this.boatServicePeriods.filter((x) => x.value != BoatServicePeriod.PER_WEEK);
      }

      return this.boatServicePeriods;
    },
    ...mapGetters([
      "unitOfLengths",
      "fuelTypes",
      "fuelCosts",
      "engineTypes",
      "charterTypes",
      "cancellationPolicies",
      "rentalPeriods",
      "boatDocumentTypes",
      "currencies",
      "transitLogPaymentTypes",
      "remainingPaymentTypes",
      "skipperPaymentTypes",
      "boatServicePeriods",
      "additionalServicesPaymentTypes",
      "depositPaymentTypes",
      "weekDays",
      "servicePricingTypes",
    ]),
  },

  validations() {
    return {
      form: {
        name: {},
        boatTypeId: { required: requiredIf(() => !this.isDraft) },
        boatBrandId: { required: requiredIf(() => !this.isDraft) },
        boatModelId: { required: requiredIf(() => !this.isDraft) },
        numberOfCabins: { required: requiredIf(() => !this.isDraft) },
        numberOfBathroom: { required: requiredIf(() => !this.isDraft) },
        numberOfBeds: { required: requiredIf(() => !this.isDraft) },
        cruiseCapacity: { required: requiredIf(() => !this.isDraft) },
        boatLength: { required: requiredIf(() => !this.isDraft) },
        unitOfLength: { required: requiredIf(() => !this.isDraft) },
        yearOfConstruction: { required: requiredIf(() => !this.isDraft) },
        fuelCost: { required: requiredIf(() => !this.isDraft), notEqualToZero },
        staff: {},
        services: {
          $each: {
            serviceId: { required: requiredIf(() => !this.isDraft) },
            price: { required: requiredIf(() => !this.isDraft), minValue: minValue(0) },
            boatServicePeriod: {
              invalidPeriod: (value) => {
                if (this.form.rentalPeriod == this.RentalPeriod.Hourly && value == this.BoatServicePeriod.PER_WEEK) {
                  return false;
                }

                return true;
              },
            },
          },
        },
        charterType: { required: requiredIf(() => !this.isDraft), notEqualToZero },
        staffCount: {},
        countryId: { required: requiredIf(() => !this.isDraft) },
        locationId: { required: requiredIf(() => !this.isDraft) },
        marinaId: { required: requiredIf(() => !this.isDraft) },
        descriptionTR: {},
        descriptionEN: {},
        checkinTime: { required: requiredIf(() => !this.isDraft && this.form.rentalPeriod != RentalPeriod.Hourly) },
        checkoutTime: { required: requiredIf(() => !this.isDraft && this.form.rentalPeriod != RentalPeriod.Hourly) },
        transitLogPaymentType: { required: requiredIf(() => !this.isDraft) },
        transitLogPrice: {
          required: requiredIf(
            () =>
              !this.isDraft &&
              this.form.transitLogPaymentType != TransitLogPaymentType.INCLUDED &&
              this.form.transitLogPaymentType != TransitLogPaymentType.NO_TRANSIT_LOG
          ),
        },
        skipperPrice: {
          required: requiredIf(() => !this.isDraft && this.form.charterType === CharterType.SKIPPERED_OR_BAREBOAT),
        },
        skipperPaymentType: {
          required: requiredIf(() => !this.isDraft && CharterType == CharterType.SKIPPERED_OR_BAREBOAT),
        },
        additionalServicesPaymentType: {
          required: requiredIf(() => !this.isDraft),
          notEqualToZero,
        },
        apa: {
          required: requiredIf(() => !this.isDraft),
          minValue: minValue(parseInt(this.minPrepaymentRate.value)),
          maxValue: maxValue(100),
        },
        cancellationPolicy: {
          required: requiredIf(() => !this.isDraft && this.form.rentalPeriod != RentalPeriod.Hourly),
          notEqualToZero: (value) =>
            this.isDraft || this.form.rentalPeriod == RentalPeriod.Hourly ? true : value != 0,
        },
        rentalPeriod: { required: requiredIf(() => !this.isDraft), notEqualToZero },
        price: { required: requiredIf(() => !this.isDraft), minValue: minValue(0) },
        remainingPaymentType: { required: requiredIf(() => !this.isDraft) },
        images: { required: requiredIf(() => !this.isDraft), minLength: () => this.form.images.length >= 4 },
        documents: { minLength: {} },
        depositPaymentType: { required: requiredIf(() => !this.isDraft) },
        servicePricingType: { required: requiredIf(() => !this.isDraft) },
        depositPrice: {
          required: requiredIf(
            () =>
              !this.isDraft &&
              this.form.depositPaymentType != DepositPaymentType.NO_DEPOSIT &&
              this.form.rentalPeriod != RentalPeriod.Hourly
          ),
        },
        servicePrice: {
          required: requiredIf(
            () =>
              !this.isDraft &&
              this.form.servicePricingType != ServicePricingType.NO_SERVICE_PRICE &&
              this.form.rentalPeriod == RentalPeriod.Hourly
          ),
        },
        oneDayPrice: {
          required: requiredIf(
            () => !this.isDraft && this.form.rentalPeriod == RentalPeriod.Daily && this.canRentForOneDay
          ),
        },
        checkinTimeForOneDay: {
          required: requiredIf(
            () => !this.isDraft && this.form.rentalPeriod == RentalPeriod.Daily && this.canRentForOneDay
          ),
        },
        checkoutTimeForOneDay: {
          required: requiredIf(
            () => !this.isDraft && this.form.rentalPeriod == RentalPeriod.Daily && this.canRentForOneDay
          ),
        },
        weekendPriceDiff: {
          required: requiredIf(
            () => !this.isDraft && this.haveWeekendPricediff && this.form.rentalPeriod == RentalPeriod.Hourly
          ),
        },
        availableCheckinDays: {
          minLength: () =>
            this.form.rentalPeriod == RentalPeriod.Hourly ? true : this.form.availableCheckinDays.length >= 1,
        },
        priceDifferences: {
          $each: {
            numberOfPeople: {
              required: requiredIf(() => !this.isDraft && this.form.rentalPeriod == RentalPeriod.Hourly),
              minValue: minValue(0),
            },
            difference: {
              required: requiredIf(() => !this.isDraft && this.form.rentalPeriod == RentalPeriod.Hourly),
              minValue: minValue(0),
            },
          },
        },
      },

      generalInformationGroup: [
        "form.name",
        "form.boatTypeId",
        "form.boatBrandId",
        "form.boatModelId",
        "form.numberOfCabins",
        "form.numberOfBathroom",
        "form.numberOfBeds",
        "form.cruiseCapacity",
        "form.boatLength",
        "form.unitOfLength",
        "form.yearOfConstruction",
        "form.fuelCost",
        "form.checkinTime",
        "form.checkoutTime",
        "form.availableCheckinDays",
        "form.countryId",
        "form.locationId",
        "form.marinaId",
        "form.staffCount",
      ],

      servicesGroup: ["form.services"],
      pricingGroup: [
        "form.apa",
        "form.cancellationPolicy",
        "form.rentalPeriod",
        "form.price",
        "form.transitLogPaymentType",
        "form.transitLogPrice",
        "form.remainingPaymentType",
        "form.charterType",
        "form.skipperPrice",
        "form.additionalServicesPaymentType",
        "form.depositPaymentType",
        "form.depositPrice",
        "form.priceDifferences",
        "form.servicePricingType",
        "form.servicePrice",
        "form.weekendPriceDiff",
        "form.oneDayPrice",
        "form.checkinTimeForOneDay",
        "form.checkoutTimeForOneDay",
      ],
      documentsGroup: ["form.documents"],
      imagesGroup: ["form.images"],
    };
  },

  metaInfo() {
    return {
      title: this.isEdit
        ? this.$t("addOrUpdateBoatPage.updateTabTitle")
        : this.$t("addOrUpdateBoatPage.createTabTitle"),
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";

.addorupdate-boat {
  .multiselect {
    &.multiselect--disabled {
      .multiselect__tags {
        background-color: #ededed;
      }
    }

    &__tags {
      border: 1px solid #d8d6de;
      padding: 0.75rem 1.143rem;
      min-height: unset;
      height: 3.2857rem;

      .multiselect__placeholder {
        font-size: 1.143rem;
      }
    }
    &__single {
      font-size: 1.143rem;
    }
  }
}

.preview-image {
  cursor: move;

  > .img-wrapper {
    height: 200px;
    border: 1px solid #d8d6de;
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
  }

  button {
    position: absolute;
    top: 15px;
    right: 30px;
  }
}

hr.boatpage-separator {
  border-color: #d8d8d8 !important;
  margin-top: 32px;
  margin-bottom: 32px;
}
.cancellation-policies {
  li {
    margin-bottom: 18px;
    color: $primary;
    font-size: 14px;

    &:first-child {
      margin-top: 18px;
    }

    span {
      position: relative;
      left: -8px;
    }
  }
}
.listing-price {
  font-size: 18px;
  font-weight: 600;
  color: $primary;
  line-height: 1.45;
  margin-bottom: 0;
  padding-top: 0.5rem;
}
.season-listing-price {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.143rem;
  height: 3.2857rem;
  font-size: 1.143rem;
  line-height: 1.25;
  border-radius: 0.357rem;
  color: $body-color;
  font-size: 20px;
  padding-left: 0;

  &.has-price {
    background-color: $primary;
    color: white;
    padding-left: 1.143rem;
  }
}
.service-checkbox {
  label {
    font-size: 18px;
    font-weight: 500;
  }
}
.departure-boat-selection {
  .checkboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 28px;
    row-gap: 14px;

    .custom-checkbox {
      margin-right: 0;
    }
  }
}
.diff-item {
  display: flex;
  width: 80%;
  align-items: flex-end;
  gap: 6px;
  margin-bottom: 12px;

  span {
    flex-shrink: 0;
  }
}
</style>
