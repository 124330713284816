<template>
  <div class="phone-number-input" v-clickOutside="handleClickOutside">
    <div class="inner">
      <div class="country" @click.prevent="showOptions = !showOptions">
        <span>{{ selectedCountry.flag }}</span>
        <img src="@/assets/images/svg/arrowDown.svg" alt="arrow-down" title="arrow-down" />
      </div>
      <div class="phone">
        <div class="prefix">
          {{ selectedCountry.dialCode }}
        </div>
        <input
          v-model="phoneNumberMasked"
          @input="handlePhoneNumberInput"
          :placeholder="placeholder"
          class="form-control"
        />
      </div>
    </div>
    <transition>
      <div v-if="showOptions" class="options-container">
        <div class="search">
          <img src="@/assets/images/svg/search.svg" alt="search" title="search" />
          <input v-model="searchTerm" :placeholder="$t('common.search')" />
        </div>
        <hr class="m-0" />
        <div class="options">
          <div
            v-for="(country, i) in filteredCountries"
            :key="i"
            class="option"
            :class="{ selected: country.iso == selectedCountry.iso }"
            @click.prevent="setDialCode(country)"
          >
            <div class="content">
              <span class="icon">{{ country.flag }}</span>
              <span class="title">{{ country.name }} ({{ country.dialCode }})</span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getCountries, getCountryCallingCode, getExampleNumber, AsYouType } from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
import clickOutsideDirective from "@/directives/clickOutside.js";

export default {
  name: "PhoneNumberInput",

  directives: { clickOutside: clickOutsideDirective },

  props: {
    defaultCountry: {
      type: String,
      required: false,
      default: "TR",
    },
    value: {
      type: Object,
    },
  },

  data() {
    return {
      selectedCountry: null,
      phoneNumberMasked: "",
      showOptions: false,
      searchTerm: "",
    };
  },

  created() {
    this.selectedCountry = this.countries.find((x) => x.iso == this.defaultCountry);
    if (this.value.phoneNumber) {
      const type = new AsYouType(this.selectedCountry.iso);
      this.phoneNumberMasked = type.input(this.value.phoneNumber);
    }
  },

  mounted() {
    this.registerDOMEvents();
  },

  destroyed() {
    this.removeDOMEvents();
  },

  methods: {
    countryCodeToUnicodeFlag(locale) {
      const code = locale.toUpperCase();
      let flag = "";
      for (let i = 0; i < code.length; i++) {
        flag += String.fromCodePoint(0x1f1e6 - 65 + code.charCodeAt(i));
      }

      return flag;
    },

    setDialCode(country) {
      this.selectedCountry = country;
      this.phoneNumberMasked = "";

      this.$emit("input", {
        iso: this.selectedCountry.iso,
        dialCode: this.selectedCountry.dialCode,
        phoneNumber: "",
      });

      this.showOptions = false;
      this.searchTerm = "";
    },

    handleClickOutside() {
      this.showOptions = false;
    },

    handlePhoneNumberInput() {
      const type = new AsYouType(this.selectedCountry.iso);
      this.phoneNumberMasked = type.input(this.phoneNumberMasked);

      this.$emit("input", {
        iso: this.selectedCountry.iso,
        dialCode: this.selectedCountry.dialCode,
        phoneNumber: type.getNationalNumber(this.phoneNumberMasked),
      });
    },

    registerDOMEvents() {
      const input = document.querySelector(".phone-number-input .inner .phone input");

      input.addEventListener("focus", this.handleInputFocus);
      input.addEventListener("focusout", this.handleInputFocusout);
    },

    handleInputFocus() {
      document.querySelector(".phone-number-input").classList.add("active");
    },

    handleInputFocusout() {
      document.querySelector(".phone-number-input").classList.remove("active");
    },

    removeDOMEvents() {
      const input = document.querySelector(".phone-number-input .inner .phone input");
      if (input) {
        input.removeEventListener("focus", this.handleInputFocus);
        input.removeEventListener("focusout", this.handleInputFocusout);
      }
    },
  },

  watch: {
    value: function (val) {
      if (val.iso) {
        this.selectedCountry = this.countries.find((x) => x.iso == val.iso);
      } else {
        this.selectedCountry = this.countries.find((x) => x.iso == this.defaultCountry);
      }

      const type = new AsYouType(this.selectedCountry.iso);
      this.phoneNumberMasked = type.input(val.phoneNumber);
    },
  },

  computed: {
    countries() {
      const isoList = getCountries();
      const countryList = [];

      var instance = new Intl.DisplayNames([this.$i18n.locale], { type: "region" });

      isoList.forEach((iso) => {
        countryList.push({
          iso,
          name: instance.of(iso),
          dialCode: "+" + getCountryCallingCode(iso),
          flag: this.countryCodeToUnicodeFlag(iso),
        });
      });

      return countryList.sort((a, b) => a.name.localeCompare(b.name, this.$i18n.locale));
    },

    filteredCountries() {
      if (!this.searchTerm) {
        return this.countries;
      }

      return this.countries.filter(
        (x) =>
          x.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          x.iso.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          x.dialCode.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },

    placeholder() {
      const code = this.selectedCountry.iso;
      const phone = getExampleNumber(code, examples);

      return new AsYouType(code).input(phone.nationalNumber);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/PhoneNumberInput.scss";
</style>
