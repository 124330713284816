<template>
  <b-modal
    id="phone-number-confirmation"
    :title="$t('accountSettingsPage.phoneNumberConfirmation')"
    size="sm"
    :ok-title="$t('accountSettingsPage.confirm')"
    :cancel-title="$t('common.cancel')"
    :ok-disabled="isOkButtonDisabled"
    @ok="handleSubmit"
  >
    <b-form @submit.prevent="handleSubmit">
      <b-form-group label="Doğrulama Kodu">
        <b-form-input v-model="form.code" :state="submitted && !$v.form.code.$error" />
        <div v-if="submitted && $v.form.code.$error" class="invalid-feedback">
          <span v-if="!$v.form.code.required">{{ $t("messages.phoneNumberConfirmationCodeRequired") }}</span>
        </div>
      </b-form-group>

      <b-form-group>
        <VueCountdown
          ref="countdown"
          :key="countdownKey"
          :auto-start="true"
          :time="60 * 2 * 1000"
          v-slot="{ minutes, seconds }"
          @end="onCountdownEnd"
        >
          <a v-if="canSendNewCode" @click="resendConfirmationSms" href="javascript:void(0)">
            {{ $t("accountSettingsPage.sendNewCode") }}
          </a>
          <span v-else>
            {{
              $t("accountSettingsPage.newCodeCountdownText").replace("{minutes}", minutes).replace("{seconds}", seconds)
            }}
          </span>
        </VueCountdown>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import accountActions from "@/store/account/actions.js";
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  name: "PhoneNumberConfirmationModal",

  components: { VueCountdown },

  props: {
    dialCode: {
      type: String,
      required: true,
    },
    isoCode: {
      type: String,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      form: {
        code: "",
      },
      submitted: null,
      canSendNewCode: false,
      countdownKey: 1,
      isOkButtonDisabled: false,
    };
  },

  methods: {
    async handleSubmit(e) {
      e.preventDefault();
      this.isOkButtonDisabled = true;
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isOkButtonDisabled = false;
        return;
      }

      const request = {
        dialCode: this.dialCode,
        isoCode: this.isoCode,
        phoneNumber: this.phoneNumber,
        code: this.form.code,
      };

      const result = await this.$store.dispatch(accountActions.UPDATE_PHONE_NUMBER, request);
      this.isOkButtonDisabled = false;
      if (result.success) {
        this.$emit("phoneNumberUpdated");
        this.$bvModal.hide("phone-number-confirmation");
      }
    },
    async resendConfirmationSms() {
      const request = {
        countryCode: this.dialCode,
        phoneNumber: this.phoneNumber,
        isRegister: false,
      };

      const result = await this.$store.dispatch(accountActions.SEND_PHONE_NUMBER_CONFIRMATION_SMS, request);
      if (result.success) {
        this.countdownKey++;
        this.$nextTick(() => {
          this.$refs.countdown.start();
          this.canSendNewCode = false;
        });
      }
    },
    onCountdownEnd() {
      this.canSendNewCode = true;
    },
  },

  validations() {
    return {
      form: {
        code: { required },
      },
    };
  },
};
</script>
