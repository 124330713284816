<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        @click="$emit('update:isEventHandlerSidebarActive', true)"
      >
        {{ $t("calendarPage.addEvent") }}
      </b-button>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">{{ $t("calendarPage.boats") }}</span>
        </h5>
        <b-form-checkbox ref="viewAllRef" v-model="checkAll" class="mb-1">
          {{ $t("calendarPage.viewAll") }}
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group v-model="selectedBoatIds" name="event-filter" stacked>
            <b-form-checkbox v-for="boat in boats" :key="boat.id" name="event-filter" :value="boat.id" class="mb-1">
              <div
                class="rounded-circle d-inline-block mr-50"
                :style="{ height: '10px', width: '10px', 'background-color': boat.calendarColor }"
              />
              {{ getBoatDisplayName(boat) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <!-- <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" /> -->
  </div>
</template>

<script>
import calendarEvents from "@/store/calendar/actions.js";

export default {
  name: "CalendarSidebar",

  props: {
    boats: {
      type: Array,
      required: true,
    },
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {
    getBoatDisplayName(boat) {
      if (boat.name) {
        return boat.name;
      }

      if (!boat.brand && !boat.model) {
        return "-";
      }

      return `${boat.brand} - ${boat.model}`;
    },
  },

  computed: {
    checkAll: {
      get: function () {
        return this.selectedBoatIds.length == this.boats.length;
      },
      set: function (val) {
        if (val) {
          this.$store.dispatch(
            calendarEvents.UPDATE_SELECTED_BOAT_IDS,
            this.boats.map((boat) => boat.id)
          );
        } else if (this.selectedBoatIds.length === this.boats.length) {
          this.$store.dispatch(calendarEvents.UPDATE_SELECTED_BOAT_IDS, []);
        }
      },
    },
    selectedBoatIds: {
      get: function () {
        return this.$store.state.calendar.selectedBoatIds;
      },
      set: function (val) {
        this.$store.dispatch(calendarEvents.UPDATE_SELECTED_BOAT_IDS, val);
      },
    },
  },
};
</script>
