<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="handleSidebarChange"
    >
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">{{ isEdit ? $t("calendarPage.editEvent") : $t("calendarPage.addEvent") }}</h5>
          <div>
            <feather-icon
              v-if="form.id && !form.isBooking"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event', form)"
            />
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>
        <b-form class="p-2" @submit.prevent="handleSubmit">
          <b-form-group :label="$t('common.startDateLabel')">
            <VueDatePicker
              v-model="form.startDate"
              class="w-100"
              :lang="$i18n.locale"
              :disabled="form.isBooking"
              :type="datepickerType"
              :format="datepickerFormat"
            />
            <div v-if="submitted && $v.form.startDate.$error" class="invalid-feedback">
              <span v-if="!$v.form.startDate.required">{{ $t("messages.startDateRequired") }}</span>
            </div>
          </b-form-group>
          <b-form-group :label="$t('common.endDateLabel')">
            <VueDatePicker
              v-model="form.endDate"
              class="w-100"
              :lang="$i18n.locale"
              :disabled="form.isBooking"
              :type="datepickerType"
              :format="datepickerFormat"
            />

            <div v-if="submitted && $v.form.endDate.$error" class="invalid-feedback">
              <span v-if="!$v.form.endDate.required">{{ $t("messages.endDateRequired") }}</span>
            </div>
          </b-form-group>
          <b-form-group :label="$t('calendarPage.boat')" :state="submitted && !$v.form.boatId.$error">
            <v-select
              v-model="form.boatId"
              :options="boats"
              label="name"
              :reduce="(boat) => boat.id"
              :disabled="form.isBooking"
              :placeholder="$t('common.boatSelectPlaceholder')"
            >
              <template #option="{ calendarColor, name, brand, model }">
                <div
                  class="rounded-circle d-inline-block mr-50"
                  :style="{ height: '10px', width: '10px', 'background-color': calendarColor }"
                />
                <span> {{ getBoatDisplayName(name, brand, model) }} </span>
              </template>

              <template #selected-option="{ calendarColor, name, brand, model }">
                <div
                  class="rounded-circle d-inline-block mr-50"
                  :style="{ height: '10px', width: '10px', 'background-color': calendarColor }"
                />
                <span> {{ getBoatDisplayName(name, brand, model) }} </span>
              </template>
            </v-select>
            <div v-if="submitted && $v.form.boatId.$error" class="invalid-feedback d-block">
              <span v-if="!$v.form.boatId.required">{{ $t("messages.boatRequired") }}</span>
            </div>
          </b-form-group>
          <b-form-group :label="$t('calendarPage.description')">
            <b-form-textarea
              v-model="form.description"
              :state="submitted && !$v.form.description.$error"
              :disabled="form.isBooking"
            />
            <div v-if="submitted && $v.form.description.$error" class="invalid-feedback"></div>
          </b-form-group>
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="form.isBooking || submitting"
            >
              <b-spinner small class="mr-1" v-if="submitting" />
              {{ isEdit ? $t("common.edit") : $t("common.add") }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import { RentalPeriod } from "@/utils/enum.js";
import VueDatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr.js";
import "vue2-datepicker/locale/en.js";

export default {
  name: "CalendarEventHandler",

  components: { vSelect, VueDatePicker },

  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    boats: {
      type: Array,
      required: true,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {},
      submitted: null,
    };
  },

  methods: {
    handleSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.isEdit) {
        this.$emit("add-event", this.form);
      } else {
        delete this.form.id;
        this.$emit("update-event", this.form);
      }
    },

    getBoatDisplayName(name, brand, model) {
      if (name) {
        return name;
      }

      if (!brand && !model) {
        return "-";
      }

      return `${brand} - ${model}`;
    },

    handleSidebarChange(val) {
      this.$emit("change", val);

      if (!val) {
        this.submitted = null;
        this.$v.$reset();
      }
    },
  },

  watch: {
    event: {
      handler: function (newVal) {
        this.form = newVal;
      },
      deep: true,
    },
    "form.startDate": function () {
      if (!this.form.startDate || this.form.endDate) {
        return;
      }

      this.form.endDate = this.form.startDate;
    },
  },

  computed: {
    isEdit() {
      return this.form.id != null && this.form.id != undefined;
    },

    datepickerType() {
      var rentalPeriod = this.event.isBooking
        ? this.event.rentalPeriod
        : this.boats.find((x) => x.id == this.form.boatId)?.rentalPeriod;

      switch (rentalPeriod) {
        case RentalPeriod.Hourly:
          return "datetime";
        default:
          return "date";
      }
    },

    datepickerFormat() {
      if (this.datepickerType == "datetime") {
        return "DD.MM.YYYY HH:mm";
      }

      return "DD.MM.YYYY";
    },
  },

  validations() {
    return {
      form: {
        startDate: { required },
        endDate: { required },
        boatId: { required },
        description: {},
      },
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.vs__dropdown-menu {
  li {
    color: $primary;

    &.vs__dropdown-option--selected,
    &.vs__dropdown-option--highlight {
      color: white !important;
    }
  }
}

.vs__dropdown-toggle {
  height: 2.714rem;
  padding: 0.438rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
}

.vs__selected-options {
  input::placeholder {
    color: #b3b3b3;
  }
}
</style>
