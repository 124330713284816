<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">
      <div
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{ show: isCalendarOverlaySidebarActive }"
      >
        <CalendarSidebar :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive" :boats="boats" />
      </div>

      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <FullCalendar ref="refCalendar" :options="calendarOptions" class="full-calendar" />
          </div>
        </div>
      </div>

      <div
        class="body-content-overlay"
        :class="{ show: isCalendarOverlaySidebarActive }"
        @click="isCalendarOverlaySidebarActive = false"
      />

      <CalendarEventHandler
        :isEventHandlerSidebarActive="isEventHandlerSidebarActive"
        :event="event"
        :boats="boats"
        :submitting="submitting"
        @remove-event="handleRemoveEvent"
        @add-event="handleSubmitEvent($event, true)"
        @update-event="handleSubmitEvent($event, false)"
        @change="(val) => (isEventHandlerSidebarActive = val)"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import CalendarEventHandler from "@/components/calendar/CalendarEventHandler.vue";
import CalendarSidebar from "@/components/calendar/CalendarSidebar.vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import calendarActions from "@/store/calendar/actions.js";
import boatActions from "@/store/boat/actions.js";
import { mapGetters } from "vuex";

export default {
  name: "Calendar",

  components: {
    FullCalendar,
    CalendarEventHandler,
    CalendarSidebar,
  },

  data() {
    return {
      isEventHandlerSidebarActive: false,
      isCalendarOverlaySidebarActive: false,
      events: [],
      boats: [],
      event: {
        id: null,
        startDate: null,
        endDate: null,
        isBooking: false,
        boatId: null,
      },
      calendarApi: null,
      submitting: false,
    };
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.boats = await this.$store.dispatch(boatActions.GET_BOATS);
      this.$store.dispatch(
        calendarActions.UPDATE_SELECTED_BOAT_IDS,
        this.boats.map((boat) => boat.id)
      );
      this.calendarApi = this.$refs.refCalendar.getApi();

      await this.fetchEvents();
    },

    async fetchEvents() {
      const events = await this.$store.dispatch(calendarActions.GET_EVENTS);
      this.events = events.map((item) => {
        var event = {
          id: item.id,
          title: item.title,
          start: item.startDate,
          end: this.$moment(item.endDate).add(1, "days").toDate(),
          allDay: true,
          extendedProps: {
            id: item.id,
            startDate: item.startDate,
            endDate: item.endDate,
            boatId: item.boatId,
            description: item.description,
            isBooking: item.isBooking,
            rentalPeriod: item.rentalPeriod,
          },
        };

        if (item.boatId) {
          const boat = this.boats.find((x) => x.id == item.boatId);
          event.backgroundColor = boat.calendarColor;
          event.borderColor = boat.calendarColor;
        }

        return event;
      });
    },

    async handleSubmitEvent(event, isUpdate) {
      this.submitting = true;

      const savedEvent = await this.$store.dispatch(calendarActions.ADD_OR_UPDATE_EVENT, event);
      if (!savedEvent) {
        this.submitting = false;
        return;
      }

      var calendarEventObj = {
        id: savedEvent.id,
        start: savedEvent.startDate,
        end: this.$moment(savedEvent.endDate).add(1, "days").toDate(),
        allDay: true,
        extendedProps: savedEvent,
      };

      if (event.boatId) {
        const boat = this.boats.find((x) => x.id == event.boatId);

        calendarEventObj.title = this.getBoatDisplayName(boat);
        calendarEventObj.backgroundColor = boat.calendarColor;
        calendarEventObj.borderColor = boat.calendarColor;
      }

      if (isUpdate) {
        const index = this.events.findIndex((x) => x.id == calendarEventObj.id);
        if (index != -1) {
          this.events.splice(index, 1, calendarEventObj);
        }
      } else {
        this.events.push(calendarEventObj);
      }

      this.isEventHandlerSidebarActive = false;
      this.submitting = false;
    },

    handleRemoveEvent(event) {
      this.$swal({
        title: this.$t("common.approval"),
        text: this.$t("calendarPage.deleteEventText"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("common.approve"),
        cancelButtonText: this.$t("common.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (!result.value) {
          return;
        }

        this.submitting = true;

        const isSuccess = await this.$store.dispatch(calendarActions.DELETE_EVENT, event.id);
        if (isSuccess) {
          const index = this.events.findIndex((x) => x.id == event.id);
          if (index != -1) {
            this.events.splice(index, 1);
          }

          this.submitting = false;
          this.isEventHandlerSidebarActive = false;
        }
      });
    },

    handleDateClick(info) {
      this.event.startDate = info.date;
      this.isEventHandlerSidebarActive = true;
    },

    handleEventClick({ event: clickedEvent }) {
      this.grabEventDataFromEventApi(clickedEvent);
      this.isEventHandlerSidebarActive = true;
    },

    grabEventDataFromEventApi(eventApi) {
      const { extendedProps } = eventApi;
      const event = { ...extendedProps };
      event.startDate = this.$moment(event.startDate).toDate();
      event.endDate = this.$moment(event.endDate).toDate();

      this.event = event;
    },

    getBoatDisplayName(boat) {
      return `${boat.name ? boat.name + " - " : ""}${boat.brand} - ${boat.model}${
        boat.yearOfConstruction ? " - " + boat.yearOfConstruction : ""
      }`;
    },
  },

  computed: {
    eventsDisplay() {
      return this.events.filter((x) => this.selectedBoatIds.includes(x.extendedProps.boatId));
    },
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, interactionPlugin, listPlugin],
        initialView: "dayGridMonth",
        headerToolbar: {
          start: "sidebarToggle, prev,next, title",
          end: "",
        },

        editable: true,
        eventResizableFromStart: true,
        dragScroll: true,
        dayMaxEvents: 2,
        navLinks: true,
        events: this.eventsDisplay,
        eventClick: this.handleEventClick,
        dateClick: this.handleDateClick,
        eventTextColor: "#fff",
        locale: this.$i18n.locale,
        firstDay: 1,

        customButtons: {
          sidebarToggle: {
            text: "sidebar",
            click: () => {
              this.isCalendarOverlaySidebarActive = !this.isCalendarOverlaySidebarActive;
            },
          },
        },
      };
    },
    ...mapGetters(["selectedBoatIds"]),
  },

  watch: {
    isEventHandlerSidebarActive: function (val) {
      if (!val) {
        this.event = {
          extendedProps: {},
        };
      }
    },
  },

  metaInfo() {
    return {
      title: this.$t("calendarPage.tabTitle"),
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
