<template>
  <b-row class="content-header">
    <b-col class="content-header-left mb-2" cols="12" md="7">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">{{ title }}</h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item :to="{ name: 'Home' }">
                <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
              </b-breadcrumb-item>
              <b-breadcrumb-item v-for="(item, index) in items" :key="index" :active="isActive(item)" :to="{ name: item.to }">
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <b-col class="content-header-right text-md-right mb-1" md="5" cols="12">
      <slot name="right" />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "Breadcrumb",

  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },

  methods: {
    isActive(item) {
      return this.$route.name === item.to;
    },
  },
};
</script>
