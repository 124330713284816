<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <span class="brand-logo">
        <b-navbar-nav class="nav align-items-center ml-auto">
          <Locale />
        </b-navbar-nav>
      </span>
      <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-img src="@/assets/images/logo/rotaneta-horizontal.svg" alt="Rotaneta Logo" class="auth-logo" fluid />
          <b-card-title title-tag="h2" class="font-weight-bold mb-1"> {{ $t("loginPage.title") }} </b-card-title>
          <b-card-text class="mb-2"> {{ $t("loginPage.subtitle") }} </b-card-text>
          <b-form class="auth-login-form mt-2" @submit.prevent="handleSubmit">
            <b-form-group :label="$t('common.email')">
              <b-form-input v-model="form.email" :state="submitted && !$v.form.email.$error" />
              <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                <span v-if="!$v.form.email.required">{{ $t("messages.emailRequired") }}</span>
                <span v-else-if="!$v.form.email.email">{{ $t("messages.emailInvalid") }}</span>
              </div>
            </b-form-group>
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label class="col-form-label">{{ $t("common.password") }}</label>
                <b-link :to="{ name: 'ForgotPassword' }">
                  <small>{{ $t("common.forgotPassword") }}</small>
                </b-link>
              </div>
              <b-input-group class="input-group-merge" :class="{ 'is-invalid': submitted && $v.form.password.$error }">
                <b-form-input
                  v-model="form.password"
                  :state="submitted && !$v.form.password.$error"
                  class="form-control-merge"
                  :type="passwordFieldType"
                />
                <b-input-group-append is-text>
                  <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                </b-input-group-append>
              </b-input-group>
              <div v-if="submitted && $v.form.password.$error" class="invalid-feedback">
                <span v-if="!$v.form.password.required">{{ $t("messages.passwordRequired") }}</span>
              </div>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="persistent"> {{ $t("common.rememberMe") }} </b-form-checkbox>
            </b-form-group>
            <b-button type="submit" variant="primary" ref="submitBtn" block> {{ $t("common.login") }} </b-button>
          </b-form>

          <b-card-text class="text-center mt-2">
            <span>{{ $t("loginPage.areYouNew") }} </span>
            <b-link :to="{ name: 'Register' }">
              <span>&nbsp;{{ $t("loginPage.createAccount") }}</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <b-col lg="7" class="d-none d-lg-flex align-items-center p-5 auth-bg">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="require('@/assets/images/login.png')" alt="Login V2" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { togglePasswordVisibility } from "@/mixins/forms.js";
import { required, email } from "vuelidate/lib/validators";
import actions from "@/store/account/actions.js";
import { mapGetters } from "vuex";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";

export default {
  name: "Login",

  components: {
    Locale,
  },

  mixins: [togglePasswordVisibility],

  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      persistent: false,
      submitted: null,
    };
  },

  methods: {
    async handleSubmit() {
      this.$refs.submitBtn.disabled = true;
      this.$v.$touch();
      this.submitted = true;
      if (this.$v.$invalid) {
        this.$refs.submitBtn.disabled = false;
        return;
      }

      const isSuccess = await this.$store.dispatch(actions.LOGIN, this.form);
      if (isSuccess) {
        const returnUrl = this.$router.currentRoute.query.returnUrl || "/";
        this.$router.push(returnUrl);
      } else {
        this.submitted = null;
        this.$v.$reset();
        this.$refs.submitBtn.disabled = false;
      }
    },
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    ...mapGetters(["isAuthenticated"]),
  },

  validations() {
    return {
      form: {
        email: { required, email },
        password: { required },
      },
    };
  },

  metaInfo() {
    return {
      title: this.$t("loginPage.tabTitle"),
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
