<template>
  <div>
    <b-row class="mb-3">
      <b-col cols="12">
        <AnnouncementSlider />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <BoatCountCard />
      </b-col>
      <b-col md="3">
        <BookingCountCard />
      </b-col>
      <b-col md="3">
        <FavoriteCountCard />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BoatCountCard from "@/components/home/BoatCountCard.vue";
import BookingCountCard from "@/components/home/BookingCountCard.vue";
import FavoriteCountCard from "@/components/home/FavoriteCountCard.vue";
import AnnouncementSlider from "@/components/home/AnnouncementSlider.vue";

export default {
  name: "Home",

  components: {
    BoatCountCard,
    BookingCountCard,
    FavoriteCountCard,
    AnnouncementSlider,
  },

  metaInfo() {
    return {
      title: this.$t("homePage.tabTitle"),
    };
  },
};
</script>
