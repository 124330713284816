export default {
  GET_BILLING_INFORMATION: "GET_BILLING_INFORMATION",
  GET_BANK_ACCOUNTS: "GET_BANK_ACCOUNTS",
  GET_BANK_ACCOUNT_BY_ID: "GET_BANK_ACCOUNT_BY_ID",
  ADD_OR_UPDATE_BANK_ACCOUNT: "ADD_OR_UPDATE_BANK_ACCOUNT",
  DELETE_BANK_ACCOUNT: "DELETE_BANK_ACCOUNT",
  UPDATE_BILLING_INFORMATION: "UPDATE_BILLING_INFORMATION",
  CHECK_BILLING_INFORMATION_COMPLETED: "CHECK_BILLING_INFORMATION_COMPLETED",
  SET_DEFAULT_BANK_ACCOUNT: "SET_DEFAULT_BANK_ACCOUNT",
};
