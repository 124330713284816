<template>
  <b-card class="booking-listing-card">
    <b-row>
      <b-col md="2">
        <b-card-img :src="booking.boat.image" :alt="booking.boat.image" class="rounded-0" />
      </b-col>
      <b-col md="10">
        <div class="content-container">
          <b-tabs>
            <b-tab :title="$t('bookingsPage.bookingInfo')" active>
              <div class="header">
                <h4>
                  {{ booking.boat.brand }} - {{ booking.boat.model }} ({{ booking.boat.location }},
                  {{ booking.boat.city }} &middot; {{ booking.boat.marina }})
                </h4>
              </div>
              <div class="booking-info">
                <div class="info-box">
                  <p>{{ $t("bookingsPage.checkinCheckoutDates") }}</p>
                  <span v-if="booking.rentalPeriod == RentalPeriod.Hourly">
                    {{ $moment(booking.startDate).lang($i18n.locale).tz("Europe/Istanbul").format("DD MMM YYYY") }}
                    {{ $moment(booking.startDate).tz("Europe/Istanbul").format("HH:mm") }} -
                    {{ $moment(booking.endDate).tz("Europe/Istanbul").format("HH:mm") }}
                  </span>
                  <span v-else>
                    {{ $moment(booking.startDate).lang($i18n.locale).format("DD MMM YYYY") }} -
                    {{ $moment(booking.endDate).lang($i18n.locale).format("DD MMM YYYY") }}
                    ({{ totalDays }} {{ $t("bookingsPage.days") }})
                  </span>
                </div>
                <div class="info-box">
                  <p>{{ $t("bookingsPage.partnerAmount") }}</p>
                  <span>{{ $formatMoney(booking.partnerIncome, CurrencyCode[booking.currency]) }}</span>
                </div>
                <div class="info-box">
                  <p>{{ $t("bookingsPage.skipperType") }}</p>
                  <span>{{ booking.wantCaptain ? $t("bookingsPage.skippered") : $t("bookingsPage.bareboat") }}</span>
                </div>
                <div v-if="booking.status == BookingStage.PENDING_AVAILABILITY" class="info-box">
                  <p>{{ $t("bookingsPage.timeRemainingToAnswer") }}</p>
                  <span>
                    {{ timeRemainingToResponse.days }} {{ $t("bookingsPage.days") }}
                    <template v-if="timeRemainingToResponse.hours > 0">
                      {{ timeRemainingToResponse.hours }} {{ $t("bookingsPage.hours") }}
                    </template>
                  </span>
                </div>
              </div>
              <div
                class="question"
                v-if="
                  booking.availabilityCheckQuestion &&
                  (booking.status == BookingStage.PENDING_AVAILABILITY ||
                    booking.status == BookingStage.AVAILABILITY_CHECK_CANCELLED_BY_OWNER)
                "
              >
                <span>{{ $t("bookingsPage.askedQuestion") }}</span>
                <p>{{ booking.availabilityCheckQuestion }}</p>
              </div>
              <div
                class="question"
                v-else-if="
                  booking.status == BookingStage.PENDING_PRE_PAYMENT ||
                  booking.status == BookingStage.PENDING_REMAINED_PAYMENT ||
                  booking.status == BookingStage.PAYMENT_SUCCESS
                "
              >
                <span>{{ $t("bookingsPage.timeRemainingToBooking") }}</span>
                <p>{{ dayRemainingToCheckin }} {{ $t("bookingsPage.days") }}</p>
              </div>
              <div
                v-else-if="
                  booking.status != BookingStage.PENDING_AVAILABILITY ||
                  booking.status != BookingStage.AVAILABILITY_CHECK_CANCELLED_BY_OWNER ||
                  booking.status == BookingStage.REFUND_REQUEST_BY_CUSTOMER ||
                  booking.status == BookingStage.REFUND_REQUEST_BY_PARTNER ||
                  booking.status == BookingStage.REFUND_SUCCESS_CANCELLED_BY_CUSTOMER ||
                  booking.status == BookingStage.AVAILABILITY_CHECK_TIMED_OUT ||
                  booking.status == BookingStage.REFUND_SUCCESS_CANCELLED_BY_PARTNER ||
                  booking.status == BookingStage.CANCELLED_BY_PARTNER ||
                  booking.status == BookingStage.CANCELLED_BY_CUSTOMER
                "
              ></div>
              <div class="question" v-else-if="booking.isStarted">
                <span>{{ $t("bookingsPage.timeRemainingToBooking") }}</span>
                <p>{{ $t("bookingsPage.bookingStartedDesc") }}</p>
              </div>
              <div class="question" v-else-if="booking.isFinished">
                <span>{{ $t("bookingsPage.timeRemainingToBooking") }}</span>
                <p>{{ $t("bookingsPage.bookingCompletedDesc") }}</p>
              </div>
            </b-tab>
            <b-tab :title="$t('bookingsPage.customerInfo')">
              <div class="header">
                <h4>{{ $t("bookingsPage.customerInformations") }}</h4>
                <h4>&middot;</h4>
                <span>{{ $t("bookingsPage.hidingUserInfoDesc") }}</span>
              </div>
              <div class="booking-info">
                <div class="info-box">
                  <p>{{ $t("bookingsPage.nameSurname") }}</p>
                  <span>{{ booking.customer.fullName }}</span>
                </div>
                <div class="info-box">
                  <p>{{ $t("common.phone") }}</p>
                  <span>{{ booking.customer.phoneNumber }}</span>
                </div>
                <div class="info-box">
                  <p>{{ $t("common.email") }}</p>
                  <span>{{ booking.customer.email }}</span>
                </div>
              </div>
              <div
                class="question"
                v-if="
                  booking.status != BookingStage.REJECTED_BY_OWNER &&
                  booking.status != BookingStage.CANCELLED_BY_CUSTOMER
                "
              >
                <span>{{ $t("bookingsPage.timeRemainingToBooking") }}</span>
                <p>{{ dayRemainingToCheckin }} {{ $t("bookingsPage.days") }}</p>
              </div>
            </b-tab>
            <b-tab
              :title="$t('bookingsPage.extraFees')"
              v-if="booking.services.length > 0 || booking.additionalPackages.length > 0"
            >
              <div class="header">
                <h4>{{ $t("bookingsPage.addedServices") }}</h4>
                <span>
                  ({{ $t("bookingsPage.total") }}: {{ booking.services.length }} {{ $t("bookingsPage.service") }}
                  {{ $t("bookingsPage.price") }}
                  {{ $formatMoney(booking.servicesAmount, CurrencyCode[booking.currency]) }})
                </span>
              </div>
              <div class="service-list">
                <div v-for="(item, index) in servicesGrouped" :key="index" class="service-list-item">
                  <h3>{{ item.categoryName }}</h3>
                  <div>
                    <div v-for="(service, index) in item.services" :key="index" class="service-box">
                      <img :src="service.image" />
                      <div>
                        <p class="name">{{ service.name }}</p>
                        <p class="price">{{ $formatMoney(service.price, CurrencyCode[booking.currency]) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="booking.additionalPackages.length > 0" class="service-list-item">
                  <h3>{{ $t("bookingsPage.extraPackages") }}</h3>
                  <div>
                    <div v-for="(item, index) in booking.additionalPackages" :key="index" class="service-box">
                      <div>
                        <p class="name">{{ item.name }}</p>
                        <p class="price">{{ $formatMoney(item.price, CurrencyCode[booking.currency]) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
          <div class="buttons">
            <div>
              <b-button
                type="button"
                variant="success"
                v-if="booking.status == BookingStage.PENDING_AVAILABILITY"
                :disabled="loading"
                @click.prevent="answerAvailabilityCheck(true)"
              >
                <b-spinner small class="mr-1" v-if="loading" />
                {{ $t("bookingsPage.available") }}
              </b-button>
              <b-button
                type="button"
                variant="outline-danger"
                v-if="booking.status == BookingStage.PENDING_AVAILABILITY"
                :disabled="loading"
                @click.prevent="answerAvailabilityCheck(false)"
              >
                <b-spinner small class="mr-1" v-if="loading" />
                {{ $t("bookingsPage.reject") }}
              </b-button>
              <b-button
                type="button"
                variant="secondary"
                v-if="booking.status == BookingStage.AVAILABILITY_CHECK_CANCELLED_BY_OWNER"
                disabled
              >
                {{ $t("bookingsPage.timeoutConditionDesc") }}
              </b-button>
              <b-button
                type="button"
                variant="danger"
                v-if="
                  (booking.status == BookingStage.PENDING_PRE_PAYMENT ||
                    booking.status == BookingStage.PENDING_REMAINED_PAYMENT ||
                    booking.status == BookingStage.PAYMENT_SUCCESS) &&
                  !booking.isStarted
                "
                :disabled="loading"
                @click.prevent="cancelReservation"
              >
                <b-spinner small class="mr-1" v-if="loading" />
                {{ $t("bookingsPage.cancelBooking") }}
              </b-button>
              <b-button
                type="button"
                variant="primary"
                v-if="
                  (booking.status == BookingStage.PENDING_REMAINED_PAYMENT ||
                    booking.status == BookingStage.PAYMENT_SUCCESS ||
                    booking.status == BookingStage.COMPLETED) &&
                  booking.isStarted &&
                  !booking.deliveryRecordUrl
                "
                :disabled="loading"
                @click.prevent="uploadDeliveryRecord"
              >
                <b-spinner small class="mr-1" v-if="loading" />
                {{ $t("bookingsPage.uploadDeliveryRecord") }}
              </b-button>
              <b-button
                type="button"
                variant="outline-danger"
                v-if="
                  (booking.status == BookingStage.PENDING_REMAINED_PAYMENT ||
                    booking.status == BookingStage.PAYMENT_SUCCESS ||
                    booking.status == BookingStage.COMPLETED) &&
                  booking.isStarted &&
                  booking.deliveryRecordUrl
                "
                :disabled="loading"
                @click.prevent="removeDocument('deliveryRecordUrl')"
              >
                <b-spinner small class="mr-1" v-if="loading" />
                {{ $t("bookingsPage.removeDeliveryRecord") }}
              </b-button>
              <input
                :ref="'delivery-record' + booking.id"
                @input="handleDeliveryRecordInput"
                type="file"
                class="d-none"
              />
              <b-button
                type="button"
                variant="outline-primary"
                v-if="
                  (booking.status == BookingStage.PENDING_REMAINED_PAYMENT ||
                    booking.status == BookingStage.PAYMENT_SUCCESS) &&
                  booking.isStarted &&
                  !booking.isFinished &&
                  !booking.deliveryRecordUrl
                "
                @click.prevent="downloadBlankDeliveryRecord"
              >
                <feather-icon icon="DownloadIcon" />
                {{ $t("bookingsPage.downloadDeliveryRecord") }}
              </b-button>
              <b-button
                type="button"
                variant="primary"
                v-if="
                  (booking.status == BookingStage.PENDING_REMAINED_PAYMENT ||
                    booking.status == BookingStage.PAYMENT_SUCCESS ||
                    booking.status == BookingStage.COMPLETED) &&
                  booking.isStarted &&
                  !booking.isFinished &&
                  !booking.invoiceUrl
                "
                :disabled="loading"
                @click.prevent="uploadInvoice"
              >
                <b-spinner small class="mr-1" v-if="loading" />
                {{ $t("bookingsPage.uploadInvoice") }}
              </b-button>
              <b-button
                type="button"
                variant="outline-danger"
                v-if="
                  (booking.status == BookingStage.PENDING_REMAINED_PAYMENT ||
                    booking.status == BookingStage.PAYMENT_SUCCESS ||
                    booking.status == BookingStage.COMPLETED) &&
                  booking.isStarted &&
                  !booking.isFinished &&
                  booking.invoiceUrl
                "
                :disabled="loading"
                @click.prevent="removeDocument('invoiceUrl')"
              >
                <b-spinner small class="mr-1" v-if="loading" />
                {{ $t("bookingsPage.removeInvoice") }}
              </b-button>
              <input :ref="'invoice' + booking.id" @input="handleInvoiceInput" type="file" class="d-none" />
              <b-button
                type="button"
                variant="outline-danger"
                v-if="booking.status == BookingStage.REJECTED_BY_OWNER"
                disabled
              >
                {{ $t("bookingsPage.bookingRejected") }}
              </b-button>
              <b-button
                type="button"
                variant="outline-danger"
                v-if="
                  booking.status == BookingStage.CANCELLED_BY_CUSTOMER ||
                  booking.status == BookingStage.REFUND_REQUEST_BY_CUSTOMER ||
                  booking.status == BookingStage.REFUND_SUCCESS_CANCELLED_BY_CUSTOMER
                "
                disabled
              >
                {{ $t("bookingsPage.bookingCancelledByCustomer") }}
              </b-button>
              <b-button
                type="button"
                variant="outline-danger"
                v-if="
                  booking.status == BookingStage.CANCELLED_BY_PARTNER ||
                  booking.status == BookingStage.REFUND_REQUEST_BY_PARTNER ||
                  booking.status == BookingStage.REFUND_SUCCESS_CANCELLED_BY_PARTNER
                "
                disabled
              >
                {{ $t("bookingsPage.cancelled") }}
              </b-button>
              <b-button
                type="button"
                variant="light"
                v-if="booking.status == BookingStage.AVAILABILITY_CHECK_TIMED_OUT"
                disabled
              >
                {{ $t("bookingsPage.timeouted") }}
              </b-button>
            </div>
            <div>
              <b-button
                :to="{ name: 'Chat', query: { id: booking.id } }"
                variant="warning"
                v-if="booking.unreadMessageCount > 0"
              >
                {{ booking.unreadMessageCount }} {{ $t("bookingsPage.message") }}
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <div class="booking-number">{{ booking.bookingNumber }}</div>
  </b-card>
</template>

<script>
import { BookingStage, PolicyType, CurrencyCode, RentalPeriod } from "@/utils/enum.js";
import bookingActions from "@/store/booking/actions.js";
import policyActions from "@/store/policy/actions.js";
import FeatherIcon from "../../@core/components/feather-icon/FeatherIcon.vue";
import accountActions from "@/store/account/actions.js";

export default {
  components: { FeatherIcon },
  name: "BookingListingCard",

  data() {
    return {
      loading: false,
      BookingStage,
      CurrencyCode,
      RentalPeriod,
    };
  },

  props: {
    booking: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async answerAvailabilityCheck(isAvailable) {
      this.loading = true;
      if (isAvailable) {
        const billingInfoCompleted = await this.checkBillingInfo();
        if (!billingInfoCompleted) {
          return;
        }
        this.$swal({
          title: this.$t("common.approval"),
          text: this.$t("messages.availabilityAccept"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("common.approve"),
          cancelButtonText: this.$t("common.cancel"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            const answerResult = await this.$store.dispatch(bookingActions.ANSWER_AVAILABILITY_CHECK, {
              bookingId: this.booking.id,
              isAvailable: true,
            });

            if (answerResult.success) {
              this.booking.status = answerResult.data.status;
            }
          }

          this.loading = false;
        });
      } else {
        this.$swal({
          title: this.$t("common.approval"),
          text: this.$t("messages.availabilityReject"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("common.approve"),
          cancelButtonText: this.$t("common.cancel"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            const answerResult = await this.$store.dispatch(bookingActions.ANSWER_AVAILABILITY_CHECK, {
              bookingId: this.booking.id,
              isAvailable: false,
            });

            if (answerResult.success) {
              this.booking.status = answerResult.data.status;
            }
          }

          this.loading = false;
        });
      }
    },

    async cancelReservation() {
      this.loading = true;

      this.$swal({
        title: this.$t("common.approval"),
        text: this.$t("messages.cancelBookingApprove"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("common.approve"),
        cancelButtonText: this.$t("common.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        input: "textarea",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const cancelResult = await this.$store.dispatch(bookingActions.CANCEL_BOOKING, {
            bookingId: this.booking.id,
            cancelReason: result.value,
          });
          if (cancelResult.success) {
            this.booking.status = cancelResult.data.status;
          }
        }
      });
    },

    uploadDeliveryRecord() {
      this.$refs[`delivery-record${this.booking.id}`].click();
    },

    uploadInvoice() {
      this.$refs[`invoice${this.booking.id}`].click();
    },

    async handleDeliveryRecordInput(e) {
      this.loading = true;
      const file = e.target.files[0];
      const result = await this.$store.dispatch(bookingActions.UPLOAD_DELIVERY_RECORD, {
        bookingId: this.booking.id,
        file,
      });

      if (result.success) {
        this.booking.deliveryRecordUrl = result.data.documentUrl;
      }

      this.loading = false;
    },

    async handleInvoiceInput(e) {
      this.loading = true;
      const file = e.target.files[0];
      const result = await this.$store.dispatch(bookingActions.UPLOAD_INVOICE, {
        bookingId: this.booking.id,
        file,
      });

      if (result.success) {
        this.booking.invoiceUrl = result.data.invoiceUrl;
      }

      this.loading = false;
    },

    async downloadBlankDeliveryRecord() {
      const data = await this.$store.dispatch(policyActions.GET_POLICY_BY_TYPE, PolicyType.BOAT_DELIVERY_RECORD);
      if (!data) {
        return;
      }

      const el = document.createElement("a");
      el.setAttribute("href", data.policyUrl);
      el.setAttribute("download", "record.pdf");
      el.setAttribute("target", "_blank");
      document.body.appendChild(el);
      el.click();
      document.body.removeChild(el);
    },

    async removeDocument(documentType) {
      this.loading = true;
      const result = await this.$store.dispatch(bookingActions.REMOVE_DOCUMENT, {
        bookingId: this.booking.id,
        documentType,
      });

      if (result.success) {
        this.booking[documentType] = null;
      }

      this.loading = false;
    },

    async checkBillingInfo() {
      const billingInfoCompleted = await this.$store.dispatch(accountActions.CHECK_BILLING_INFO_COMPLETED);
      if (!billingInfoCompleted) {
        this.$swal({
          title: this.$t("bookingsPage.missingBillingInfoTitle"),
          text: this.$t("bookingsPage.missingBillingInfoDesc"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("bookingsPage.missingBillingInfoButtonText"),
          cancelButtonText: this.$t("common.cancel"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$router.push({ name: "BillingInformation" });
          }
        });
        this.loading = false;
        return;
      }

      return billingInfoCompleted;
    },
  },

  computed: {
    totalDays() {
      const start = this.$moment(this.booking.startDate);
      const end = this.$moment(this.booking.endDate);

      return end.diff(start, "days");
    },
    timeRemainingToResponse() {
      const now = this.$moment();
      const createDate = this.$moment(this.booking.createdDateTime);
      const expiryDate = createDate.add(3, "days");
      const diff = expiryDate.diff(now, "hours");

      var result = {
        days: ~~(diff / 24),
        hours: diff % 24,
      };

      if (result.days <= 0) {
        result = {
          days: 0,
          hours: 0,
        };
      }

      return result;
    },
    dayRemainingToCheckin() {
      const start = this.$moment(this.booking.startDate);
      const now = this.$moment();
      const remainDays = start.diff(now, "days");

      return remainDays < 0 ? 0 : remainDays;
    },
    servicesGrouped() {
      var services = this.booking.services.reduce(function (rv, x) {
        (rv[x["category"]] = rv[x["category"]] || []).push(x);
        return rv;
      }, []);

      const result = [];
      Object.keys(services).map((key) => {
        return result.push({
          categoryName: key,
          services: services[key].map((item) => {
            return {
              name: item.name,
              image: item.image,
              price: item.price,
            };
          }),
        });
      });

      return result;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/BookingListingCard.scss";
</style>
