<template>
  <b-modal id="boat-rules" :title="$t('common.boatPostingRules')" size="lg" ok-only :ok-title="$t('common.ok')">
    <b-spinner v-if="isLoading" class="m-auto" />
    <div v-else>
      <p v-for="(item, index) in boatPostingRules" :key="item.id">{{ index + 1 }}. {{ item.rule }}</p>
    </div>
  </b-modal>
</template>

<script>
import boatActions from "@/store/boat/actions.js";

export default {
  name: "BoatRulesModal",

  data() {
    return {
      isLoading: false,
      boatPostingRules: [],
    };
  },

  async mounted() {
    await this.init();

    this.$root.$on("langChanged", async () => {
      await this.init();
    });
  },

  methods: {
    async init() {
      this.isLoading = true;
      this.boatPostingRules = await this.$store.dispatch(boatActions.LIST_BOAT_POSTING_RULES);
      this.isLoading = false;
    },
  },
};
</script>
