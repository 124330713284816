<template>
  <b-modal
    id="business-model-selection-modal"
    size="lg"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
  >
    <template #default>
      <div class="header">
        <div>
          <h2>{{ $t("addOrUpdateBoatPage.rentalPeriodSelection") }}</h2>
        </div>
        <p>{{ $t("addOrUpdateBoatPage.rentalPeriodSelectionDesc") }}</p>
      </div>

      <div class="options">
        <div @click="selectRentalPeriod('hourly')" class="option">
          <img src="@/assets/images/icons/hourly.svg" alt="hourly" />
          <div>
            <p>{{ $t("addOrUpdateBoatPage.hourly") }}</p>
            <p>{{ $t("addOrUpdateBoatPage.hourlyDesc") }}</p>
          </div>
        </div>

        <div @click="selectRentalPeriod('daily')" class="option">
          <img src="@/assets/images/icons/daily.svg" alt="daily" />
          <div>
            <p>{{ $t("addOrUpdateBoatPage.daily") }}</p>
            <p>{{ $t("addOrUpdateBoatPage.dailyDesc") }}</p>
          </div>
        </div>

        <div @click="selectRentalPeriod('weekly')" class="option">
          <img src="@/assets/images/icons/weekly.svg" alt="weekly" />
          <div>
            <p>{{ $t("addOrUpdateBoatPage.weekly") }}</p>
            <p>{{ $t("addOrUpdateBoatPage.weeklyDesc") }}</p>
          </div>
        </div>
      </div>

      <img
        v-if="!hideCloseButton"
        @click="$bvModal.hide('business-model-selection-modal')"
        class="close"
        src="@/assets/images/icons/close.svg"
        alt="close"
      />
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "BoatBusinessModelSelectionModal",

  props: {
    hideCloseButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    selectRentalPeriod(mode) {
      this.$emit("selected", mode);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/BoatBusinessModelSelectionModal.scss";
</style>
