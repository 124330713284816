export default {
  IS_AUTHENTICATED: "IS_AUTHENTICATED",
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  LOGOUT: "LOGOUT",
  GET_ACCOUNT: "GET_ACCOUNT",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  GET_ACCOUNT_DETAILS: "GET_ACCOUNT_DETAILS",
  UPDATE_GENERAL_SETTINGS: "UPDATE_GENERAL_SETTINGS",
  UPDATE_NOTIFICATION_SETTINGS: "UPDATE_NOTIFICATION_SETTINGS",
  SET_LANGUAGE: "SET_LANGUAGE",
  CHECK_BILLING_INFO_COMPLETED: "CHECK_BILLING_INFO_COMPLETED",
  UPDATE_CONTACT_SETTINGS: "UPDATE_CONTACT_SETTINGS",
  SEND_PHONE_NUMBER_CONFIRMATION_SMS: "SEND_PHONE_NUMBER_CONFIRMATION_SMS",
  UPDATE_PHONE_NUMBER: "UPDATE_PHONE_NUMBER",
};
