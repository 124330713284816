<template>
  <b-card>
    <b-form @submit.prevent="handleSubmit">
      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('common.currentPassword')">
            <b-input-group class="input-group-merge" :class="{ 'is-invalid': submitted && $v.form.currentPassword.$error }">
              <b-form-input
                v-model="form.currentPassword"
                :type="passwordFieldTypeOld"
                :state="submitted && !$v.form.currentPassword.$error"
              />
              <b-input-group-append is-text>
                <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
              </b-input-group-append>
            </b-input-group>
            <div v-if="submitted && $v.form.currentPassword.$error" class="invalid-feedback">
              <span v-if="!$v.form.currentPassword.required">{{ $t("messages.currentPasswordRequired") }}</span>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('common.newPassword')">
            <b-input-group class="input-group-merge" :class="{ 'is-invalid': submitted && $v.form.newPassword.$error }">
              <b-form-input v-model="form.newPassword" :type="passwordFieldTypeNew" :state="submitted && !$v.form.newPassword.$error" />
              <b-input-group-append is-text>
                <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
              </b-input-group-append>
            </b-input-group>
            <div v-if="submitted && $v.form.newPassword.$error" class="invalid-feedback">
              <span v-if="!$v.form.newPassword.required">{{ $t("messages.newPasswordRequired") }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="$t('common.newPasswordConfirmation')">
            <b-input-group class="input-group-merge" :class="{ 'is-invalid': submitted && $v.form.newPasswordConfirmation.$error }">
              <b-form-input
                v-model="form.newPasswordConfirmation"
                :type="passwordFieldTypeRetype"
                :state="submitted && !$v.form.newPasswordConfirmation.$error"
              />
              <b-input-group-append is-text>
                <feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer" @click="togglePasswordRetype" />
              </b-input-group-append>
            </b-input-group>
            <div v-if="submitted && $v.form.newPasswordConfirmation.$error" class="invalid-feedback">
              <span v-if="!$v.form.newPasswordConfirmation.required">{{ $t("messages.passwordConfirmationRequired") }}</span>
              <span v-else-if="!$v.form.newPasswordConfirmation.sameAs">{{ $t("messages.passwordConfirmationInvalid") }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button type="submit" ref="submitBtn" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1">
            {{ $t("common.save") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { required } from "vuelidate/lib/validators";
import actions from "@/store/account/actions.js";

export default {
  name: "AccountSettingPassword",

  directives: {
    Ripple,
  },

  data() {
    return {
      form: {
        currentPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
      },
      submitted: null,
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },

  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },

  methods: {
    async handleSubmit() {
      this.$refs.submitBtn.disabled = true;
      this.$v.$touch();
      this.submitted = true;
      if (this.$v.$invalid) {
        this.$refs.submitBtn.disabled = false;
        return;
      }

      try {
        const isSuccess = await this.$store.dispatch(actions.UPDATE_PASSWORD, this.form);
        if (isSuccess) {
          this.form = {
            currentPassword: "",
            newPassword: "",
            newPasswordConfirmation: "",
          };
        }
      } finally {
        this.resetForm();
      }
    },

    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    resetForm() {
      this.submitted = null;
      this.$v.$reset();
      this.$refs.submitBtn.disabled = false;
    },
  },

  validations() {
    return {
      form: {
        currentPassword: { required },
        newPassword: { required },
        newPasswordConfirmation: {
          required,
          sameAs: (value) => value === this.form.newPassword,
        },
      },
    };
  },
};
</script>
