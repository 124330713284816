<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <span class="brand-logo">
        <b-navbar-nav class="nav align-items-center ml-auto">
          <Locale />
        </b-navbar-nav>
      </span>
      <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-img src="@/assets/images/logo/rotaneta-horizontal.svg" alt="Rotaneta logo" class="auth-logo" fluid />
          <b-card-title title-tag="h2" class="font-weight-bold mb-1"> {{ $t("forgotPasswordPage.title") }} 🔒 </b-card-title>
          <b-card-text class="mb-2"> {{ $t("forgotPasswordPage.subtitle") }} </b-card-text>
          <b-form class="auth-forgot-password-form mt-2" @submit.prevent="handleSubmit">
            <b-form-group :label="$t('common.email')">
              <b-form-input v-model="form.email" :state="submitted && !$v.form.email.$error" />
              <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                <span v-if="!$v.form.email.required">{{ $t("messages.emailRequired") }}</span>
                <span v-if="!$v.form.email.email">{{ $t("messages.emailInvalid") }}</span>
              </div>
            </b-form-group>
            <b-button type="submit" ref="submitBtn" variant="primary" block> {{ $t("forgotPasswordPage.submitButtonText") }} </b-button>
          </b-form>
          <p class="text-center mt-2">
            <b-link :to="{ name: 'Login' }"> <feather-icon icon="ChevronLeftIcon" /> {{ $t("forgotPasswordPage.backToLogin") }} </b-link>
          </p>
        </b-col>
      </b-col>
      <b-col lg="7" class="d-none d-lg-flex align-items-center p-5 auth-bg">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="require('@/assets/images/password-reset.png')" alt="Forgot password V2" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import actions from "@/store/account/actions.js";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";

export default {
  name: "ForgotPassword",

  components: {
    Locale,
  },

  data() {
    return {
      form: {
        email: "",
      },
      submitted: null,
    };
  },

  methods: {
    async handleSubmit() {
      this.$refs.submitBtn.disabled = true;
      this.$v.$touch();
      this.submitted = true;
      if (this.$v.$invalid) {
        this.$refs.submitBtn.disabled = false;
        return;
      }

      const isSuccess = await this.$store.dispatch(actions.FORGOT_PASSWORD, this.form);
      if (isSuccess) {
        this.form.email = "";
      }

      this.submitted = null;
      this.$refs.submitBtn.disabled = false;
      this.$v.$reset();
    },
  },

  validations() {
    return {
      form: {
        email: { required, email },
      },
    };
  },

  metaInfo() {
    return {
      title: this.$t("forgotPasswordPage.tabTitle"),
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
