import actionTypes from "./actions.js";
import mutationTypes from "./mutations.js";
import httpClient from "@/libs/axios.js";
import i18n from "@/libs/i18n/index.js";

const state = {
  boats: [],
  boatFeatures: {},
  boatTypes: {},
  boatBrands: {},
  boatModels: {},
  boatFlags: {},
  boatServices: {},
  boatPostingRules: {},
  boatCount: null,
  favoriteBoatCount: null,
};

const mutations = {
  [mutationTypes.SET_BOATS](state, payload) {
    state.boats = payload;
    state.boatCount = payload.length;
  },
  [mutationTypes.SET_BOAT_FEATURES](state, payload) {
    state.boatFeatures[i18n.locale] = payload;
  },
  [mutationTypes.SET_BOAT_TYPES](state, payload) {
    state.boatTypes[i18n.locale] = payload;
  },
  [mutationTypes.SET_BOAT_BRANDS](state, payload) {
    state.boatBrands[i18n.locale] = payload;
  },
  [mutationTypes.SET_BOAT_MODELS](state, payload) {
    state.boatModels[payload.id] = {
      ...state.boatModels[payload.id],
      [i18n.locale]: payload.data,
    };
  },
  [mutationTypes.SET_BOAT_FLAGS](state, payload) {
    state.boatFlags[i18n.locale] = payload;
  },
  [mutationTypes.SET_BOAT_SERVICES](state, payload) {
    state.boatServices[payload.rentalPeriod] = {
      ...state.boatServices[payload.rentalPeriod],
      [i18n.locale]: payload.data,
    };
  },
  [mutationTypes.SET_BOAT_POSTING_RULES](state, payload) {
    state.boatPostingRules[i18n.locale] = payload;
  },
  [mutationTypes.SET_BOAT_COUNT](state, payload) {
    state.boatCount = payload;
  },
  [mutationTypes.SET_FAVORITE_BOAT_COUNT](state, payload) {
    state.favoriteBoatCount = payload;
  },
};

const actions = {
  async [actionTypes.LIST_BOAT_FEATURES]({ commit, getters }) {
    if (getters.boatFeatures && getters.boatFeatures[i18n.locale]) {
      return getters.boatFeatures[i18n.locale];
    }

    const { data: result } = await httpClient.get("/boat/features");
    if (result.success) {
      commit(mutationTypes.SET_BOAT_FEATURES, result.data);
      return result.data;
    }
  },
  async [actionTypes.LIST_BOAT_TYPES]({ commit, getters }) {
    if (getters.boatTypes && getters.boatTypes[i18n.locale]) {
      return getters.boatTypes[i18n.locale];
    }

    const { data: result } = await httpClient.get("/boat/types");
    if (result.success) {
      commit(mutationTypes.SET_BOAT_TYPES, result.data);
      return result.data;
    }
  },
  async [actionTypes.LIST_BOAT_BRANDS]({ commit, getters }) {
    if (getters.boatBrands && getters.boatBrands[i18n.locale]) {
      return getters.boatBrands[i18n.locale];
    }

    const { data: result } = await httpClient.get("/boat/brands");
    if (result.success) {
      commit(mutationTypes.SET_BOAT_BRANDS, result.data);
      return result.data;
    }
  },
  async [actionTypes.LIST_BOAT_MODELS_BY_BRAND_ID]({ commit, getters }, id) {
    if (
      getters.boatModels[id] &&
      getters.boatModels[id][i18n.locale] &&
      getters.boatModels[id][i18n.locale].length > 0
    ) {
      return getters.boatModels[id][i18n.locale];
    }

    const { data: result } = await httpClient.get(`/boat/brands/${id}/models`);
    if (result.success) {
      commit(mutationTypes.SET_BOAT_MODELS, { id, data: result.data });
      return result.data;
    }
  },
  async [actionTypes.LIST_BOAT_FLAGS]({ commit, getters }) {
    if (getters.boatFlags && getters.boatFlags[i18n.locale]) {
      return getters.boatFlags[i18n.locale];
    }

    const { data: result } = await httpClient.get("/boat/flags");
    if (result.success) {
      commit(mutationTypes.SET_BOAT_FLAGS, result.data);
      return result.data;
    }
  },
  async [actionTypes.LIST_BOAT_SERVICES]({ commit, getters }, rentalPeriod) {
    if (
      getters.boatServices[rentalPeriod] &&
      getters.boatServices[rentalPeriod][i18n.locale] &&
      getters.boatServices[rentalPeriod][i18n.locale].length > 0
    ) {
      return getters.boatServices[rentalPeriod][i18n.locale];
    }

    const { data: result } = await httpClient.get(`/boat/services?period=${rentalPeriod}`);
    if (result.success) {
      commit(mutationTypes.SET_BOAT_SERVICES, { rentalPeriod, data: result.data });
      return result.data;
    }
  },
  async [actionTypes.CREATE_BOAT]({ dispatch }, boat) {
    const { data: result } = await httpClient.post("/boat", boat);
    if (result.success) {
      await dispatch(actionTypes.GET_BOATS, true);
    }

    return result.success;
  },
  async [actionTypes.UPDATE_BOAT]({ dispatch }, boat) {
    const { data: result } = await httpClient.put("/boat", boat);
    if (result.success) {
      await dispatch(actionTypes.GET_BOATS, true);
    }

    return result.success;
  },
  async [actionTypes.GET_BOAT_BY_ID]({ commit }, id) {
    const { data: result } = await httpClient.get(`/boat/${id}`);
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.GET_BOATS]({ commit, getters }, forceUpdate = false) {
    if (getters.boats.length > 0 && !forceUpdate) {
      return getters.boats;
    }
    const { data: result } = await httpClient.get("/boat");
    if (result.success) {
      commit(mutationTypes.SET_BOATS, result.data);
      return result.data;
    }
  },
  async [actionTypes.DELETE_BOAT]({ commit }, id) {
    const { data: result } = await httpClient.delete(`/boat/${id}`);
    return result.success;
  },
  async [actionTypes.LIST_SPOKEN_LANGUAGES]({ commit }) {
    const { data: result } = await httpClient.get("/boat/spoken-languages");
    if (result.success) {
      return result.data;
    }
  },
  async [actionTypes.LIST_BOAT_POSTING_RULES]({ commit, getters }) {
    if (getters.boatPostingRules && getters.boatPostingRules[i18n.locale]) {
      return getters.boatPostingRules[i18n.locale];
    }

    const { data: result } = await httpClient.get("/boat/posting-rules");
    if (result.success) {
      commit(mutationTypes.SET_BOAT_POSTING_RULES, result.data);
      return result.data;
    }
  },
  async [actionTypes.GET_BOAT_COUNT]({ commit, getters }) {
    if (getters.boatCount) {
      return getters.boatCount;
    }

    if (getters.boats.length > 0) {
      commit(mutationTypes.SET_BOAT_COUNT, getters.boats.length);
      return getters.boats.length;
    }

    const { data: result } = await httpClient.get("/boat/count");
    if (result.success) {
      commit(mutationTypes.SET_BOAT_COUNT, result.data);
      return result.data;
    }
  },
  async [actionTypes.GET_FAVORITE_BOAT_COUNT]({ commit, getters }) {
    if (getters.favoriteBoatCount) {
      return getters.favoriteBoatCount;
    }

    const { data: result } = await httpClient.get("/boat/favorite-count");
    if (result.success) {
      commit(mutationTypes.SET_FAVORITE_BOAT_COUNT, result.data);
      return result.data;
    }
  },
};

const getters = {
  boats: (state) => state.boats,
  boatFeatures: (state) => state.boatFeatures,
  boatTypes: (state) => state.boatTypes,
  boatBrands: (state) => state.boatBrands,
  boatModels: (state) => state.boatModels,
  boatFlags: (state) => state.boatFlags,
  boatServices: (state) => state.boatServices,
  boatPostingRules: (state) => state.boatPostingRules,
  boatCount: (state) => state.boatCount,
  favoriteBoatCount: (state) => state.favoriteBoatCount,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
