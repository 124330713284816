<template>
  <div class="text-center empty-list">
    <b-img :src="image" />
    <div class="empty-list-btn-wrapper">
      <b-link :to="{ name: buttonLink }" class="empty-list-btn">{{ buttonText }}</b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyListGraphic",

  props: {
    image: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonLink: {
      type: String,
      required: true,
    },
  },
};
</script>
