<template>
  <b-carousel class="announcements" v-if="announcements.length" id="carousel-caption" controls indicators>
    <b-carousel-slide
      v-for="(announcement, index) in announcements"
      :key="index"
      class="announcements-slide"
      :caption-html="`<h2 class='text-white'>${announcement.title}</h2>`"
      :text-html="announcement.content"
      :background="announcement.image ? 'black' : announcement.backgroundColor"
    >
      <template v-if="announcement.image" #img>
        <b-img :src="announcement.image" />
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import announcementActions from "@/store/announcement/actions.js";

export default {
  name: "AnnouncementSlider",

  data() {
    return {
      announcements: [],
    };
  },

  async mounted() {
    this.announcements = await this.$store.dispatch(announcementActions.GET_ANNOUNCEMENTS);

    this.$root.$on("langChanged", async () => {
      this.announcements = await this.$store.dispatch(announcementActions.GET_ANNOUNCEMENTS);
    });
  },
};
</script>
