import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Vuelidate from "vuelidate";
import "@core/assets/fonts/feather/iconfont.css";
import "@core/scss/core.scss";
import "@/assets/scss/style.scss";
import FeatherIcon from "@/components/common/FeatherIcon.vue";
import VueMeta from "vue-meta";
import i18n from "@/libs/i18n";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import Breadcrumb from "@/components/common/Breadcrumb.vue";
import Ripple from "vue-ripple-directive";
import VueLoadingOverlay from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueCKEditor from "@ckeditor/ckeditor5-vue2";
import accountActions from "@/store/account/actions.js";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VueMoment from "vue-moment";
import moment from "moment";
import "moment/locale/tr";
import PortalVue from "portal-vue";
import VueSweetalert2 from "vue-sweetalert2";
import "moment-timezone";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(VueMeta);
Vue.use(VueToast, {
  position: "top-right",
  duration: 3000,
});
Vue.use(VueLoadingOverlay, {
  color: "rgba(0, 0, 0, 0.8)",
});
Vue.use(VueCKEditor);
Vue.use(VueMoment, {
  moment,
});
Vue.use(PortalVue);
Vue.use(VueSweetalert2);

Vue.component("FeatherIcon", FeatherIcon);
Vue.component("Breadcrumb", Breadcrumb);
Vue.component("VuePhoneNumberInput", VuePhoneNumberInput);

Vue.directive("Ripple", Ripple);

store.dispatch(
  accountActions.IS_AUTHENTICATED,
  localStorage.getItem("access_token") !== null && localStorage.getItem("access_token") !== undefined
);

Vue.prototype.$formatMoney = (value, currency) => {
  const formatter = new Intl.NumberFormat(i18n.locale, {
    style: "currency",
    currency: currency,
    minimumSignificantDigits: 1,
    maximumFractionDigits: 0,
  });

  const money = Math.round(parseFloat(value));
  return formatter.format(money);
};

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
