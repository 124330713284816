<template>
  <component :is="tag" v-on="$listeners">
    <b-avatar size="42" :src="chat.customerProfilePicture" class="badge-minimal" variant="transparent" />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ chat.customerName }}
      </h5>
      <p class="card-text text-truncate">
        {{ chat.lastMessage }}
      </p>
    </div>
    <div class="chat-meta text-nowrap">
      <small class="float-right mb-25 chat-time">
        {{ formatDateToMonthShort }}
      </small>
      <b-badge v-if="chat.unseenMessageCount > 0" pill variant="primary" class="float-right">
        {{ chat.unseenMessageCount }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { isToday } from "@core/utils/utils.js";

export default {
  name: "ChatContact",

  props: {
    tag: {
      type: String,
      default: "div",
    },
    chat: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formatDateToMonthShort() {
      const date = new Date(this.chat.lastMessageSendDate);
      let formatting = { month: "short", day: "numeric" };

      if (isToday(date)) {
        formatting = { hour: "numeric", minute: "numeric" };
      }

      return new Intl.DateTimeFormat("en-US", formatting).format(new Date(this.chat.lastMessageSendDate));
    },
  },
};
</script>
