<template>
  <b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
    <template #button-content>
      <feather-icon
        :badge="notifications.some((x) => !x.isRead) ? notifications.filter((x) => !x.isRead).length : null"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">{{ $t("common.notifications") }}</h4>
        <b-badge pill variant="light-primary"> {{ notifications.filter((x) => !x.isRead).length }} {{ $t("common.new") }} </b-badge>
      </div>
    </li>

    <vue-perfect-scrollbar v-once :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area" tagname="li">
      <b-link v-for="notification in notifications" :key="notification.subtitle">
        <b-media>
          <template #aside>
            <b-avatar size="32" :src="notification.avatar" :text="notification.avatar" :variant="notification.type" />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <li class="dropdown-menu-footer" v-if="notifications.some((x) => !x.isRead)">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click.prevent="$store.dispatch(actions.READ_ALL_NOTIFICATIONS)"
        >{{ $t("notificationsDropdown.readAllNotifications") }}</b-button
      >
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import actions from "@/store/notification/actions";
import { mapGetters } from "vuex";

export default {
  name: "NotificationDropdown",

  components: {
    VuePerfectScrollbar,
  },

  directives: {
    Ripple,
  },

  async mounted() {
    await this.$store.dispatch(actions.FETCH_NOTIFICATIONS);
  },

  computed: {
    perfectScrollbarSettings() {
      return {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      };
    },
    ...mapGetters(["notifications"]),
  },
};
</script>
