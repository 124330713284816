<template>
  <StatisticCardHorizontal
    icon="ServerIcon"
    :statistic="bookingCount"
    :statisticTitle="$t('homePage.bookingCount')"
    color="success"
    :loading="loading"
  />
</template>

<script>
import StatisticCardHorizontal from "@/components/home/StatisticCardHorizontal.vue";
import actions from "@/store/booking/actions.js";

export default {
  name: "BookingCountCard",

  components: {
    StatisticCardHorizontal,
  },

  data() {
    return {
      bookingCount: 0,
      loading: true,
    };
  },

  async mounted() {
    this.bookingCount = await this.$store.dispatch(actions.GET_BOOKING_COUNT);
    this.loading = false;
  },
};
</script>
