<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- Bookmarks Container -->
      <div class="help-center-container">
        <feather-icon icon="PhoneCallIcon" size="24" class="icon"/>
        <a href="tel:+908503087868">0(850) 308 78 68</a>
      </div>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";

export default {
  components: {
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
