<template>
  <div>
    <Breadcrumb :title="$t('bankAccountsPage.tabTitle')" :items="breadcrumbItems">
      <template #right>
        <b-button :to="{ name: 'CreateBankAccount' }" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
          {{ $t("common.new") }}
        </b-button>
      </template>
    </Breadcrumb>
    <b-table v-if="bankAccounts.length > 0" responsive :items="bankAccounts" :fields="columns" thead-class="table-head">
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(action)="data">
        <span>
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret size="sm">
            <template #button-content>
              <feather-icon icon="SettingsIcon" size="16" />
            </template>
            <b-dropdown-item @click.prevent="setDefaultBankAccount(data.item)" v-if="!data.item.isPrimary">
              <feather-icon icon="EditIcon" size="16" class="mr-1" />
              {{ $t("bankAccountsPage.setDefault") }}
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'EditBankAccount', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" size="16" class="mr-1" />
              {{ $t("common.edit") }}
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="handleDelete(data.item)" link-class="text-danger">
              <feather-icon icon="TrashIcon" size="16" class="mr-1" />
              {{ $t("common.delete") }}
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </template>
    </b-table>
    <EmptyListGraphic
      v-else-if="isLoaded && bankAccounts.length === 0"
      :image="require('@/assets/images/banka-hesaplarim-empty.svg')"
      buttonLink="CreateBankAccount"
      :buttonText="$t('common.createBankAccount')"
    />
  </div>
</template>

<script>
import actions from "@/store/payment/actions.js";
import EmptyListGraphic from "@/components/common/EmptyListGraphic.vue";

export default {
  name: "BankAccounts",

  components: {
    EmptyListGraphic,
  },

  data() {
    return {
      bankAccounts: [],
      columns: [
        { label: this.$t("common.bankName"), key: "bankName", sortable: true },
        { label: this.$t("addOrUpdateBankAccountPage.nameOrTitle"), key: "name" },
        {
          label: "IBAN",
          key: "iban",
          formatter: (value) => {
            return value.replace(/(.{4})/g, "$1 ").trim();
          },
        },
        {
          label: this.$t("common.actions"),
          key: "action",
          thStyle: { width: "100px" },
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      isLoaded: false,
    };
  },

  async mounted() {
    this.bankAccounts = await this.$store.dispatch(actions.GET_BANK_ACCOUNTS);
    this.isLoaded = true;
  },

  methods: {
    handleDelete(entity) {
      this.$bvModal
        .msgBoxConfirm(this.$t("messages.bankAccountDeleteConfirmation"), {
          title: this.$t("common.areYouSure"),
          size: "md",
          okTitle: this.$t("common.yes"),
          cancelTitle: this.$t("common.no"),
          okVariant: "primary",
          cancelVariant: "secondary",
          centered: true,
        })
        .then(async (result) => {
          if (result) {
            await this.$store.dispatch(actions.DELETE_BANK_ACCOUNT, entity.id);
            this.bankAccounts.splice(this.bankAccounts.indexOf(entity), 1);
          }
        });
    },

    async setDefaultBankAccount(entity) {
      this.bankAccounts = await this.$store.dispatch(actions.SET_DEFAULT_BANK_ACCOUNT, entity.id);
    },
  },

  computed: {
    breadcrumbItems() {
      return [{ text: this.$t("bankAccountsPage.tabTitle"), to: "BankAccounts" }];
    },
  },

  metaInfo() {
    return {
      title: this.$t("bankAccountsPage.tabTitle"),
    };
  },
};
</script>
