<template>
  <ul>
    <NavMenuItem :text="$t('navMenuItems.home')" to="Home" icon="HomeIcon" />
    <NavMenuItem :text="$t('navMenuItems.boats')" to="Boats" icon="AnchorIcon" />
    <NavMenuItem :text="$t('navMenuItems.bookings')" to="Bookings" icon="NavigationIcon" />
    <NavMenuItem :text="$t('navMenuItems.calendar')" to="CalendarIndex" icon="CalendarIcon" />
    <NavMenuItem :text="$t('navMenuItems.discounts')" to="Discounts" icon="PercentIcon" />
    <NavMenuItem :text="$t('navMenuItems.chat')" to="Chat" icon="MailIcon" :tag="unreadMessageCount" />
    <NavMenuItem :text="$t('navMenuItems.billingInformation')" to="BillingInformation" icon="FileTextIcon" />
    <NavMenuItem :text="$t('navMenuItems.bankAccounts')" to="BankAccounts" icon="CreditCardIcon" />
  </ul>
</template>

<script>
import NavMenuItem from "@/components/common/NavMenuItem.vue";
import { mapGetters } from "vuex";

export default {
  name: "VerticalNavMenuItems",

  components: {
    NavMenuItem,
  },

  computed: {
    ...mapGetters(["unreadMessageCount"]),
  },
};
</script>
