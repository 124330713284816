<template>
  <div>
    <Breadcrumb
      :title="
        isEdit ? $t('addOrUpdateBankAccountPage.updateTabTitle') : $t('addOrUpdateBankAccountPage.createTabTitle')
      "
      :items="breadcrumbItems"
    >
      <template #right>
        <b-button :to="{ name: 'BankAccounts' }" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
          {{ $t("common.backToList") }}
        </b-button>
      </template>
    </Breadcrumb>
    <b-card ref="card">
      <b-form @submit.prevent="handleSubmit">
        <b-row>
          <b-col sm="12">
            <b-form-group :label="$t('addOrUpdateBankAccountPage.accountTitle')">
              <b-form-input v-model.trim="form.name" :state="submitted && !$v.form.name.$error" />
              <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
                <span v-if="!$v.form.name.required">{{ $t("messages.bankAccountTitleRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="3">
            <b-form-group :label="$t('addOrUpdateBankAccountPage.bankName')">
              <b-form-input v-model.trim="form.bankName" :state="submitted && !$v.form.bankName.$error" />
              <div v-if="submitted && $v.form.bankName.$error" class="invalid-feedback">
                <span v-if="!$v.form.bankName.required">{{ $t("messages.bankNameRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="IBAN">
              <b-form-input
                v-model.trim="ibanMasked"
                :state="submitted && !$v.form.iban.$error"
                v-mask="'AA## #### #### #### #### #### ##'"
              />
              <div v-if="submitted && $v.form.iban.$error" class="invalid-feedback">
                <span v-if="!$v.form.iban.required">{{ $t("messages.ibanRequired") }}</span>
                <span v-else-if="!$v.form.iban.iban">{{ $t("messages.ibanInvalid") }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group :label="$t('addOrUpdateBankAccountPage.currency')">
              <b-form-select v-model="form.currency" :state="submitted && !$v.form.currency.$error">
                <b-form-select-option v-for="currency in currencies" :key="currency.value" :value="currency.value">
                  {{ $t(`currencies.${currency.value}`) }}
                </b-form-select-option>
              </b-form-select>
              <div v-if="submitted && $v.form.currency.$error" class="invalid-feedback">
                <span v-if="!$v.form.currency.required">{{ $t("messages.currencyRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="3">
            <b-form-group :label="$t('addOrUpdateBankAccountPage.swiftCode')">
              <b-form-input v-model.trim="form.bicSwiftCode" :state="submitted && !$v.form.bicSwiftCode.$error" />
              <div v-if="submitted && $v.form.bicSwiftCode.$error" class="invalid-feedback">
                <span v-if="!$v.form.bicSwiftCode.required">{{ $t("messages.bicSwiftRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right" cols="12">
            <b-button
              v-if="isEdit"
              @click.prevent="handleDelete"
              type="button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              class="mt-1 mr-1"
            >
              {{ $t("common.delete") }}
            </b-button>
            <b-button
              type="submit"
              ref="submitBtn"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
            >
              {{ $t("common.save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { iban } from "@/utils/validators";
import actions from "@/store/payment/actions.js";
import { mapGetters } from "vuex";
import { VueMaskDirective } from "v-mask";

export default {
  name: "AddOrUpdateBankAccount",

  directives: {
    mask: VueMaskDirective,
  },

  data() {
    return {
      submitted: null,
      form: {
        name: "",
        bankName: "",
        iban: "",
        bicSwiftCode: "",
        currency: 1,
      },
      ibanMasked: "",
    };
  },

  async mounted() {
    if (this.isEdit) {
      let loader = this.$loading.show({
        container: this.$refs.card,
      });
      const { id } = this.$route.params;
      this.form = await this.$store.dispatch(actions.GET_BANK_ACCOUNT_BY_ID, id);
      this.ibanMasked = this.form.iban.replace(/(.{4})/g, "$1 ").trim();
      loader.hide();
    }
  },

  methods: {
    async handleSubmit() {
      this.$refs.submitBtn.disabled = true;
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$refs.submitBtn.disabled = false;
        return;
      }

      const isSuccess = await this.$store.dispatch(actions.ADD_OR_UPDATE_BANK_ACCOUNT, this.form);
      if (isSuccess) {
        this.submitted = null;
        this.$v.$reset();
        this.$router.push({ name: "BankAccounts" });
      } else {
        this.$refs.submitBtn.disabled = false;
      }
    },

    handleDelete() {
      this.$bvModal
        .msgBoxConfirm(this.$t("messages.bankAccountDeleteConfirmation"), {
          title: this.$t("common.areYouSure"),
          size: "md",
          okTitle: this.$t("common.yes"),
          cancelTitle: this.$t("common.no"),
          okVariant: "primary",
          cancelVariant: "secondary",
          centered: true,
        })
        .then(async (result) => {
          if (result) {
            this.$loading.show({ container: this.$refs.card });
            await this.$store.dispatch(actions.DELETE_BANK_ACCOUNT, this.form.id);
            this.$router.push({ name: "BankAccounts" });
          }
        });
    },
  },

  watch: {
    ibanMasked: function () {
      this.ibanMasked = this.ibanMasked.toUpperCase();
      this.form.iban = this.ibanMasked.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
    },
  },

  computed: {
    isEdit() {
      return this.$router.currentRoute.name === "EditBankAccount";
    },
    breadcrumbItems() {
      return [
        { text: this.$t("bankAccountsPage.tabTitle"), to: "BankAccounts" },
        {
          text: this.isEdit
            ? this.$t("addOrUpdateBankAccountPage.updateBankAccount")
            : this.$t("addOrUpdateBankAccountPage.createBankAccount"),
          to: this.isEdit ? "EditBankAccount" : "CreateBankAccount",
        },
      ];
    },
    ...mapGetters(["currencies"]),
  },

  validations() {
    return {
      form: {
        name: { required },
        bankName: { required },
        iban: { required, iban },
        bicSwiftCode: {},
        currency: { required },
      },
    };
  },

  metaInfo() {
    return {
      title: this.isEdit
        ? this.$t("addOrUpdateBankAccountPage.updateTabTitle")
        : this.$t("addOrUpdateBankAccountPage.createTabTitle"),
    };
  },
};
</script>
