<template>
  <div>
    <Breadcrumb :title="$t('boatsPage.tabTitle')" :items="breadcrumbItems">
      <template #right>
        <b-button v-b-modal.boat-rules variant="outline-primary" class="mr-1">
          {{ $t("common.boatPostingRules") }}
        </b-button>
        <b-button v-b-modal.business-model-selection-modal variant="primary">{{ $t("boatsPage.newBoat") }}</b-button>
      </template>
    </Breadcrumb>
    <b-row v-if="boats.length > 0">
      <b-col lg="3" md="6" v-for="boat in boats" :key="boat.id">
        <BoatListingCard :boat="boat" @delete="handleDelete" />
      </b-col>
    </b-row>
    <EmptyListGraphic
      v-else-if="isLoaded && boats.length === 0"
      :image="require('@/assets/images/teknelerim-empty.svg')"
      buttonLink="CreateBoat"
      :buttonText="$t('common.createBoat')"
    />
    <BoatRulesModal />
    <BoatBusinessModelSelectionModal @selected="handleBusinessModelSelected" />
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import boatActions from "@/store/boat/actions.js";
import BoatListingCard from "@/components/boats/BoatListingCard.vue";
import BoatRulesModal from "@/components/common/BoatRulesModal.vue";
import EmptyListGraphic from "@/components/common/EmptyListGraphic.vue";
import BoatBusinessModelSelectionModal from "@/components/boats/BoatBusinessModelSelectionModal.vue";

export default {
  name: "BoatIndex",

  components: {
    VueGoodTable,
    BoatListingCard,
    BoatRulesModal,
    EmptyListGraphic,
    BoatBusinessModelSelectionModal,
  },

  data() {
    return {
      boats: [],
      isLoaded: false,
    };
  },

  async mounted() {
    this.boats = await this.$store.dispatch(boatActions.GET_BOATS);
    this.isLoaded = true;
  },

  methods: {
    handleDelete(id) {
      const deletedBoat = this.boats.find((boat) => boat.id === id);
      this.boats.splice(this.boats.indexOf(deletedBoat), 1);
    },

    handleBusinessModelSelected(model) {
      this.$router.push({ name: "CreateBoat", query: { mode: model } });
    },
  },

  computed: {
    breadcrumbItems() {
      return [{ text: this.$t("boatsPage.tabTitle"), to: "Boats" }];
    },
  },

  metaInfo() {
    return {
      title: this.$t("boatsPage.tabTitle"),
    };
  },
};
</script>
