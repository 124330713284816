<template>
  <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>
    <template #button-content>
      <b-img v-if="currentLocale" :src="currentLocale.flag" height="14px" width="22px" :alt="currentLocale.title" />
      <span class="ml-50 text-body" v-if="currentLocale">{{ currentLocale.title }}</span>
    </template>
    <b-dropdown-item v-for="lang in supportedLanguages" :key="lang.languageCode" @click="setLocale(lang)">
      <b-img :src="lang.flag" height="14px" width="22px" :alt="lang.languageCode" />
      <span class="ml-50">{{ lang.title }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { mapGetters } from "vuex";
import commonActions from "@/store/common/actions.js";
import accountActions from "@/store/account/actions.js";

export default {
  name: "Locale",

  methods: {
    async setLocale(newLang) {
      if (newLang.languageCode === this.$i18n.locale) {
        return;
      }

      this.$i18n.locale = newLang.languageCode;
      localStorage.setItem("lang", newLang.languageCode);
      this.$root.$emit("langChanged", newLang);

      if (this.isAuthenticated) {
        await this.$store.dispatch(accountActions.SET_LANGUAGE, { languageCode: newLang.languageCode });
      }
    },
  },

  async beforeMount() {
    await this.$store.dispatch(commonActions.GET_SUPPORTED_LANGUAGES);
  },

  computed: {
    currentLocale() {
      return this.supportedLanguages.find((x) => x.languageCode === this.$i18n.locale);
    },
    ...mapGetters(["supportedLanguages", "isAuthenticated"]),
  },
};
</script>
