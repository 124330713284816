<template>
  <div>
    <b-card class="account-settings-general">
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <b-img ref="previewEl" rounded :src="profilePicture" height="80" />
          </b-link>
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
            :disabled="avatarLoading"
          >
            <b-spinner small class="mr-1" v-if="avatarLoading" />
            {{ $t("accountSettingsPage.uploadProfilePhoto") }}
          </b-button>
          <b-form-file
            ref="refInputEl"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @input="handleProfilePictureInput"
          />
          <b-card-text>{{ $t("accountSettingsPage.allowedProfilePhotoTypes") }}</b-card-text>
        </b-media-body>
      </b-media>
      <b-form class="mt-2" @submit.prevent="handleSubmit">
        <b-row>
          <b-col sm="6">
            <b-form-group :label="$t('common.firstName')">
              <b-form-input v-model.trim="settings.firstName" :state="submitted && !$v.settings.firstName.$error" />
              <div v-if="submitted && $v.settings.firstName.$error" class="invalid-feedback">
                <span v-if="!$v.settings.firstName.required">{{ $t("messages.firstNameRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('common.lastName')">
              <b-form-input v-model.trim="settings.lastName" :state="submitted && !$v.settings.lastName.$error" />
              <div v-if="submitted && $v.settings.lastName.$error" class="invalid-feedback">
                <span v-if="!$v.settings.lastName.required">{{ $t("messages.lastNameRequired") }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('common.email')">
              <b-form-input v-model.trim="settings.email" :state="submitted && !$v.settings.email.$error" />
              <div v-if="submitted && $v.settings.email.$error" class="invalid-feedback">
                <span v-if="!$v.settings.email.required">{{ $t("messages.emailRequired") }}</span>
                <span v-if="!$v.settings.email.email">{{ $t("messages.emailInvalid") }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('common.phone')">
              <PhoneNumberInput
                v-model="phoneNumber"
                :class="{ 'is-invalid': submitted && $v.settings.phoneNumber.$error }"
              />
              <div v-if="submitted && $v.settings.phoneNumber.$error" class="invalid-feedback">
                <span v-if="!$v.settings.phoneNumber.required">{{ $t("messages.phoneRequired") }}</span>
                <span v-else-if="!$v.settings.phoneNumber.isValid">{{ $t("messages.phoneInvalid") }}</span>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group :label="$t('common.spokenLanguages')">
              <vue-multiselect
                v-model="userSpokenLanguagesTemp"
                :options="spokenLanguages"
                label="name"
                track-by="id"
                :multiple="true"
                :closeOnSelect="false"
                :placeholder="$t('accountSettingsPage.selectSpokenLanguages')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              type="submit"
              ref="submitBtn"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t("common.save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <PhoneNumberConfirmationModal
      :dialCode="settings.countryDialCode"
      :isoCode="settings.countryIsoCode"
      :phoneNumber="settings.phoneNumber"
      @phoneNumberUpdated="onPhoneNumberUpdated"
    />
  </div>
</template>

<script>
import { required, email, maxLength } from "vuelidate/lib/validators";
import accountActions from "@/store/account/actions.js";
import boatActions from "@/store/boat/actions.js";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import httpClient from "@/libs/axios.js";
import PhoneNumberInput from "../common/PhoneNumberInput.vue";
import { isValidPhoneNumber } from "libphonenumber-js";
import PhoneNumberConfirmationModal from "@/components/common/PhoneNumberConfirmationModal.vue";

export default {
  name: "AccountSettingGeneral",

  components: {
    VueMultiselect,
    PhoneNumberInput,
    PhoneNumberConfirmationModal,
  },

  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      submitted: null,
      spokenLanguages: [],
      userSpokenLanguagesTemp: [],
      avatarLoading: false,
      phoneNumber: {},
      oldPhoneNumber: {
        dialCode: "",
        phoneNumber: "",
      },
    };
  },

  async mounted() {
    if (this.settings.phoneNumber) {
      this.phoneNumber = {
        iso: this.settings.countryIsoCode,
        dialCode: this.settings.countryDialCode,
        phoneNumber: this.settings.phoneNumber,
      };

      this.oldPhoneNumber = {
        countryDialCode: this.settings.countryDialCode,
        phoneNumber: this.settings.phoneNumber,
      };
    }

    await this.init();

    this.$root.$on("langChanged", async () => {
      await this.init();
    });
  },

  methods: {
    async init() {
      this.spokenLanguages = await this.$store.dispatch(boatActions.LIST_SPOKEN_LANGUAGES);
      this.userSpokenLanguagesTemp = this.spokenLanguages.filter((x) => this.settings.spokenLanguageIds.includes(x.id));
    },
    async handleSubmit() {
      this.$refs.submitBtn.disabled = true;
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$refs.submitBtn.disabled = false;
        return;
      }

      await this.$store.dispatch(accountActions.UPDATE_GENERAL_SETTINGS, this.settings);
      this.resetForm();
      if (this.phoneNumberChanged) {
        const result = await this.$store.dispatch(accountActions.SEND_PHONE_NUMBER_CONFIRMATION_SMS, {
          countryCode: this.settings.countryDialCode,
          phoneNumber: this.settings.phoneNumber,
          isRegister: false,
        });

        if (result.success) {
          this.$bvModal.show("phone-number-confirmation");
        }
      }
    },

    async handleProfilePictureInput(file) {
      try {
        this.avatarLoading = true;
        const formData = new FormData();
        formData.append("file", file);
        const { data: result } = await httpClient.post("/general/upload-file?uploadType=profilePicture", formData);
        if (result.success) {
          this.settings.profilePicture = result.data;
        }
      } catch {
        this.$toast.error(this.$t("messages.imageUploadError"));
      } finally {
        this.avatarLoading = false;
      }
    },

    resetForm() {
      this.submitted = null;
      this.$v.$reset();
      this.$refs.submitBtn.disabled = false;
    },
    onPhoneNumberUpdated() {
      this.oldPhoneNumber = {
        countryDialCode: this.settings.countryDialCode,
        phoneNumber: this.settings.phoneNumber,
      };
    },
  },

  watch: {
    userSpokenLanguagesTemp: function () {
      this.settings.spokenLanguageIds = this.userSpokenLanguagesTemp.map((x) => x.id);
    },

    phoneNumber: {
      handler: function (newVal) {
        this.settings.countryDialCode = newVal.dialCode;
        this.settings.countryIsoCode = newVal.iso;
        this.settings.phoneNumber = newVal.phoneNumber;
      },
      deep: true,
    },
  },

  computed: {
    profilePicture() {
      return this.settings.profilePicture || require("@/assets/images/avatar.svg");
    },
    phoneNumberChanged() {
      return (
        this.oldPhoneNumber.countryDialCode != this.settings.countryDialCode ||
        this.oldPhoneNumber.phoneNumber != this.settings.phoneNumber
      );
    },
  },

  validations() {
    return {
      settings: {
        firstName: { required },
        lastName: { required },
        email: { required, email },
        phoneNumber: {
          required,
          isValid: () => isValidPhoneNumber(this.settings.phoneNumber, this.settings.countryIsoCode),
        },
      },
    };
  },
};
</script>
