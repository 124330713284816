export const UnitOfLength = {
  METER: 1,
  FOOT: 2,
};

export const FuelType = {
  DIESEL: 1,
  GASOLINE: 2,
  ELECTRIC: 3,
  HYBRID: 4,
  GAS: 5,
};

export const FuelCost = {
  INCLUDED: 1,
  EXCLUDED: 2,
};

export const EngineType = {
  OUTBOARD: 1,
  INBOARD: 2,
  STERN_DRIVE: 3,
  JET_DRIVE: 4,
  OTHER: 5,
};

export const CharterType = {
  SKIPPERED: 1,
  BAREBOAT: 2,
  SKIPPERED_OR_BAREBOAT: 3,
};

export const CancellationPolicy = {
  Wide: 1,
  FLEXIBLE: 2,
  SEMI_FLEXIBLE: 3,
  STRICT: 4,
};

export const RentalPeriod = {
  Hourly: 1,
  Daily: 2,
  Weekly: 3,
};

export const BoatDocumentType = {
  TURIZM_ISLETMESI_BELGESI: 1,
  GEMI_SICIL_TASDIKNAMESI: 2,
  BAYRAK_SAHADETNAMESI: 3,
  BAGLAMA_KUTUGU_RUHSATNAMESI: 4,
  TONILATO_BELGESI: 5,
  DENIZE_ELVERISLILIK_BELGESI: 6,
  GUMRUK_GIRIS_BEYANNAMESI: 7,
  YETERLILIK_BELGESI: 8,
  SIGORTA_POLICESI: 9,
  IMZA_SIRKULERI: 10,
};

export const BoatStatus = {
  ACTIVE: 1,
  WAITING_FOR_APPROVAL: 2,
  WAITING_FOR_UPDATE_APPROVAL: 3,
  REJECTED: 4,
  UPDATE_REQUIRED: 5,
  DRAFT: 6,
  MISSING_DOCUMENTS_PENDING: 7,
};

export const InvoiceCountry = {
  TURKEY: 1,
  OTHER: 2,
};

export const Currency = {
  TRY: 1,
  USD: 2,
  EUR: 3,
};

export const CurrencySymbol = {
  [1]: "₺",
  [2]: "$",
  [3]: "€",
};

export const CurrencyCode = {
  [1]: "TRY",
  [2]: "USD",
  [3]: "EUR",
};

export const AppConfigType = {
  COMISSION_RATE: 1,
  MIN_PREPAYMENT_RATE: 2,
  MAX_ACTIVE_AVAILABILITY_QUERY_COUNT: 3,
  MAX_PARTNER_DISCOUNT_RATE: 5,
};

export const TransitLogPaymentType = {
  INCLUDED: 1,
  PREPAID: 2,
  POSTPAID: 3,
  CHECKIN: 4,
  NO_TRANSIT_LOG: 5,
};

export const RemainingPaymentType = {
  ONLINE: 1,
  ONCHECKIN: 2,
};

export const SkipperPaymentType = {
  PREPAID: 1,
  POSTPAID: 2,
  CHECKIN: 3,
};

export const BookingStage = {
  PENDING_AVAILABILITY: 1,
  REJECTED_BY_OWNER: 2,
  CANCELLED_BY_CUSTOMER: 3,
  PENDING_PRE_PAYMENT: 4,
  PENDING_REMAINED_PAYMENT: 5,
  PAYMENT_SUCCESS: 6,
  REFUND_REQUEST_BY_CUSTOMER: 7,
  REFUND_REQUEST_BY_PARTNER: 8,
  REFUND_SUCCESS_CANCELLED_BY_CUSTOMER: 9,
  COMPLETED: 11,
  AVAILABILITY_CHECK_CANCELLED_BY_OWNER: 12,
  AVAILABILITY_CHECK_TIMED_OUT: 13,
  REFUND_SUCCESS_CANCELLED_BY_PARTNER: 14,
  CANCELLED_BY_PARTNER: 15,
};

export const PolicyType = {
  TERMS_OF_USE: 1,
  MONEY_TRANSFER_AGREEMENT: 2,
  KVKK: 3,
  DISTANCE_SELLING_AGREEMENT: 4,
  COMMERCIAL_MAIL_CONFIRMATION: 5,
  PRELIMINARY_INFORMATION_FORM: 6,
  BOAT_DELIVERY_RECORD: 7,
  CANCELLATION_POLICY: 8,
};

export const BoatServicePeriod = {
  PER_BOOKING: 1,
  PER_DAY: 2,
  PER_WEEK: 3,
};

export const AdditionalServicesPaymentType = {
  PREPAID: 1,
  POSTPAID: 2,
  CHECKIN: 3,
};

export const DepositPaymentType = {
  NO_DEPOSIT: 1,
  CHECKIN: 2,
};

export const DiscountType = {
  STANDART: 1,
  EARLY_BOOKING: 2,
};

export const WeekDay = {
  SUNDAY: 1,
  MONDAY: 2,
  TUESDAY: 3,
  WEDNESDAY: 4,
  THURSDAY: 5,
  FRIDAY: 6,
  SATURDAY: 7,
};

export const ServicePricingType = {
  NO_SERVICE_PRICE: 1,
  PRICE_PER_PERSON: 2,
  SINGLE_PRICE: 3,
};
