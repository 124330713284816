<template>
  <b-card>
    <b-form @submit.prevent="handleSubmit">
      <b-row>
        <h6 class="section-label mx-1 mb-2">{{ $t("accountSettingsPage.notificationSettingsText") }}</h6>
        <b-col cols="12" class="mb-2">
          <b-form-checkbox v-model="settings.hideEmail" switch inline>
            <span>{{ $t("accountSettingsPage.hideEmail") }}</span>
          </b-form-checkbox>
        </b-col>

        <b-col cols="12" class="mb-2">
          <b-form-checkbox v-model="settings.hidePhoneNumber" switch inline>
            <span>{{ $t("accountSettingsPage.hidePhoneNumber") }}</span>
          </b-form-checkbox>
        </b-col>

        <b-col cols="12" class="mb-2">
          <b-form-checkbox v-model="settings.disableWhatsAppNotifications" switch inline>
            <span>{{ $t("accountSettingsPage.disableWhatsAppNotifications") }}</span>
          </b-form-checkbox>
        </b-col>

        <b-col cols="12">
          <b-button
            ref="submitBtn"
            type="submit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1 mt-1"
          >
            {{ $t("common.save") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import actions from "@/store/account/actions.js";

export default {
  name: "AccountSettingsNotification",

  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    async handleSubmit() {
      this.$refs.submitBtn.disabled = true;
      await this.$store.dispatch(actions.UPDATE_CONTACT_SETTINGS, this.settings);
      this.$refs.submitBtn.disabled = false;
    },
  },
};
</script>
