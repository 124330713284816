<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <span class="brand-logo">
        <b-navbar-nav class="nav align-items-center ml-auto">
          <Locale />
        </b-navbar-nav>
      </span>
      <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-img src="@/assets/images/logo/rotaneta-horizontal.svg" alt="Rotaneta Logo" class="auth-logo" fluid />
          <b-card-title title-tag="h2" class="font-weight-bold mb-1"> {{ $t("resetPasswordPage.title") }} 🔒 </b-card-title>
          <b-card-text class="mb-2"> {{ $t("resetPasswordPage.subtitle") }} </b-card-text>
          <b-form class="auth-reset-password-form mt-2" method="POST" @submit.prevent="handleSubmit">
            <b-form-group :label="$t('common.newPassword')">
              <b-input-group class="input-group-merge" :class="{ 'is-invalid': submitted && $v.form.newPassword.$error }">
                <b-form-input
                  v-model="form.newPassword"
                  :type="password1FieldType"
                  :state="submitted && !$v.form.newPassword.$error"
                  class="form-control-merge"
                />
                <b-input-group-append is-text>
                  <feather-icon class="cursor-pointer" :icon="password1ToggleIcon" @click="togglePassword1Visibility" />
                </b-input-group-append>
              </b-input-group>
              <div v-if="submitted && $v.form.newPassword.$error" class="invalid-feedback">
                <span v-if="!$v.form.newPassword.required">{{ $t("messages.passwordRequired") }}</span>
                <span v-if="!$v.form.newPassword.minLength">New password must be at least 6 characters</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('common.newPasswordConfirmation')">
              <b-input-group class="input-group-merge" :class="{ 'is-invalid': submitted && $v.form.newPasswordConfirm.$error }">
                <b-form-input
                  v-model="form.newPasswordConfirm"
                  :type="password2FieldType"
                  class="form-control-merge"
                  :state="submitted && !$v.form.newPasswordConfirm.$error"
                />
                <b-input-group-append is-text>
                  <feather-icon class="cursor-pointer" :icon="password2ToggleIcon" @click="togglePassword2Visibility" />
                </b-input-group-append>
              </b-input-group>
              <div v-if="submitted && $v.form.newPasswordConfirm.$error" class="invalid-feedback">
                <span v-if="!$v.form.newPasswordConfirm.required">{{ $t("messages.passwordConfirmationRequired") }}</span>
                <span v-else-if="!$v.form.newPasswordConfirm.sameAs">{{ $t("messages.passwordConfirmationInvalid") }}</span>
              </div>
            </b-form-group>
            <b-button block type="submit" ref="submitBtn" variant="primary"> {{ $t("resetPasswordPage.submitButtonText") }} </b-button>
          </b-form>
        </b-col>
      </b-col>
      <b-col lg="7" class="d-none d-lg-flex align-items-center p-5 auth-bg">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img :src="require('@/assets/images/password-reset.png')" fluid alt="Register V2" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import actions from "@/store/account/actions.js";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";

export default {
  name: "ResetPassword",

  components: {
    Locale,
  },

  data() {
    return {
      form: {
        resetKey: "",
        email: "",
        newPassword: "",
        newPasswordConfirm: "",
      },

      submitted: null,
      password1FieldType: "password",
      password2FieldType: "password",
    };
  },

  mounted() {
    const { email, resetKey } = this.$route.query;

    this.form.email = email;
    this.form.resetKey = resetKey;
  },

  methods: {
    async handleSubmit() {
      this.$refs.submitBtn.disabled = true;
      this.$v.$touch();
      this.submitted = true;
      if (this.$v.$invalid) {
        this.$refs.submitBtn.disabled = false;
        return;
      }

      const isSuccess = await this.$store.dispatch(actions.RESET_PASSWORD, this.form);
      if (isSuccess) {
        this.$router.push({ name: "Login" });
      } else {
        this.$refs.submitBtn.disabled = false;
        this.$v.$reset();
        this.submitted = null;
      }
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === "password" ? "text" : "password";
    },
  },

  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },

  validations() {
    return {
      form: {
        newPassword: { required, minLength: minLength(6) },
        newPasswordConfirm: {
          required,
          sameAs: () => {
            return this.form.newPassword === this.form.newPasswordConfirm;
          },
        },
      },
    };
  },

  metaInfo() {
    return {
      title: this.$t("resetPasswordPage.tabTitle"),
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
